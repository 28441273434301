/*
 * @flow
 */

import { observable, action, computed } from 'mobx'
import CommonMethods from '../../../../../Common/utils/CommonMethods'
import type { APIRevenueExpenditureObject } from './type'

class TodayRevenueExpenditureObject {
  @observable expenditure: number
  @observable revenue: number

  constructor() {
    this.expenditure = 0
    this.revenue = 0
  }
  @computed
  get todayRevenueAmount(): any {
    return CommonMethods.toCurrency(this.revenue, {
      minimizePrecision: 2,
      isCurrency: true
    })
  }
  @computed
  get todayExpenditureAmount(): any {
    return CommonMethods.toCurrency(this.expenditure, {
      minimizePrecision: 2,
      isCurrency: true
    })
  }

  @action.bound
  setBasicDetails(revenueExpenditureObject: APIRevenueExpenditureObject) {
    if (revenueExpenditureObject) {
      this.expenditure = revenueExpenditureObject.expenditure || 0
      this.revenue = revenueExpenditureObject.revenue || 0
    }
  }
}
export default TodayRevenueExpenditureObject

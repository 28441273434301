/**
 * @flow
 * Author: Manikanta
 * */

// import Config from "react-native-config";

// const { SENTRY_KEY, ...Others } = Config;

// const SENTRY_DSN_KEY = SENTRY_KEY;

// export default {
//   SENTRY_DSN_KEY,
//   ...Others,
// };

const envVariables = process.env;

const Config: any = {};

Object.keys(envVariables).forEach((variable) => {
  if (variable.includes("REACT_APP")) {
    const envKey = variable.replace("REACT_APP_", "");
    Config[envKey] = envVariables[variable];
  }
});

export default Config;

/*
* @flow
* @author: Dinesh
*/
import Colors from '../../Common/themes/Colors'

export function getRandomColorForChannelsAndFranchise() {
  const homeScreenFranchiseAndChannelColors = [
    Colors.argh,
    Colors.izumi,
    Colors.amon,
    Colors.mango,
    Colors.deepLilac,
    Colors.bumi
  ]
  return homeScreenFranchiseAndChannelColors[
    Math.floor(Math.random() * homeScreenFranchiseAndChannelColors.length)
  ]
}

import styled from "@emotion/styled";
import Colors from "../../../Common/themes/Colors";
import tw from "tailwind.macro";
import {
  Input,
  InputLabel,
  Logo,
  SignInPage,
  LoginForm,
  LogoText,
  LoginPageDiv,
  LoginButton,
  ErrorMessageSpan,
} from "../LoginUsernameScreen/styledComponents";

//TODO: Need to update the styles with Tailwind

export const BaseTypo = styled.div`
  background-color: transparent;
`;

export const SignInOTPPage = styled(SignInPage)``;

export const LogoOTP = styled(Logo)``;

export const LoginOTPForm = styled(LoginForm)``;

export const LogoOTPText = styled(LogoText)`
  margin: 18px 123px 18px 2px;
`;

export const LoginOTPPageDiv = styled(LoginPageDiv)``;

export const Label = styled(InputLabel)``;

export const InputBar = styled(Input)``;

export const SubmitButton = styled.button`
  ${tw` flex justify-center text-center text-white focus:outline-none focus:shadow-outline`}
  font-family: "CerebriSans";
  font-weight: 500;
  width: 240px;
  height: 36px;
  margin: 2px 0 0px 0px;
  padding: 7px 70px 7px 70px;
  border-radius: 3px;
  background-color: #004acd;
  outline: none;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ErrorMessage = styled(ErrorMessageSpan)`
  font-family: "CerebriSans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${Colors.coolGreyTwo};
  max-width: 240px;
  margin-bottom: 5px;
  visibility: ${(props) => (props.displayError ? "visible" : "hidden")};
`;

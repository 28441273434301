import React, { Component } from 'react';
import {NoDataGraphDiv, NoDataAmount, NoDataText } from './styledComponents'

class NoDataGraphView extends Component {
    render() {
        return (
            <NoDataGraphDiv>
                <NoDataAmount>₹ 0</NoDataAmount>
                <NoDataText>Total</NoDataText>
            </NoDataGraphDiv>
        );
    }
}

export default NoDataGraphView;
import React from "react";
import styled from "@emotion/styled";
import { useTable, useSortBy } from "react-table";
import moment from "moment";
import SortByIcon from '../../../Common/Icons/SortByIcon'

const franchiseData = {
  results: [
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 1",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-12",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 2",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-13",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 3",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-14",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 4",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-15",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 5",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-16",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli 6",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-17",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-18",
    },
    {
      franchise_id: 1,
      franchise_name: "Gachibowli",
      cash_collected: "10000",
      digital_amount_collected: "7000",
      date: "2018-10-11",
    },
  ],
  total: 15,
  applied_sort_by: [
    {
      key: "DATE",
      value: "ASC",
    },
  ],
  sort_by: [
    {
      key: "DATE",
      value: "ASC",
    },
  ],
};

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    border: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border: 0;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
      border: 0;
      text-align: center;
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {column.render("Header")}
                  <span style={{ marginLeft: "10px" }}>
                    {column.disableSortBy ? "" : <SortByIcon />}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function SampleTable() {
  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: (row) => {
          const date = moment(new Date(row.value)).format("Do MMM YYYY");
          return <span>{`${date}`}</span>;
        },
      },
      {
        Header: "Franchise Name",
        accessor: "franchise_name",
        disableSortBy: true,
      },
      {
        Header: "Cash Collected",
        accessor: "cash_collected",
        disableSortBy: true,
      },
      {
        Header: "Digital Amount Collected",
        accessor: "digital_amount_collected",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} data={franchiseData.results} />
    </Styles>
  );
}

export default SampleTable;

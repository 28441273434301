/*
 * @flow
 * Author: Prasanth
 */

/* eslint no-param-reassign:["error", { "props": false }] import/no-named-as-default-member: 0 */

import { observable, action, computed } from "mobx";
import { create, persist } from "mobx-persist";
// import I18n from "react-native-i18n";
// import { AsyncStorage } from "react-native";
import { bindPromiseWithOnSuccess } from "@ib/mobx-promise";
import type { IObservableArray } from "mobx";
import type { APIStatus } from "@ib/api-constants";
import { API_INITIAL } from "@ib/api-constants";
import CommonMethods from "../../../Common/utils/CommonMethods";
import UserBaseProfile from "../models/UserBaseProfile";
import AdditionalAttr from "../models/AdditionalAttr/index.js";
import BackendConstants from "../../constants/BackendConstants";
import LoginFranchiseDetails from "../models/LoginFranchiseDetails";
import type { APIPhoneNumber } from "../AuthStore/type";
import settingsStore from "../../../Common/stores/SettingsStore";
import AppConfig from "../models/AppConfig";
import ZendeskConfig from "../models/ZendeskConfig";
import profileAPI from "./ProfileAPI";
import type {
  APIProfile,
  APIChangeUserPhoneNumberRequest,
  APIVerifyChangeUserPhoneNumberOTPRequest,
  APIBasicProfile,
  APIGetFranchisesParameter,
  APIGetFranchisesResponse,
  APIAppConfigResponse,
} from "./type.js";

const pour = create({
  // storage: AsyncStorage,
});

const COUNTRY_CODE = "91";

class UserProfile extends UserBaseProfile {
  @persist("list", AdditionalAttr)
  @observable
  additionalAttributes: IObservableArray<AdditionalAttr> = [];
  @persist
  @observable
  language: string;
  @persist
  @observable
  emailId: string;
  @persist
  @observable
  country: string;
  @persist
  @observable
  phoneNumber: string;
  @persist
  @observable
  countryCode: string;
  @persist
  @observable
  gender: string;
  @observable changePhNoStatus: APIStatus;
  @observable changePhNoVerifyStatus: APIStatus;
  @observable changePhNoOtpResendStatus: APIStatus;
  @observable updateProfileStatus: APIStatus;
  @observable newPhoneNumber: string;
  @observable newCountryCode: string;
  @persist("list", LoginFranchiseDetails)
  @observable
  franchiseDetails: IObservableArray<LoginFranchiseDetails> = [];
  @observable appConfig: AppConfig;

  @observable genderOptions: Array<Object>;

  @observable listOfFranchisesStatus: APIStatus;
  @observable appConfigStatus: APIStatus;

  @observable commonSelectedFranchiseId: number;

  @observable userProfileStatus: APIStatus;

  @persist("object", ZendeskConfig)
  @observable
  zendeskConfig: ZendeskConfig;

  languageOptions: Array<Object>;

  constructor() {
    super();
    this.init();
  }

  @action.bound
  init() {
    this.appConfig = new AppConfig();
    this.listOfFranchisesStatus = API_INITIAL;
    this.appConfigStatus = API_INITIAL;
    this.userProfileStatus = API_INITIAL;
    this.gender = BackendConstants.GENDER_TYPE_NA;
    this.genderOptions = [
      {
        label: this.genderMaleLabel,
        checked: false,
        option: BackendConstants.GENDER_TYPE_MALE,
      },
      {
        label: this.genderFemaleLabel,
        checked: false,
        option: BackendConstants.GENDER_TYPE_FEMALE,
      },
    ];
    this.languageOptions = [
      {
        label: this.languageEnglishLabel,
        value: BackendConstants.LANGUAGE_TYPE_ENGLISH,
      },
      {
        label: this.languageHindiLabel,
        value: BackendConstants.LANGUAGE_TYPE_HINDI,
      },
      {
        label: this.languageTeluguLabel,
        value: BackendConstants.LANGUAGE_TYPE_TELUGU,
      },
      {
        label: this.languageTamilLabel,
        value: BackendConstants.LANGUAGE_TYPE_TAMIL,
      },
      {
        label: this.languageKannadaLabel,
        value: BackendConstants.LANGUAGE_TYPE_KANNADA,
      },
    ];
    this.language = BackendConstants.LANGUAGE_TYPE_ENGLISH;
    this.countryCode = COUNTRY_CODE;
    this.zendeskConfig = new ZendeskConfig();
    this.commonSelectedFranchiseId = -1;
  }

  @computed
  get commonSelectedFranchise(): LoginFranchiseDetails {
    const franchiseDetails = this.franchiseDetails.find(
      (franchise) => franchise.franchiseId === this.commonSelectedFranchiseId
    );
    if (franchiseDetails) {
      return franchiseDetails;
    }
    return this.franchiseDetails[0];
  }

  @action.bound
  setCommonSelectedFranchiseId(id: string) {
    this.commonSelectedFranchiseId = id;
  }

  @computed
  get genderMaleLabel(): string {
    return "I18n.t(franchiseLogin.male)";
  }

  @computed
  get genderFemaleLabel(): string {
    return "I18n.t(franchiseLogin.female)";
  }

  @computed
  get languageEnglishLabel(): string {
    return "I18n.t(franchiseLogin.english)";
  }

  @computed
  get languageHindiLabel(): string {
    return "I18n.t(franchiseLogin.hindi)";
  }

  @computed
  get languageTeluguLabel(): string {
    return "I18n.t(franchiseLogin.telugu)";
  }

  @computed
  get languageTamilLabel(): string {
    return "I18n.t(franchiseLogin.tamil)";
  }

  @computed
  get languageKannadaLabel(): string {
    return "I18n.t(franchiseLogin.kannada)";
  }

  getRequestData() {
    return {
      profilePic: this.profilePic,
      additionalAttributes: this.additionalAttributes,
      name: this.name,
      language: this.language,
      emailId: this.emailId,
      country: this.country,
      phoneNumber: this.phoneNumber,
      countryCode: this.countryCode,
      id: this.id,
      type: this.userType,
    };
  }

  @action.bound
  setProfile(profile: APIProfile) {
    settingsStore.setAppLanguage(profile.language);
    this.setId(profile.user_id);
    this.setUserType(profile.user_type);
    this.setName(profile.name);
    this.setProfilePic(profile.profile_pic);
    this.setAdditionalAttributes(profile.additional_attributes);
    this.setLanguage(profile.language);
    this.setEmailId(profile.email_id);
    this.setCountry(profile.country);
    this.setGender(profile.gender);
    this.setFranchiseDetails(profile.franchise_details);
    this.zendeskConfig.updateDetails(profile.zendesk_config);
  }

  @action.bound
  setProfileResponse(user: APIProfileResponse) {
    const profile = {
      user_id: user.user_id,
      user_type: user.user_type,
      franchise_details: user.franchise_details,
      zendesk_config: user.zendesk_config,
    };
    this.setProfile({ ...profile, ...user.profile });
  }

  @action.bound
  setFranchiseDetails(franchiseDetails: APIGetFranchisesResponse) {
    this.franchiseDetails.replace(
      franchiseDetails.map((eachFranchise) => {
        const franchiseInstance = new LoginFranchiseDetails();
        franchiseInstance.setFranchiseDetails(eachFranchise);
        return franchiseInstance;
      })
    );
    this.setDefaultFranchise();
  }

  @action.bound
  setDefaultFranchise() {
    if (this.franchiseDetails.length > 0) {
      this.commonSelectedFranchiseId = this.franchiseDetails[0].franchiseId;
    }
  }

  @computed
  get selectedFranchise(): LoginFranchiseDetails {
    return this.franchiseDetails[0];
  }

  @action.bound
  setAdditionalAttributes(additionalAttributes: Array<AdditionalAttr>) {
    this.additionalAttributes.replace(
      additionalAttributes.map((eachAttribute) => {
        const attr = new AdditionalAttr();
        attr.setAttribute(eachAttribute);
        return attr;
      })
    );
  }

  @action.bound
  setGender(gender: string) {
    this.genderOptions.forEach((eachOption) => {
      if (eachOption.option === gender) {
        eachOption.checked = true;
        this.gender = gender;
      } else {
        eachOption.checked = false;
      }
    });
  }

  @action.bound
  setLanguage(language: string) {
    this.language = language;
  }

  @action.bound
  setEmailId(emailId: string) {
    this.emailId = emailId;
  }

  @action.bound
  setCountry(country: string) {
    this.country = country;
  }

  @action.bound
  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  @action.bound
  setCountryCode(countryCode: string) {
    this.countryCode = countryCode;
  }

  @action.bound
  setNewPhoneNumber(phoneNumber: string) {
    this.newPhoneNumber = phoneNumber;
  }

  @action.bound
  setChangePhNoStatus(status: APIStatus) {
    this.changePhNoStatus = status;
  }

  changePhNoSuccess() {
    if (true) console.log("Success");
  }

  @action.bound
  setChangePhNoVerifyStatus(status: APIStatus) {
    this.changePhNoVerifyStatus = status;
  }

  @action.bound
  changePhNoVerifySuccess() {
    this.setPhoneNumber(this.newPhoneNumber);
    this.setNewPhoneNumber("");
  }

  @action.bound
  setChangePhNoOtpResendStatus(status: APIStatus) {
    this.changePhNoOtpResendStatus = status;
  }

  resendChangePhNoOtpSuccess() {
    if (true) console.log("Success");
  }

  @action.bound
  setUpdateProfileStatus(status: APIStatus) {
    this.updateProfileStatus = status;
  }

  @action.bound
  setUserProfileStatus(status: APIStatus) {
    this.userProfileStatus = status;
  }

  @action.bound
  updateProfileSuccess(response) {
    settingsStore.setAppLanguage(response.language);
  }

  // TODO:Should make country code dynamic//
  changePhoneNumber() {
    const requestObject = {
      new_value: {
        phone_number: this.newPhoneNumber,
        country_code: "91",
      },
      old_value: {
        phone_number: this.phoneNumber,
        country_code: "91",
      },
    };
    this.changePhoneNumberApi(requestObject);
  }

  @action.bound
  async changePhoneNumberApi(
    requestObject: APIChangeUserPhoneNumberRequest,
    shouldUpdateImmediately: boolean = true
  ) {
    const changePhNoPromise = profileAPI.changePhoneNumberApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(changePhNoPromise)
        .to(this.setChangePhNoStatus, this.changePhNoSuccess)
        .catch((error) => {
          if (true) console.log(error);
          CommonMethods.displayApiError(error);
        });
    }
    return changePhNoPromise;
  }

  // TODO:Should make country code dynamic//
  changePhoneNumberVerify(otp: string) {
    const requestObject = {
      phone_number: this.newPhoneNumber,
      country_code: "91",
      otp,
    };
    this.changePhoneNumberOtpVerifyApi(requestObject);
  }

  @action.bound
  async changePhoneNumberOtpVerifyApi(
    requestObject: APIVerifyChangeUserPhoneNumberOTPRequest,
    shouldUpdateImmediately: boolean = true
  ) {
    const changePhNoOtpPromise = profileAPI.changePhoneNumberOtpVerifyApi(
      requestObject
    );
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(changePhNoOtpPromise)
        .to(this.setChangePhNoVerifyStatus, this.changePhNoVerifySuccess)
        .catch((error) => {
          if (true) console.log(error);
          CommonMethods.displayApiError(error);
        });
    }
    return changePhNoOtpPromise;
  }

  @action.bound
  async getUserProfileApi(shouldUpdateImmediately: boolean = true) {
    const profilePromise = profileAPI.getUserProfileApi();
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(profilePromise).to(
        this.setUserProfileStatus,
        this.setProfileResponse
      );
    }
    return profilePromise;
  }

  // TODO:Should make country code dynamic//
  resendChangePhoneNumberOtp() {
    const requestObject = {
      phone_number: this.newPhoneNumber,
      country_code: "91",
    };
    this.changePhoneNumberOtpResendApi(requestObject);
  }

  @action.bound
  async changePhoneNumberOtpResendApi(
    requestObject: APIPhoneNumber,
    shouldUpdateImmediately: boolean = true
  ) {
    const changePhNoOtpResendPromise = profileAPI.changePhoneNumberOtpResendApi(
      requestObject
    );
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(changePhNoOtpResendPromise)
        .to(this.setChangePhNoOtpResendStatus, this.resendChangePhNoOtpSuccess)
        .catch((error) => {
          if (true) console.log(error);
          CommonMethods.displayApiError(error);
        });
    }
    return changePhNoOtpResendPromise;
  }

  updateProfile = (profile: Object) => {
    let attributes = [];
    if (profile.additionalAttributes.length > 0) {
      attributes = profile.additionalAttributes.map((attrib) =>
        attrib.getRequestData()
      );
    } else {
      attributes = [];
    }
    const requestObject = {
      profile_pic: profile.profilePic,
      additional_attributes: attributes,
      name: profile.name,
      language: profile.language,
      email_id: profile.emailId,
      country: profile.country,
      gender: profile.gender,
    };
    return this.updateUserProfileApi(requestObject);
  };

  @action.bound
  async updateUserProfileApi(
    requestObject: APIBasicProfile,
    shouldUpdateImmediately: boolean = true
  ) {
    const updateProfilePromise = profileAPI.updateUserProfileApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(updateProfilePromise)
        .to(this.setUpdateProfileStatus, () => {
          this.updateProfileSuccess(requestObject);
        })
        .catch((error) => {
          if (true) console.log(error);
          CommonMethods.displayApiError(error);
        });
    }
    return updateProfilePromise;
  }

  @action.bound
  getFranchisesRequest(): APIGetFranchisesParameter {
    return { user_ids: [this.id] };
  }

  @action.bound
  setListOfFranchisesStatus(status: APIStatus) {
    this.listOfFranchisesStatus = status;
  }

  @action.bound
  setAppConfigStatus(status: APIStatus) {
    this.appConfigStatus = status;
  }

  @action.bound
  getFranchiseDetails(shouldUpdateImmediately?: boolean = true) {
    const franchiseDetailsPromise = profileAPI.getFranchiseDetailsAPI(
      this.getFranchisesRequest()
    );
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(franchiseDetailsPromise).to(
        this.setListOfFranchisesStatus,
        this.setFranchiseDetails
      );
    }
    return franchiseDetailsPromise;
  }

  @action.bound
  getAppConfig(shouldUpdateImmediately?: boolean = true) {
    const appConfigPromise = profileAPI.getAppConfig();
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(appConfigPromise)
        .to(this.setAppConfigStatus, this.setAppConfig)
        .catch((e) => {
          this.appConfigError = e;
        });
    }
    return appConfigPromise;
  }

  @action.bound
  setAppConfig(appConfig: APIAppConfigResponse) {
    this.appConfig.updateDetails(appConfig);
  }

  clearStore() {
    this.additionalAttributes.clear();
    this.franchiseDetails.clear();
    this.language = "";
    this.emailId = "";
    this.country = "";
    this.phoneNumber = "";
    this.countryCode = COUNTRY_CODE;
    this.changePhNoStatus = API_INITIAL;
    this.changePhNoVerifyStatus = API_INITIAL;
    this.changePhNoOtpResendStatus = API_INITIAL;
    this.updateProfileStatus = API_INITIAL;
    this.listOfFranchisesStatus = API_INITIAL;
    this.appConfigStatus = API_INITIAL;
    this.userProfileStatus = API_INITIAL;
    this.newPhoneNumber = "";
    this.newCountryCode = "";
  }
}

const userProfile = new UserProfile();

export const pourPromise = Promise.all(
  Object.keys(userProfile).map((key) => pour(key, userProfile))
);

export { userProfile as default, UserProfile };

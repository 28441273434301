/*
 * @flow
 * Author: Sunny
 */

// import { Alert } from "react-native";
// import I18n from 'react-native-i18n'
import type { APIError, ErrorType } from "../stores/types";
import i18n from "../i18n";

// TODO: toCurrency - const newOptions = { ...defaultOptions, ...options } line has to be modified

export const CRORES = "CRORES";
export const LAKHS = "LAKHS";
export const THOUSANDS = "THOUSANDS";

class CommonMethods {
  static toPrecision(amount, precision) {
    const decimalPosition = amount.indexOf(".");
    if (decimalPosition === -1) {
      return amount;
    }

    return amount.substring(0, amount.indexOf(".") + precision);
  }
  static numberWithComma(amount) {
    const currencyValue = this.toCurrency(amount, {
      precision: 0,
      isCurrency: false,
      currencyCode: "INR",
    });
    return currencyValue;
  }
  static toCurrency(
    rupees,
    options = {
      precision: 0,
      isCurrency: true,
      currencyCode: "INR",
      minimizeLimit: -1,
      minimizePrecision: 0,
    }
  ) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
    //  possible currency codes : https://www.currency-iso.org/dam/downloads/lists/list_one.xls
    // we can remove the style object to remove rupee symbol as output
    /* A=1000
        A.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})

        >>> "₹ 1,000"
     */
    if (!rupees && rupees !== 0) {
      return null;
    }
    let positiveNumber = rupees;
    let isNegative = false;
    if (rupees < 0) {
      isNegative = true;
      positiveNumber = -rupees;
    }
    const defaultOptions = {
      precision: 0,
      isCurrency: false,
      currencyCode: "INR",
      minimizeLimit: -1,
      minimizePrecision: 0,
    };
    let isMinimized = false;
    const newOptions = { ...defaultOptions, ...options };
    newOptions.minimizeLimit = this.getMinimizationLimit(
      newOptions.minimizeLimit
    );
    let minimizedValues = [positiveNumber, ""];
    if (
      newOptions.minimizeLimit > 0 &&
      positiveNumber >= newOptions.minimizeLimit
    ) {
      minimizedValues = this.numDifferentiation(positiveNumber);
      isMinimized = true;
    }
    const {
      precision,
      minimizePrecision,
      isCurrency,
      currencyCode,
    } = newOptions;
    let outPutStyle = "currency";
    if (!isCurrency) {
      outPutStyle = "decimal";
    }
    const outputOptions = {
      style: outPutStyle,
      currency: currencyCode,
      minimumFractionDigits: isMinimized ? minimizePrecision : precision,
      maximumFractionDigits: isMinimized ? minimizePrecision : precision,
    };

    const outNumber = Number(minimizedValues[0]).toLocaleString(
      "en-IN",
      outputOptions
    );
    let sendingNumber = outNumber;
    if (isNegative) {
      sendingNumber = `-${outNumber}`;
    }
    if (minimizedValues[1]) {
      return `${sendingNumber} ${minimizedValues[1]}`;
    }

    return sendingNumber;
  }

  static getMinimizationLimit(value: string | number): number {
    if (value === CRORES) {
      return 10000000;
    }
    if (value === LAKHS) {
      return 100000;
    }
    if (value === THOUSANDS) {
      return 1000;
    }
    return value;
  }

  static numDifferentiation(value) {
    let suffix = "";
    let val = value;
    if (val >= 10000000) {
      val = `${val / 10000000}`;
      suffix = "Cr";
    } else if (val >= 100000) {
      val = `${val / 100000}`;
      suffix = "L";
    } else if (val >= 1000) {
      val = `${val / 1000}`;
      suffix = "K";
    }
    return [val, suffix];
  }

  static displayApiError(error, showAlert: boolean = true): ErrorType {
    let description: string = "";
    let title: string = i18n.t("franchiseCommon.failure");
    let errorCode: number = 500;
    if (true) console.log(error, "error arg1");
    if (true) console.log(showAlert, "showAlert arg2");
    try {
      if (error.message) error = error.message; // eslint-disable-line no-param-reassign
    } catch (e) {
      if (true) console.log("err", e);
    }

    let parsedError = error;
    try {
      parsedError = JSON.parse(error);
    } catch (e) {
      if (true) console.log("err", e);
    }

    if (parsedError) {
      if (parsedError.response) {
        description = parsedError.response;
      }
      if (parsedError.http_status_code) {
        errorCode = parsedError.http_status_code;
        if (
          parsedError.http_status_code === 503 ||
          parsedError.problem === "NETWORK_ERROR"
        ) {
          title = i18n.t("franchiseCommon.connectionLost");
          description = i18n.t("franchiseCommon.yourInternetSeemsToBeOffline");
        }
      }
    }

    if (description === null || description === "") {
      title = i18n.t("franchiseCommon.failure");
      description = i18n.t("franchiseCommon.somethingWentWrong");
    }
    if (showAlert) {
      alert(title, description);
    }
    return { errorCode, title, description };
  }

  static getDeepIndexOf(arr, obj) {
    return arr.findIndex((cur) =>
      Object.keys(obj).every((key) => obj[key] === cur[key])
    );
  }

  static getIndexOf(arr, obj, key) {
    return arr.findIndex((cur) => cur[key] === obj[key]);
  }
}

export default CommonMethods;

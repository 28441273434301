import { observer } from "mobx-react";
import React, { Component } from "react";
import {
  RevenueCentersDiv,
  Icon,
  AreaTextAmount,
  AreaText,
  Amount,
} from "./styledComponents";

@observer
class RevenueCenters extends Component {
  render() {
    const { title, name, amount, color, id } = this.props;
    return (
      <RevenueCentersDiv>
        <Icon color={color}>{title}</Icon>
        <AreaTextAmount>
          <AreaText>{name}</AreaText>
          <Amount>{amount}</Amount>
        </AreaTextAmount>
      </RevenueCentersDiv>
    );
  }
}

export default RevenueCenters;

import styled from "@emotion/styled";
import {
  ReactSelectAndDatePickerDiv,
  ReactSelectButtonDiv,
} from "../../containers/RevenueSummaryWithFranchisesTabbar/styledComponents";
import ReactSelect from "../ReactSelect";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
`;

export const TabBarContainer = styled.div`
  display: flex;
`;

export const TabContainer = styled.div`
  height: 32px;
  padding: 6px 21px 6px 22px;
  border-radius: 3px;
  background-color: ${(props) => (props.state ? "#0d47a1" : "#d2e4ff")};
  color: ${(props) => (props.state ? "#ffffff" : "black")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabTextContainer = styled.div``;

export const TabBarValueContainer = styled.div``;

export const SummaryText = styled.div`
  font-family: CerebriSans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #13274b;
`;

export const ReactSelectButton = styled.div`
  margin-right: 10px;
`;

export const ReactSelectAndDatePicker = styled.div`
  display: flex;
`;

/*
* @flow
* @author: Dinesh
*/
import { observable, action } from 'mobx'
import PieDataSet from './PieDataSet'

class PieChartValue {
  @observable value: PieDataSet | number
  @observable color: string
  @observable legend: string

  constructor(value: PieDataSet | number, legend: string, color: string) {
    this.value = value
    this.color = color
    this.legend = legend
  }

  @action.bound
  setColor(color: string) {
    this.color = color
  }
}

export default PieChartValue

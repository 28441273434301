/**
 * @flow
 */

import React, { Component } from "react";
import { observer } from "mobx-react";

import type FranchiseEntity from "../../stores/models/FranchiseEntity";

import PaymentSummaryTable from "../PaymentSummaryTable";
import { toJS } from "mobx";

type PaymentSummaryProps = {
  franchiseEntity: FranchiseEntity,
};

@observer
class PaymentSummary extends Component<PaymentSummaryProps> {
  renderPaymentSummarySection = () => {
    const { franchiseEntity } = this.props;
    const { paymentSummaryStore } = franchiseEntity;
    const { paginationStore } = paymentSummaryStore;
    const {
      currentPageEntities,
      entitiesFetchingStatus,
      totalPages,
      currentPage,
      apiError,
    } = paginationStore;

    return (
      <PaymentSummaryTable
        paymentSummaryItems={currentPageEntities}
        loadingStatus={entitiesFetchingStatus}
        onSortChange={paymentSummaryStore.onSortChanged}
        totalPagesCount={totalPages}
        currentPage={currentPage}
        onPagePress={paymentSummaryStore.onPagePress}
        error={apiError}
        onRetry={paymentSummaryStore.onReload}
        paymentSummaryStore={paymentSummaryStore}
      />
    );
  };

  render() {
    return this.renderPaymentSummarySection();
  }
}

export default PaymentSummary;

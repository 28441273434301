import * as React from "react";

function SvgComponent(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 100 100" {...props}>
      <defs>
        <path
          id="a"
          d="M99.67 50c0 27.614-22.312 50-49.835 50S0 77.614 0 50 22.312 0 49.835 0 99.67 22.386 99.67 50"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#5681BF" xlinkHref="#a" />
        <g fill="#ECF4FF" mask="url(#b)">
          <path d="M16.383 97.3c1.674-16.965 15.89-30.26 33.122-30.26 17.233 0 31.449 13.294 33.122 30.26L49.235 102l-32.852-4.7zm14.814-55.475c0-10.196 8.215-18.491 18.308-18.491s18.31 8.295 18.31 18.49c0 10.197-8.217 18.492-18.31 18.492-10.093 0-18.308-8.295-18.308-18.492z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

SvgComponent.defaultProps = {
  width: 100,
  height: 100,
};

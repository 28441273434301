import React, { Component } from 'react';
import { Container, TextWithAmountDiv, Text, Amount } from './styledComponents'
class TotalIconTextAmountCard extends Component {
    render() {
        const { icon:Icon, title, width, color, height, amount} = this.props;
        return (
            <Container>
                <Icon width={width} height={height} fill={color} />
                <TextWithAmountDiv>
                    <Text>{title}</Text>
                    <Amount>{amount}</Amount>
                </TextWithAmountDiv>
            </Container>
        );
    }
}

export default TotalIconTextAmountCard;
/*
 * @flow
 * @Author: Chinmaya
 */

import styled from "@emotion/styled";

import Colors from "../../../themes/Colors"; // eslint-disable-line

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isActive ? Colors.paleGrey : Colors.otto};
  padding: 9px 14px;
  border: ${(props) =>
    props.isActive || props.isEnabled
      ? "1px solid #ebedf0"
      : null};
  cursor : ${(props) => (props.isActive || props.isEnabled ? 'pointer' : 'not-allowed')};
`;

export const ViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.span`
  font-family: "CerebriSans";
  font-size: 14px;
  text-align: left;
  color: ${Colors.twilightBlue};
  cursor: pointer;
`;

export const Body = styled.span`
  font-family: "CerebriSans";
  font-size: 14px;
  text-align: left;
  color: ${Colors.nightSky};
  cursor: pointer;
`;

/*
 * @flow
 * @author: Chinmaya
 */

import { observable, action } from "mobx";
import { API_INITIAL } from "@ib/api-constants";

import type { APIStatus } from "@ib/api-constants";

class UIStore {
  @observable homeStatus: APIStatus = API_INITIAL;

  @observable homeError: Error;

  @action.bound
  setHomeStatus(status: APIStatus) {
    this.homeStatus = status;
  }

  @action.bound
  setHomeError(error: Error) {
    this.homeError = error;
  }

  @action.bound
  clearStore() {
    this.setHomeStatus(API_INITIAL);
    this.setHomeError(null);
  }
}

export default UIStore;

/*
 * @flow
 */

import { observable } from 'mobx'
import type { IObservableArray } from 'mobx'

import Franchise from '../Franchise/index.js'

import StatItem from '../../../../FranchiseManagement/stores/models/Franchise/StatItem'
import type {
  APIFranchiseDetail,
  APIQualityScore,
  APIQualityChecklistItems
} from '../../type'
import QualityChecklistItems from './QualityChecklistItems'
import QualityScore from './QualityScore'

class FranchiseDetail {
  @observable franchise: Franchise
  @observable stats: IObservableArray<StatItem>
  @observable isOpen: boolean
  @observable aggregateRating: number
  @observable qualityScore: APIQualityScore
  @observable qualityChecklistItems: APIQualityChecklistItems

  constructor(franchiseDetail: APIFranchiseDetail) {
    this.franchise = new Franchise(franchiseDetail.franchise)
    this.stats = franchiseDetail.stats.map((item) => new StatItem(item))
    this.aggregateRating = franchiseDetail.aggregate_rating
    this.isOpen = franchiseDetail.is_open
    this.qualityScore = new QualityScore(franchiseDetail.quality_score)
    this.qualityChecklistItems = new QualityChecklistItems(
      franchiseDetail.quality_checklist_items
    )
  }
}

export default FranchiseDetail

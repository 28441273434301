/*
 * @author: Tanmay B
 * @flow
 */

import { observable, computed } from 'mobx'
import CommonMethods from '../../../../../Common/utils/CommonMethods'
import { TRANSACTION_DATE_TIME_FORMAT } from '../../../../constants/AppConstants'
import { getDisplayDate } from '../../../../../Common/utils/DateUtils'
import { type APITransactionLogItem } from '../../../type'

class TransactionItem {
  @observable franchiseId: number
  @observable franchiseName: string
  @observable channelName: string
  @observable transactedAmount: number
  @observable ownerAmount: number
  @observable orderId: string
  @observable transactedTime: string
  @observable channelId: number
  @observable paymentMode: string
  @observable transactionId: string
  @observable invoiceUrl: string

  constructor(transactionLogItem: APITransactionLogItem) {
    this.franchiseId = transactionLogItem.franchise_id
    this.franchiseName = transactionLogItem.franchise_name
    this.channelName = transactionLogItem.channel_name
    this.transactedAmount = transactionLogItem.transacted_amount
    this.ownerAmount = transactionLogItem.owner_amount
    this.orderId = transactionLogItem.order_id
    this.transactedTime = transactionLogItem.transacted_time
    this.channelId = transactionLogItem.channel_id
    this.paymentMode = transactionLogItem.payment_mode
    this.transactionId = transactionLogItem.transaction_id
    this.invoiceUrl = transactionLogItem.invoice_url
  }
  @computed
  get dateTime(): string {
    return getDisplayDate(this.transactedTime, TRANSACTION_DATE_TIME_FORMAT)
  }

  @computed
  get amount(): any {
    return CommonMethods.toCurrency(this.transactedAmount)
  }

  @computed
  get displayOwnerAmount(): any {
    return CommonMethods.toCurrency(this.ownerAmount)
  }
}
export default TransactionItem

import React from "react";
import Select from "react-select";

import { Container } from "./styledComponents";

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', height: 35, minHeight: 20, fontFamily:'CerebriSans', fontSize:14, border:'1px solid #b2b9c4' })
};

class ReactSelect extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption);
  };
  render() {
    const { selectedOption } = this.state;
    const { options, formatOptionsForReactSelect, className } = this.props;

    return (
      <Container className={className}>
        <Select
          value={selectedOption}
          {...this.props}
          options={
            formatOptionsForReactSelect
              ? formatOptionsForReactSelect(options)
              : options
          }
          onChange={this.handleChange}
          styles={colourStyles}
        />
      </Container>
    );
  }
}

export default ReactSelect;

ReactSelect.defaultProps = {
  options: [],
};

/*
 * @flow
 * @author : Dinesh
 */

// import I18n from "react-native-i18n";
import PieChartValue from "../../Common/stores/GraphsData/PieChartGraph/PieChartValue";
import Colors from "../themes/Colors";
import { getDisplayDate } from "./DateUtils";

class GraphUtils {
  static convertDateTimeToYYMMM(dateTime: string): string {
    return getDisplayDate(dateTime, "DD MMM");
  }

  static formatDateTimeXvaluesToMMMYY(values: Array<string>): Array<string> {
    if (values === undefined || values === null) {
      return [];
    }
    return values.map((eachValue) => this.convertDateTimeToYYMMM(eachValue));
  }

  static getRandomPieDataColors(length: number) {
    const pieDataColors = [
      Colors.bumi,
      Colors.deepLilac,
      Colors.izumi,
      Colors.mango,
    ];
    const colors = [];
    let prev = -1;
    for (let index = 0; index < length; index += 1) {
      let random = Math.floor(Math.random() * pieDataColors.length);
      while (prev === random) {
        random = Math.floor(Math.random() * pieDataColors.length);
      }
      colors.push(pieDataColors[random]);
      prev = random;
    }
    return colors;
  }

  static getFormattedPieGraphDataWithOthers(
    values: Array<number>,
    legends: Array<string>,
    legendCharsLimit?: number = 1000
  ) {
    const pieDataColors = [
      Colors.bumi,
      Colors.deepLilac,
      Colors.izumi,
      Colors.mango,
      Colors.steel,
    ];
    let newValues = [];
    if (values && values.length > 0) {
      values.forEach((value, index) => {
        if (value > 0) {
          let legend = legends[index];
          if (legends[index].length > legendCharsLimit) {
            legend = `${legends[index].slice(0, legendCharsLimit)}...`;
          }
          newValues.push(new PieChartValue(value, legend, ""));
        }
      });
      if (newValues.length > 5) {
        newValues.sort((a, b) => a.value < b.value);
        newValues[4] = new PieChartValue(
          this.getOthersSum(newValues),
          "I18n.t(franchiseCommon.others)",
          ""
        );
        newValues = newValues.slice(0, 5);
      }
      newValues.forEach((eachValue, index) => {
        eachValue.setColor(pieDataColors[index]);
      });
      return newValues;
    }
    return [];
  }

  static getOthersSum(values: Array<Object>) {
    if (values && values.length > 5) {
      const othersValues = values.slice(4, values.length);
      let sum = 0;
      othersValues.forEach((eachValue) => {
        sum += eachValue.value;
      });
      return sum;
    }
    return 0;
  }

  static getTimeOfDayFromLabel(label: string): string {
    const values = label.split(":");
    if (values.length === 3) {
      const labelTime = parseInt(values[0], 10);
      if (labelTime < 12) {
        return `${labelTime}a`;
      } else if (labelTime === 12) {
        return "12p";
      }
      return `${labelTime - 12}p`;
    }
    throw new Error(
      `getTimeOfDayFromLabel accepts label of format hh:mm:ss but received ${label}`
    );
  }
}

export default GraphUtils;

/*
* @flow
* @author: Dinesh
*/

export const TRANSACTION_DATE_TIME_FORMAT = 'DD MMM YYYY hh:mm A'
export const TRANSACTION_DATE_FORMAT = 'DD MMM YYYY'
export const CALENDAR_FORMAT = 'YYYY-MM-DD'

export const FRANCHISE_DEFAULT_ID = -1
export const CHANNEL_DEFAULT_ID = -1

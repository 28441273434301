/**
 * @flow
 * @author: @ib/Chinmaya
 */

import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ImageTitleAndNumber from "../../../Common/components/ImageTitleAndNumber/ImageTitleAndNumber";
import SettlementIncome from "../../../Common/components/SettlementIncome/SettlementIncome";
import NextArrow from "../../../Common/Icons/NextArrow";
import RevenueCurrencyIcon from "../../../Common/Icons/RevenueCurrencyIcon";
import RevenueMoneyIcon from "../../../Common/Icons/RevenueMoneyIcon";
import { REVENUE_SUMMARY_SCREEN } from "../../../constants/RouteConstants";
// import DashboardSummaryItemCardContainer from "../DashboardSummaryItemCardContainer";
import RevenueSummaryAreaGraph from "../RevenueSummaryAreaGraph";

import {
  Container,
  Card,
  CardDiv,
  ImageLogo,
  Heading,
  IncomeDetails,
  ViewDetailsDiv,
  ViewDetails,
  ViewDetailsText,
} from "./styledComponents";

@observer
class RevenueSummaryItemCard extends Component {
  navigateToRevenue = () => {
    this.props.history.push(REVENUE_SUMMARY_SCREEN);
  };

  render() {
    const {
      revenueData,
      navigateToCashOuts,
      graphData,
      hideHeaderAndWelcomeText,
    } = this.props;
    return (
      <Container>
        <CardDiv>
          <RevenueMoneyIcon/>
          <Heading>Revenue</Heading>
        </CardDiv>
        <IncomeDetails>
          <ImageTitleAndNumber
            icon={RevenueCurrencyIcon}
            size={30}
            text="Todays Income"
            number={revenueData.income}
            currency={"₹ "}
          />
          <SettlementIncome settlementAmount={revenueData.settlementAmount} />
        </IncomeDetails>
        <RevenueSummaryAreaGraph graphData={graphData} />
        {hideHeaderAndWelcomeText ? null : (
          <ViewDetailsDiv>
            <ViewDetails onClick={this.navigateToRevenue}>
              <ViewDetailsText>View details</ViewDetailsText>
              <NextArrow width={5} height={10} color={"#0d47a1"} />
            </ViewDetails>
          </ViewDetailsDiv>
        )}
      </Container>
    );
  }
}

export default withRouter(RevenueSummaryItemCard);

// navigateToRevenue = () => {
// gotoRevenueSummary()
// }

// graphLeftYAxisData = {
//   gridLineWidth: 1,
//   gridDashedLine: {
//     lineLength: 1,
//     spaceLength: 2,
//     phase: 0
//   },
//   axisMinimum: 0,
//   labelCount: 5
// }

// graphXAxisData = {
//   gridLineWidth: 1,
//   gridDashedLine: {
//     lineLength: 1,
//     spaceLength: 2,
//     phase: 0
//   }
// }

// graphLegendData = {
//   enabled: false
// }
// import { gotoRevenueSummary } from '../../../FranchiseRevenueManagement/utils/RevenueManagementNavigationUtils'

// import type RevenueData from '../../stores/models/RevenuesSection'

// type RevenueSummaryItemCardProps = {
//   revenueData: RevenueData,
//   navigateToCashOuts: () => void
// }

/**
 * @flow
 * @author: Dinesh
 * */

import { observable, action } from 'mobx'
import { type APIOperatorAppConfigResponse } from '../../UserProfile/type'

class OperatorConfig {
  @observable showAccounts: boolean
  @observable showFmcg: boolean
  @observable showRentals: boolean
  @observable showMemberships: boolean
  @observable showTodayAccountSummary: boolean
  @observable showSlots: boolean
  @observable showSlotStatus: boolean
  @observable showCampaigns: boolean

  constructor() {
    this.showAccounts = false
    this.showFmcg = false
    this.showRentals = false
    this.showMemberships = false
    this.showTodayAccountSummary = false
    this.showSlots = false
    this.showSlotStatus = false
    this.showCampaigns = false
  }

  @action.bound
  updateDetails(operatorConfig: APIOperatorAppConfigResponse) {
    this.showAccounts = operatorConfig.show_accounts
    this.showFmcg = operatorConfig.show_fmcg
    this.showRentals = operatorConfig.show_rentals
    this.showMemberships = operatorConfig.show_memberships
    this.showTodayAccountSummary = operatorConfig.show_today_account_summary
    this.showSlots = operatorConfig.show_slots
    this.showSlotStatus = operatorConfig.show_slot_status
    this.showCampaigns = operatorConfig.show_campaigns
  }
}

export default OperatorConfig

/*
 * @flow
 * @Author: Chinmaya
 */

import React, { Component } from "react";
import { observer } from "mobx-react";
import { observable, computed, action } from "mobx";
// import Previous from "icons/Previous"; // eslint-disable-line
// import Next from "icons/Next"; // eslint-disable-line

// import { StyleSheet } from 'react-native' // eslint-disable-line

import PreviousArrow from '../../../Common/Icons/PreviousArrow'
import NextArrow from '../../../Common/Icons/NextArrow'

import { Container, SubContainer, NavigationImg } from "./styledComponents";
import NavigationButton, { NavigationButtonWithText } from "./NavigationButton";
// import styles from "./styles";

type Page = number;

type Props = {
  totalPagesCount: number,
  currentPage: Page,
  maxDisplayPagesCount: number,
  onPagePress: (page: Page) => mixed,
  style?: any,
};

type NavigationState = "SHOW_MORE" | "SHOW_LESS";

const SHOW_MORE = "SHOW_MORE";
const SHOW_LESS = "SHOW_LESS";

const MORE_BUTTON_TEXT = "...";

@observer
export class PaginationNavigation extends Component<Props> {
  @observable navigationState: NavigationState = SHOW_MORE; // eslint-disable-line

  @computed
  get lastPage(): Page {
    if (this.navigationState === SHOW_LESS) {
      return this.props.currentPage + (this.props.maxDisplayPagesCount - 1);
    }
    return this.props.totalPagesCount;
  }

  @computed
  get displayPages(): Array<string | number> {
    const pages: Array<string | number> = [];
    
    if (this.props.maxDisplayPagesCount >= this.props.totalPagesCount) {
      for (let i = 1; i <= this.props.totalPagesCount; i += 1) {
        pages.push(i);
      }
      return pages;
    }
    const rightLimit =
      this.props.totalPagesCount - this.props.maxDisplayPagesCount;
    if (this.props.currentPage > rightLimit) {
      for (let i = rightLimit + 1; i <= this.props.totalPagesCount; i += 1) {
        pages.push(i);
      }
      return pages;
    }
    if (this.navigationState === SHOW_LESS) {
      for (let i = this.props.currentPage; i <= this.lastPage; i += 1) {
        pages.push(i);
      }
      return pages;
    }
    // TODO: Create pages based on maxDisplayPagesCount
    pages.push(this.props.currentPage);
    pages.push(this.props.currentPage + 1);
    pages.push(MORE_BUTTON_TEXT);
    pages.push((this.props.totalPagesCount - 1).toString());
    pages.push(this.props.totalPagesCount.toString());

    return pages;
  }

  @computed
  get isLeftArrowEnabled(): boolean {
    return this.props.currentPage !== 1;
  }

  @computed
  get isRightArrowEnabled(): boolean {
    return this.props.currentPage !== this.props.totalPagesCount;
  }
  @action
  setNavigationState(state: NavigationState) {
    this.navigationState = state;
  }

  renderLeftArrow = () => {
    if (this.props.totalPagesCount <= 1) {
      return null;
    }

    const backPress = () => {
      const page = this.props.currentPage - 1;
      this.props.onPagePress(page);
    };

    return (
      <NavigationButton
        // containerStyle={styles.leftArrow}
        isEnabled={this.isLeftArrowEnabled}
        isActive={false}
        onClick={backPress}
      >
        {/* <Previous /> */}
        <PreviousArrow />
      </NavigationButton>
    );
  };

  renderRightArrow = () => {
    if (this.props.totalPagesCount <= 1) {
      return null;
    }

    const frontPress = () => {
      const page = this.props.currentPage + 1;
      this.props.onPagePress(page);
    };
    return (
      <NavigationButton
        // containerStyle={styles.rightArrow}
        isEnabled={this.isRightArrowEnabled}
        isActive={false}
        onClick={frontPress}
      >
        {/* <Next /> */}
        <NextArrow />
      </NavigationButton>
    );
  };

  renderPages = () => {
    if (this.props.totalPagesCount <= 1) {
      return null;
    }
    return this.displayPages.map((page) => {
      const onPress = () => {
        if (page !== MORE_BUTTON_TEXT) {
          this.props.onPagePress(parseInt(page, 10));
        }
      };
      return (
        <NavigationButtonWithText
          key={page}
          onClick={onPress}
          isEnabled={page !== MORE_BUTTON_TEXT}
          isActive={page === this.props.currentPage}
        >
          {page}
        </NavigationButtonWithText>
      );
    });
  };
  render() {
    const { style } = this.props;
    return (
      <Container style={style}>
        <SubContainer pagesCount={this.props.totalPagesCount} style={style}>
          {this.renderLeftArrow()}
          {this.renderPages()}
          {this.renderRightArrow()}
        </SubContainer>
      </Container>
    );
  }
}

export default PaginationNavigation;

/*
* @flow
*/

import BaseGraph from '../BaseGraph'

class ExpenditureGraph extends BaseGraph {
  constructor(
    entityId: number,
    entityType: string,
    basicFilters: BasicFilters,
    services: Services
  ) {
    super(entityId, entityType, basicFilters)
    this.getGraphSummaryAPI =
      services.revenueStoreService.getExpenditureGraphSummary
  }
}

export default ExpenditureGraph

import styled from "@emotion/styled";

export const SummaryCards = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-wrap: wrap; 
  width:100%;
  height:100%;
`
export const NoDataChannel = styled.div`
  width: 100%;
  height: 200px;
  font-size: 18px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`
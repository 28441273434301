import tw from "tailwind.macro";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

export const HeaderDiv = styled.div`
  font-family: "CerebriSans";
`;

export const TopBar = styled.div`
  ${tw`flex justify-between items-center`}
  border-bottom: 1px solid lightgrey;
  ${tw`w-full`}
  height:60px;
  background-color: #073782;
  position: fixed;
  z-index: 200;
  padding-left: 20px;
`;

export const DashboardHeaderText = styled.div`
  color: white;
  font-family: "CerebriSans";
  font-weight: 500;
  font-size: 22px;
`;

export const DashboardHeaderDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const BackWrapperImg = styled.img`
  width: 20px;
  height: 17px;
  margin: 0 20px 0 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const IbcLogoImage = styled.img`
  object-fit: contain;
  margin-right: 10px;
  height: 44px;
`;

export const HeaderNotify = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: center;
`;

export const NotificationIcon = styled.button`
  ${tw`hover:bg-blue-900 hover:text-white hover:border-3 hover:border-red-400`}
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 4px;
  padding: 10px;
`;

export const BellIcon = styled.img`
  object-fit: contain;
`;

export const Message = styled.img`
  object-fit: contain;
`;

export const NotifyNum = styled.div`
  position: absolute;
  top: 7px;
  right: 140px;
  border-radius: 50%;
  border: 1px solid red;
  background-color: red;
  height: 20px;
  width: 20px;
  font-size: 12px;
  padding: 2px;
  color: white;
`;

export const Profile = styled.img`
  ${tw`border-gray-800 cursor-pointer h-12 w-12 rounded text-xs`}
  border-radius: 50%;
`;

export const ProfileContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const UserName = styled.span`
  font-family: "CerebriSans";
  font-size: 16px;
  color: #fff;
  padding-left: 10px;
  font-weight: 500;
`;

export const SignOutButton = styled.button`
  ${tw`hover:bg-blue-900 hover:text-white`}
  position:absolute;
  top: 60px;
  right: 0px;
  font-family: "CerebriSans";
  font-size: 16px;
  margin: 7px 5px 25px 12px;
  padding: 8px 18px;
  border-radius: 3px;
  border: solid 1px #b2b9c4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
`;

export const ProfileSpan = styled.span`
  display: flex;
  align-self: center;
  margin: 8px 1px 8px 10px;
  font-size: 15px;
  ${tw`text-white`}
  font-family:'CerebriSans';
`;

export const SignOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

/**
 * @flow
 */

export const RESET_ALL = 0

export const TODAY = 1
export const YESTERDAY = 2
export const LAST_WEEK = 3
export const LAST_MONTH = 4
export const LAST_QUARTER = 5
export const CUSTOM_DATES = 6

export const dateFilterIndex = {
  resetAll: RESET_ALL,
  today: TODAY,
  yesterday: YESTERDAY,
  lastWeek: LAST_WEEK,
  lastMonth: LAST_MONTH,
  lastQuarter: LAST_QUARTER,
  customDates: CUSTOM_DATES
}

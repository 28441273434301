/**
 * @flow
 * @author: Dinesh
 * */

import { action } from 'mobx'
import { type APIAppConfigResponse } from '../../UserProfile/type'
import OperatorConfig from './OperatorConfig'

class AppConfig {
  operator: OperatorConfig

  constructor() {
    this.operator = new OperatorConfig()
  }

  @action.bound
  updateDetails(appConfig: APIAppConfigResponse) {
    this.operator.updateDetails(appConfig.operator)
  }
}

export default AppConfig

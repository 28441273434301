/*
 * @flow
 */

import { observable } from 'mobx'

import type { IObservableArray } from 'mobx'

import { NOT_UPDATED } from '../../../constants/FranchiseHealthConstants.js'

import Franchise from '../Franchise/index.js'
import CheckListItem from '../CheckListItem/index.js'

import type { APIFranchiseHealth, HealthStatus } from '../../type.js'

class FranchiseHealth {
  @observable franchise: Franchise
  @observable checkList: IObservableArray<CheckListItem>
  @observable healthStatus: HealthStatus
  @observable overAllPercentage: string

  constructor(franchiseHealth: APIFranchiseHealth) {
    this.franchise = new Franchise(franchiseHealth.franchise)
    this.checkList = franchiseHealth.check_list.map(
      (item) => new CheckListItem(item)
    )
    this.healthStatus = franchiseHealth.health_status
    this.overAllPercentage =
      franchiseHealth.health_status === NOT_UPDATED
        ? '-'
        : `${franchiseHealth.over_all_percentage.toString()}%`
  }
}
export default FranchiseHealth

/**
 * @flow
 * @author: @ib/Chinmaya
 */
import styled from "@emotion/styled";

export const CardContainer = styled.div``;

export const CardsOnBoardContainer = styled.div`
  font-family: "CerebriSans";
  width: 48%;
  /* height: 350px; */
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  position: relative;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

import * as React from "react";

import Colors from "../../themes/Colors";

function SvgComponent(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 11 16" {...props}>
      <defs>
        <filter
          id="prevArrow"
          width="105.3%"
          height="136.1%"
          x="-2.6%"
          y="-18.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation={1}
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.2471 0 0 0 0 0.2471 0 0 0 0 0.2667 0 0 0 0.15 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        filter="url(#prevArrow)"
        transform="translate(-12 -11)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M20 23l-5-5 5-5"
          stroke="#4F5E79"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path d="M6 6h24v24H6z" />
      </g>
    </svg>
  );
}

export default SvgComponent;

SvgComponent.defaultProps = {
  width: 11,
  height: 16,
};

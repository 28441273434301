/**
 * @flow
 * @author: @ib/Chinmaya
 */

import { observer } from "mobx-react";
import React, { Component } from "react";

// import type { ImageSourcePropType } from 'react-native'
// import type { StyleObj } from 'react-native/Libraries/StyleSheet/StyleSheetTypes'

import { MainContainer, Title } from "./styledComponents";

// type DashboardSummaryItemTitleWithIconProps = {
//   style?: StyleObj,
//   source: ImageSourcePropType,
//   title: string
// }

@observer
class DashboardSummaryItemTitleWithIcon extends Component {
  render() {
    const { icon: Icon, title } = this.props;
    return (
      <MainContainer>
        <Icon />
        <Title>{title}</Title>
      </MainContainer>
    );
  }
}

export default DashboardSummaryItemTitleWithIcon;

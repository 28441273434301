import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  HeaderContainer,
  TabBarContainer,
  TabTextContainer,
  TabContainer,
  TabBarDiv,
  TabBarValueContainer,
  SummaryText,
  ReactSelectButton,
  ReactSelectAndDatePicker
} from "./styledComponents";
import ChannelCards from "../ChannelCards";
import { summaryByTypes } from "../../constants/ChannelConstants";
import DatePickerWithPresetButtons from "../DatePickerWithPresetButtons";
import ReactSelect from "../ReactSelect";

const tabsList = [
  {
    name: summaryByTypes.revenueChannels,
    value: summaryByTypes.revenueChannels,
  },
  {
    name: summaryByTypes.expenditureChannels,
    value: summaryByTypes.expenditureChannels,
  },
];

const defaultSelectedTab = summaryByTypes.revenueChannels;

@observer
class RevenueExpenditureChannelsTabBar extends Component {
  @observable selectedTab = defaultSelectedTab;

  constructor(props) {
    super(props);
  }

  onClickTab = (value) => {
    this.selectedTab = value;
  };

  renderTabs = () => {
    return tabsList.map((tab) => {
      const onClickTabItem = () => this.onClickTab(tab.value);
      return (
        <TabContainer
          state={this.selectedTab === tab.value}
          key={tab.value}
          onClick={onClickTabItem}
        >
          <TabTextContainer>{tab.name}</TabTextContainer>
        </TabContainer>
      );
    });
  };

  onRetry = () => {
    this.props.entity.onChangeSummaryByChannelsTab();
  };

  getInitialStartDate = () => {
    const { revenueChannels, expenditureChannels } = this.props.entity;

    if (this.selectedTab === summaryByTypes.revenueChannels) {
      return new moment(
        revenueChannels.basicFilters.currentDateFilter.fromDate
      );
    } else if (this.selectedTab === summaryByTypes.expenditureChannels) {
      return new moment(
        expenditureChannels.basicFilters.currentDateFilter.fromDate
      );
    }
    return () => {};
  };

  getInitialEndDate = () => {
    const { revenueChannels, expenditureChannels } = this.props.entity;

    if (this.selectedTab === summaryByTypes.revenueChannels) {
      return new moment(revenueChannels.basicFilters.currentDateFilter.toDate);
    } else if (this.selectedTab === summaryByTypes.expenditureChannels) {
      return new moment(
        expenditureChannels.basicFilters.currentDateFilter.toDate
      );
    }
    return () => {};
  };

  getDateFilterChangeFunction = () => {
    const { revenueChannels, expenditureChannels } = this.props.entity;

    if (this.selectedTab === summaryByTypes.revenueChannels) {
      return revenueChannels.onChannelDateFilterChanged;
    } else if (this.selectedTab === summaryByTypes.expenditureChannels) {
      return expenditureChannels.onChannelDateFilterChanged;
    }
    return () => {};
  };

  formatOptionsForReactSelect = (data) => {
    return data.map((item) => {
      return {
        label: item.name,
        value: item.name,
        ...item,
      };
    });
  };

  handleSelectChange = (selectedOption) => {
    const {
      summarySectionFilter,
      revenueChannels,
      expenditureChannels,
    } = this.props.entity;

    let selectedData = [];
    if (selectedOption !== null) {
      selectedData = summarySectionFilter.defaultSortOptions.filter(
        (item) => item.name === selectedOption.name
      );
      const index = summarySectionFilter.defaultSortOptions.findIndex(
        (ele) => ele.name === selectedOption.name
      );
      if (this.selectedTab === summaryByTypes.revenueChannels) {
        revenueChannels.onChannelSortChanged(index, selectedData[0]);
      } else if (this.selectedTab === summaryByTypes.expenditureChannels) {
        expenditureChannels.onChannelSortChanged(index, selectedData[0]);
      }
    }
  };

  render() {
    const { entity, t } = this.props;
    const { revenueChannels, expenditureChannels } = entity;
    const {
      selectedTabSummaryBy,
      summarySectionFilter,
      summaryByTabs,
      summarySectionDateFilterIndex,
    } = this.props.entity;

    return (
      <TabBarDiv>
        <HeaderContainer>
          <TabBarContainer>
            <SummaryText>
              {t("revenueManagement:revenueModule.summaryBy")} {` `}
              {summaryByTypes.revenueChannels}
            </SummaryText>
            {/* {this.renderTabs()} */}
          </TabBarContainer>
          <ReactSelectAndDatePicker>
            <ReactSelectButton>
              <ReactSelect
                onChange={this.handleSelectChange}
                formatOptionsForReactSelect={this.formatOptionsForReactSelect}
                placeholder={t("revenueManagement:revenueModule.sortBy")}
                options={summarySectionFilter.defaultSortOptions}
              />
            </ReactSelectButton>
            <DatePickerWithPresetButtons
              onDatesChange={this.getDateFilterChangeFunction()}
              initialStartDate={this.getInitialStartDate()}
              initialEndDate={this.getInitialEndDate()}
            />
          </ReactSelectAndDatePicker>
        </HeaderContainer>
        <TabBarValueContainer>
          {this.selectedTab === summaryByTypes.revenueChannels ? (
            <ChannelCards
              channelsInstance={revenueChannels}
              onRetry={this.onRetry}
            />
          ) : (
            <ChannelCards
              channelsInstance={expenditureChannels}
              onRetry={this.onRetry}
            />
          )}
        </TabBarValueContainer>
      </TabBarDiv>
    );
  }
}

export default withTranslation()(RevenueExpenditureChannelsTabBar);

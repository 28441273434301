/**
 * @flow
 * @author: Prasanth
 */

import { action, observable, computed } from "mobx";
// import I18n from "react-native-i18n";
import { API_INITIAL } from "@ib/api-constants";
import type { APIStatus } from "@ib/api-constants";
import { bindPromiseWithOnSuccess } from "../../../Common/utils/MobxPromise";
import type { Pagination } from "../../../Common/stores/types";
import PaginationStore from "../../../Common/stores/PaginationStore";
import CommonMethods from "../../../Common/utils/CommonMethods";
import { todayDate, sevenDaysBackDate } from "../../../Common/utils/DateUtils";
import { dateFilterIndex } from "../../../Common/constants/DateFilterConstants";
import FilterItem from "../models/common/FilterItem";
import SortByItem from "../models/common/SortByItem";
import DateFilter from "../models/common/DateFilter";
import {
  SORT_TYPE_DESC,
  transactionLogFilterTypes,
  transactionLogSortTypes,
} from "../../constants/BackendConstants";
import BasicFilters from "../models/common/BasicFilters";
import ExpenditureLogItem from "../models/common/ExpenditureLogItem/";
import { Services } from "../../services/";
import {
  type APIExpenditureLogResponse,
  type APIExpenditureLogsRequest,
} from "../type";
import i18n from '../../../Common/i18n'


class ExpenditureLogsStore {
  @observable paginationStore: PaginationStore<ExpenditureLogItem>;
  @observable basicFilters: BasicFilters;
  @observable defaultSortOption: SortByItem;
  @observable channelFilterIndex: number;

  @observable emailId: string;
  @observable sendExpenditureLogsToMailStatus: APIStatus;
  services: Services;
  constructor(services: Services) {
    this.initStore(services);
  }

  @action.bound
  initStore(services: Services) {
    this.services = services;
    this.basicFilters = new BasicFilters();
    this.defaultSortOption = new SortByItem({
      key: transactionLogSortTypes.date,
      order: SORT_TYPE_DESC,
      name: "",
    });
    this.basicFilters.setSortBy(this.defaultSortOption);
    this.setDefaultDateFilter();
    this.setupPaginationStore();
    this.channelFilterIndex = 0;

    this.emailId = "";
    this.sendExpenditureLogsToMailStatus = API_INITIAL;
  }

  @action.bound
  setEmailId(value: string) {
    this.emailId = value;
  }

  @action.bound
  setDefaultDateFilter = () => {
    this.basicFilters.setDateFilter(
      new DateFilter({
        from_date: sevenDaysBackDate(),
        to_date: todayDate(),
      })
    );
    this.basicFilters.currentDateFilter.setDateFilterIndex(
      dateFilterIndex.lastWeek
    );
  };

  @action.bound
  clearExpenditureLogsStore() {
    this.initStore(this.services);
  }

  @action.bound
  setupPaginationStore() {
    this.paginationStore = new PaginationStore(ExpenditureLogItem);
    this.paginationStore.getEntitiesListAPI = this.services.revenueStoreService.getExpenditureLogs;
    this.paginationStore.getStringEntityIndex = (expenditure) =>
      expenditure.transaction_id;
    this.paginationStore.getEntitiesFromResponse = (response) =>
      response.expenditure_logs;
    this.paginationStore.getEntitiesCountFromResponse = (response) =>
      response.total;
    this.paginationStore.changeShowPerPage(10, false);
    this.paginationStore.getEntitiesCountFromResponse = this.getEntitiesCountFromResponse;
    this.paginationStore.requestTransformer = (
      pagination: Pagination,
      filters
    ): APIExpenditureLogsRequest => {
      const appliedFilters = [];
      filters.forEach((eachFilters) => {
        eachFilters.forEach((eachFilter) => {
          appliedFilters.push(eachFilter.requestObject);
        });
      });
      const request = {
        sort_by: [this.basicFilters.currentSortBy.requestObject],
        filters: appliedFilters,
        offset_limit: {
          offset: pagination.offset,
          limit: pagination.limit,
        },
      };
      if (!this.basicFilters.currentSortBy.isReset) {
        request.sort_by = [this.basicFilters.currentSortBy.requestObject];
      }
      if (!this.basicFilters.currentDateFilter.isReset) {
        request.date_range = this.basicFilters.currentDateFilter.requestData;
      }
      if (
        this.basicFilters.currentFilter &&
        !this.basicFilters.currentFilter.isReset
      ) {
        request.filters = [this.basicFilters.currentFilter.requestObject];
      }
      return request;
    };
  }

  @computed
  get sortOptions(): Array<SortByItem> {
    return [
      this.defaultSortOption,
      new SortByItem({
        key: transactionLogSortTypes.franchise,
        order: SORT_TYPE_DESC,
        name: "",
      }),
      new SortByItem({
        key: transactionLogSortTypes.channel,
        order: SORT_TYPE_DESC,
        name: "",
      }),
      new SortByItem({
        key: transactionLogSortTypes.date,
        order: SORT_TYPE_DESC,
        name: "",
      }),
      new SortByItem({
        key: transactionLogSortTypes.expenditureAmount,
        order: SORT_TYPE_DESC,
        name: "",
      }),
    ];
  }

  @computed
  get displayChannelFilters() {
    const channelFilters = [];
    this.basicFilters.filters.forEach((eachFilter) => {
      if (eachFilter.filterType === transactionLogFilterTypes.channel) {
        channelFilters.push(eachFilter);
      }
    });
    return observable([
      new FilterItem({
        name: i18n.t("franchiseCommon.allChannels"),
        filter_id: "-1",
        filter_type: transactionLogFilterTypes.channel,
      }),
      ...channelFilters,
    ]);
  }

  @action.bound
  onPagePress(page: number) {
    this.paginationStore.changeCurrentPage(page);
  }

  @action.bound
  onSortChanged(key: string) {
    if (key) {
      if (this.basicFilters.currentSortBy.sortId === key) {
        this.basicFilters.currentSortBy.toggleOrder();
      } else {
        const findSortItem = this.sortOptions.find(
          (eachSort) => eachSort.sortId === key
        );
        if (findSortItem) {
          this.basicFilters.setSortBy(findSortItem);
        }
      }
      this.paginationStore.reloadPagesData();
    }
  }

  @action.bound
  onDateFilterChanged(fromDate: string, toDate: string, index: number) {
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    this.paginationStore.reloadPagesData();
  }

  @computed
  get defaultChannelFilterIndex(): number {
    if (this.basicFilters.filters.length > 0) {
      return this.channelFilterIndex;
    }
    return 0;
  }

  @action.bound
  setDefaultChannelFilter(index: number, value: FilterItem) {
    this.channelFilterIndex = index;
    this.paginationStore.changeCurrentFilter(value.filterType, [value], false);
  }

  @action.bound
  setDefaultFranchiseFilter(franchiseId: number, name: string = "") {
    const formattedFilterObj = new FilterItem({
      name,
      filter_id: franchiseId,
      filter_type: transactionLogFilterTypes.franchise,
    });
    this.paginationStore.changeCurrentFilter(
      transactionLogFilterTypes.franchise,
      [formattedFilterObj],
      false
    );
  }

  @action.bound
  onFilterChanged(index: number, value: FilterItem) {
    if (value.filterType === transactionLogFilterTypes.channel) {
      this.channelFilterIndex = index;
    }
    this.paginationStore.changeCurrentFilter(value.filterType, [value], true);
  }

  @action.bound
  onFilterReset(index: number, value: FilterItem) {
    if (value.filterType === transactionLogFilterTypes.channel) {
      this.channelFilterIndex = index;
    }
    this.paginationStore.changeCurrentFilter(value.filterType, [], true);
  }

  @action.bound
  onReload() {
    this.paginationStore.reloadPagesData();
  }

  @action.bound
  getEntitiesCountFromResponse(response: APIExpenditureLogResponse) {
    if (this.basicFilters.filters.length === 0) {
      this.basicFilters.setFilters(response.filters);
    }
    return response.total;
  }

  @action.bound
  setSendExpenditureLogsToMailStatus(status: APIStatus) {
    this.sendExpenditureLogsToMailStatus = status;
  }

  @action.bound
  sendExpenditureLogsToMailAPI(
    onSuccess: Function = () => {},
    shouldUpdateImmediately: boolean = true
  ) {
    const requestObject = this.paginationStore.requestTransformer(
      {},
      this.paginationStore.currentFilters
    );
    requestObject.offset_limit = {
      offset: 0,
      limit: -1,
    };
    requestObject.email_id = this.emailId;
    const sendExpenditureLogsToMailPromise = this.services.revenueStoreService.sendExpenditureLogsToMail(
      requestObject
    );
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(sendExpenditureLogsToMailPromise)
        .to(this.setSendExpenditureLogsToMailStatus, () => {
          onSuccess();
        })
        .catch((e) => {
          CommonMethods.displayApiError(e);
        });
    }
    return sendExpenditureLogsToMailPromise;
  }

  @action.bound
  onExpenditureLogsOpened(franchiseId, name) {
    this.setDefaultFranchiseFilter(franchiseId, name);
    this.paginationStore.reloadPagesData();
  }
}

export default ExpenditureLogsStore;

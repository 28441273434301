export const GENDER_TYPE_MALE = 'M'
export const GENDER_TYPE_FEMALE = 'F'
export const GENDER_TYPE_NA = 'NA'

export const LANGUAGE_TYPE_ENGLISH = 'ENGLISH'
export const LANGUAGE_TYPE_HINDI = 'HINDI'
export const LANGUAGE_TYPE_TELUGU = 'TELUGU'
export const LANGUAGE_TYPE_TAMIL = 'TAMIL'
export const LANGUAGE_TYPE_KANNADA = 'KANNADA'

export const USER_TYPE_OPERATOR = 'OPERATOR'
export const USER_TYPE_OWNER = 'OWNER'
export const USER_TYPE_MANAGER = 'MANAGER'

export default {
  GENDER_TYPE_MALE,
  GENDER_TYPE_FEMALE,
  GENDER_TYPE_NA,
  LANGUAGE_TYPE_ENGLISH,
  LANGUAGE_TYPE_HINDI,
  LANGUAGE_TYPE_TELUGU,
  LANGUAGE_TYPE_TAMIL,
  LANGUAGE_TYPE_KANNADA,
  USER_TYPE_OPERATOR,
  USER_TYPE_OWNER,
  USER_TYPE_MANAGER
}

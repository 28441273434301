/*
* @flow
*/

import BaseChannels from '../BaseChannels'

class ExpenditureChannels extends BaseChannels {
  constructor(franchiseId, services) {
    super(franchiseId)
    this.getChannelsListAPI =
      services.revenueStoreService.getExpenditureChannelsList
  }
}
export default ExpenditureChannels

/**
 * @flow
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DatePickerExample from "../DatePickerExample";

type Props = {};

const today = moment();
const yesterday = moment().subtract(1, "day");
const presets = [
  {
    text: "Yesterday",
    start: yesterday,
    end: today,
  },
  {
    text: "Last Week",
    start: moment().subtract(1, "week"),
    end: today,
  },
  {
    text: "Last Month",
    start: moment().subtract(1, "month"),
    end: today,
  },
  {
    text: "Last 90 days",
    start: moment().subtract(3, "month"),
    end: today,
  },
];

@observer
class DatePickerWithPresetButtons extends Component<
  Props & { revenueStore: RevenueStore }
> {
  render() {
    return (
      <div style={{ display: "flex" }}>
        <DatePickerExample
          presets={presets}
          isOutsideRange={() => false}
          autoFocus
          {...this.props}
        />
      </div>
    );
  }
}

export default withTranslation()(DatePickerWithPresetButtons);

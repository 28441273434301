/*
 * @flow
 * Author: Prasanth
 */

import networkCallWithApisauce from '../../../Common/utils/AppAPI'
import urls from '../../utils/urls'
import type {
  APIPhoneNumber,
  APIVerifyLoginOtpRequest,
  APIAccessTokenRequest
} from './type'

class UserAPI {
  async userLoginApi(requestObject: APIPhoneNumber) {
    const url = urls.auth.userLogin
    return networkCallWithApisauce(url, requestObject)
  }
  async userLoginOtpVerifyApi(requestObject: APIVerifyLoginOtpRequest) {
    const url = urls.auth.verifyLoginOtp
    return networkCallWithApisauce(url, requestObject)
  }
  async userLoginOtpResendApi(requestObject: APIPhoneNumber) {
    const url = urls.auth.resendLoginOtp
    return networkCallWithApisauce(url, requestObject)
  }

  async getAccessTokenApi(requestObject: APIAccessTokenRequest) {
    const url = urls.auth.getAccessToken
    return networkCallWithApisauce(url, requestObject)
  }
  async userLogOutApi() {
    const url = urls.auth.userLogout
    return networkCallWithApisauce(url, {}, () => {}, () => {}, 'GET')
  }
}

const userAPI = new UserAPI()
export default userAPI

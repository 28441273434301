/*
* @flow
*/

import BaseChannels from '../BaseChannels'

class RevenueChannels extends BaseChannels {
  constructor(franchiseId, services) {
    super(franchiseId)
    this.getChannelsListAPI =
      services.revenueStoreService.getRevenueChannelsList
  }
}
export default RevenueChannels

/*
 * @author: Tanmay B
 * @flow
 */

import { observable, action, computed } from 'mobx'
import CommonMethods from '../../../../../Common/utils/CommonMethods'
import type { APIRevenueExpenditureObject } from './type'

class RevenueExpenditureObject {
  @observable expenditure: number
  @observable revenue: number
  @observable settlement: number
  @observable approxSettlementAmount: boolean

  constructor() {
    this.expenditure = 0
    this.revenue = 0
    this.settlement = 0
    this.approxSettlementAmount = false
  }
  @computed
  get totalRevenueAmount(): any {
    return CommonMethods.toCurrency(this.revenue, {
      minimizePrecision: 2,
      isCurrency: true
    })
  }
  @computed
  get totalExpenditureAmount(): any {
    return CommonMethods.toCurrency(this.expenditure, {
      minimizePrecision: 2,
      isCurrency: true
    })
  }

  @computed
  get totalSettlementAmount(): any {
    return CommonMethods.toCurrency(this.settlement, {
      minimizePrecision: 2,
      isCurrency: true
    })
  }
  @action.bound
  setBasicDetails(
    revenueExpenditureObject: APIRevenueExpenditureObject,
    settlement?: number = 0
  ) {
    if (revenueExpenditureObject) {
      this.expenditure = revenueExpenditureObject.expenditure || 0
      this.revenue = revenueExpenditureObject.revenue || 0
      this.settlement = settlement || 0
    }
  }
  @action.bound
  setApproxSettlementAmount(approxSettlementAmount: boolean) {
    this.approxSettlementAmount = approxSettlementAmount
  }
}
export default RevenueExpenditureObject

/*
* @flow
* @author : Dinesh
*/

import { observable } from 'mobx'

class PieDataSet {
  @observable value: number
  @observable label: string
  @observable marker: string

  constructor(value: number, label?: string = '', marker?: string = '') {
    this.value = value
    this.label = label
    this.marker = marker
  }
}

export default PieDataSet

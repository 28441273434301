import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
    <svg width={width} height={height} viewBox="0 0 9 6" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-6-8h20v20H-6z" />
        <path
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M1 1l3.5 4L8 1"
        />
      </g>
    </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 9,
   height: 6,
   fill: Colors.white
}
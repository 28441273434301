import styled from "@emotion/styled";
import { LoaderViewDiv } from "../PaymentSummaryTable/styledComponents";

export const PaymentSummaryTableDiv = styled.div`
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  border-radius: 3px;
  font-family: "CerebriSans";
`;

export const PaymentSummaryHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 752px 18px 20px;
  box-shadow: inset 0 -1px 0 0 #dfe3e8;
  font-size: 18px;
`;

export const PaymentSummaryTableDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RenderHeaderDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const SortBySpan = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;
export const NoTransactions = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44), 0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  background-color: #ffffff;
  font-size: 18px;
`;

export const Container = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    border: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border: 0;
    }

    th {
      font-family: "CerebriSans";
      font-weight: 400;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      background-color:#ebedf0;

      :last-child {
        border-right: 0;
      }
      border: 0;
      text-align: center;
      box-shadow: inset 0 -1px 0 0 #dfe3e8;
    }

    td {
      font-family: "CerebriSans";
      font-weight: 400;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
      border: 0;
      text-align: center;
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

export const RenderBodyDiv = styled.div`
  width:100%;
  height:100%;
`

export const LoaderViewWrapper = styled(LoaderViewDiv)``
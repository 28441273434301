/**
 * @flow
 * @author: @ib/Chinmaya
 */

import { observer } from "mobx-react";
import React, { Component } from "react";
import { CardsOnBoardContainer, CardContainer } from "./styledComponents";

// type DashboardSummaryItemCardContainerProps = {
//   onPress: () => void,
//   children: ReactNode
// }

@observer
class DashboardSummaryItemCardContainer extends Component {
  render() {
    const { children, className, navigate } = this.props;
    return (
      <CardsOnBoardContainer className={className} onClick={navigate}>
        {children}
      </CardsOnBoardContainer>
    );
  }
}

export default DashboardSummaryItemCardContainer;

import * as React from "react";

function SvgComponent(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 35 36" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          fill="#4FBA6F"
          d="M2.182 17.126l28.86-2.78c1.325-.127 2.502.855 2.628 2.194l1.315 13.944c.127 1.339-.845 2.528-2.17 2.656l-28.86 2.779c-1.326.128-2.502-.855-2.629-2.194L.011 19.782c-.126-1.34.846-2.529 2.171-2.656z"
        />
        <g fill="#FFF">
          <path d="M6.602 30.754a3.6 3.6 0 01-2.558-1.123 5.205 5.205 0 01-.599-6.348 3.57 3.57 0 012.62-1.638 3.56 3.56 0 012.881 1.109 5.203 5.203 0 01.599 6.347 3.572 3.572 0 01-2.622 1.638c-.106.01-.214.015-.32.015zm-.22-7.905c-.067 0-.134.003-.202.01a2.386 2.386 0 00-1.738 1.11 3.979 3.979 0 00.452 4.799 2.379 2.379 0 001.917.759 2.392 2.392 0 001.739-1.11c.473-.733.679-1.61.582-2.478a3.782 3.782 0 00-1.035-2.322 2.425 2.425 0 00-1.714-.768zm6.31 7.332a.606.606 0 01-.603-.548l-.74-7.885a.613.613 0 01.243-.57.6.6 0 01.956.455l.744 7.881a.614.614 0 01-.135.446.602.602 0 01-.407.219l-.058.002z" />
          <path d="M12.536 25.943c-.128 0-.22 0-.263-.004a.605.605 0 01-.58-.63.6.6 0 01.624-.587c.723.028 2.772-.08 3.503-.865a.958.958 0 00-.133-1.391c-.864-.63-2.94-.333-3.6-.183a.601.601 0 01-.722-.457.609.609 0 01.452-.73c.314-.073 3.115-.685 4.575.38.49.365.804.925.861 1.54a2.18 2.18 0 01-.56 1.675c-1.067 1.144-3.35 1.252-4.157 1.252zm7.276 3.552a.606.606 0 01-.6-.55l-.742-7.883a.608.608 0 01.543-.664l5.177-.498a.605.605 0 01.653.55.608.608 0 01-.54.663l-4.58.44.63 6.67 4.58-.441a.6.6 0 01.551.251c.126.178.148.41.057.61a.603.603 0 01-.495.351l-5.178.499c-.018.002-.038.002-.056.002z" />
          <path d="M19.412 25.252a.605.605 0 01-.631-.58.607.607 0 01.574-.638l3.884-.374c.33-.03.621.216.653.55a.608.608 0 01-.54.663l-3.882.374a.297.297 0 01-.058.005zm8.168 3.496c-.31 0-.57-.24-.6-.552l-.743-7.881a.608.608 0 01.389-.628.598.598 0 01.701.216l3.973 5.605-.53-5.629a.613.613 0 01.243-.57.598.598 0 01.61-.058c.2.094.332.29.346.512l.743 7.881a.61.61 0 01-.389.627.596.596 0 01-.7-.215l-3.973-5.607.531 5.632a.606.606 0 01-.542.663c-.02 0-.037.003-.058.003z" />
        </g>
        <path
          fill="#000"
          d="M17.905 1.273a.597.597 0 01-.225-.044 1.204 1.204 0 00-.19-.01.603.603 0 01-.682-.487.608.608 0 01.454-.708.815.815 0 01.215-.023c.162-.004.324.012.482.046.058.013.115.03.17.052a.611.611 0 01.368.68.605.605 0 01-.592.494z"
        />
        <path
          fill="#CB9080"
          d="M31.337 17.052a.6.6 0 01-.46-.216L18.75 2.343a.614.614 0 01-.141-.394.607.607 0 01.433-.587.6.6 0 01.679.258l12.076 14.433c.15.181.181.433.083.647a.602.602 0 01-.544.352zM3.014 20.097a.602.602 0 01-.54-.337.613.613 0 01.057-.637l12.66-17.174a.6.6 0 01.844-.122c.267.202.32.584.12.853L3.496 19.853a.597.597 0 01-.481.244z"
        />
        <path
          fill="#A5A5A4"
          d="M17.477 4.872c-1.332 0-2.41-1.09-2.41-2.436 0-1.345 1.078-2.435 2.41-2.435 1.331 0 2.41 1.09 2.41 2.435a2.45 2.45 0 01-.706 1.723 2.396 2.396 0 01-1.704.713z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;

SvgComponent.defaultProps = {
  width: 35,
  height: 36,
};

import React from "react";
import styled from "@emotion/styled";
import { useTable, useSortBy } from "react-table";
import moment from "moment";
import { API_FETCHING } from "@ib/api-constants";

import PaginationNavigation from "../../../Common/components/PaginationNavigation";
import Loader from "../../../Common/Icons/Loader";
import SortByIcon from "../../../Common/Icons/SortByIcon";

import NoDataView from "../NoDataView/NoDataView";

import {
  Container,
  PaymentSummaryTableDiv,
  PaymentSummaryHeader,
  PaymentSummaryTableDetails,
  RenderHeaderDiv,
  SortBySpan,
  NoTransactions,
  LoaderViewWrapper,
} from "./styledComponents";

const getTransformedExpenditureData = (data) => {
  return data.map((item) => {
    const updatedValue = {
      ...item,
      franchiseId: item.franchise.franchise_id,
      franchiseName: item.franchise.name,
      channelId: item.channel.channelId,
      channelName: item.channel.name,
      userId: item.addedBy.user_id,
      userName: item.addedBy.name,
    };
    return updatedValue;
  });
};

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );
  const isEmptyResults = data.length === 0;

  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <RenderHeaderDiv>
                  {column.render("Header")}
                  <SortBySpan>
                    {column.disableSortBy ? "" : <SortByIcon />}
                  </SortBySpan>
                </RenderHeaderDiv>
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const renderBody = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <PaymentSummaryTableDiv>
      <PaymentSummaryHeader>Expenditure Logs</PaymentSummaryHeader>
      <PaymentSummaryTableDetails>
        <table {...getTableProps()}>
          {renderHeader()}
          {renderBody()}
        </table>
      </PaymentSummaryTableDetails>
    </PaymentSummaryTableDiv>
  );
}

function ExpendituresLogTable(props) {
  const {
    loadingStatus,
    expenditures,
    totalPagesCount,
    currentPage,
    onPagePress,
  } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "transactionId",
      },
      {
        Header: "Franchise",
        accessor: "franchiseName",
      },
      {
        Header: "Channel",
        accessor: "channelName",
      },
      {
        Header: "Transaction Amount",
        accessor: "amount",
      },
      {
        Header: "Date",
        accessor: "expenditureDateTime",
        Cell: (row) => {
          const date = moment(new Date(row.value)).format("Do MMM YYYY");
          return <span>{`${date}`}</span>;
        },
      },
    ],
    []
  );

  return (
    <Container>
      <Table
        columns={columns}
        data={getTransformedExpenditureData(expenditures)}
      />
      {loadingStatus === API_FETCHING && expenditures.length === 0 ? (
        <LoaderViewWrapper>
          <Loader width={40} height={40} />
        </LoaderViewWrapper>
      ) : expenditures.length === 0 ? (
        <NoTransactions>
          <NoDataView
            text={"No Transactions"}
            spanText={"Remove the filters and try again"}
          />
        </NoTransactions>
      ) : null}
      <PaginationNavigation
        totalPagesCount={totalPagesCount}
        currentPage={currentPage}
        maxDisplayPagesCount={9}
        onPagePress={onPagePress}
      />
    </Container>
  );
}

export default ExpendituresLogTable;

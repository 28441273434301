/**
 * @flow
 * @author: @ib/Chinmaya
 */
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import DashboardSummaryItemCardContainer from "../DashboardSummaryItemCardContainer";

export const Container = styled(DashboardSummaryItemCardContainer)`
  font-family:'CerebriSans';
`;

export const Card = styled.div`
  width: 48%;
  height: 350px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 30px;
`;

export const CardDiv = styled.div`
  font-family: 'CerebriSans';
  display: flex;
  align-items: center;
`;

export const Heading = styled.p`
  font-family: 'CerebriSans';
  font-size: 23px;
  margin-left:5px;
`;
export const IncomeDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
`;

export const ViewDetailsDiv = styled.div`
  font-family: 'CerebriSans';
  font-style: italic;
  font-size: 14px;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  color:#0d47a1;
`

export const ViewDetails = styled.div`
  display:flex;
  align-items:center;
  cursor:pointer;
  &:hover {
    border-bottom:1px solid #0d47a1;
  }
`

export const ViewDetailsText = styled.p`
  margin-right:5px;
`
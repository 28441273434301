/*
 * @flow
 * @Author: Chinmaya
 */

import React, { Component } from "react";
import { observer } from "mobx-react";

import { Container, ViewContainer, Link, Body } from "./styledComponents";

type Props = {
  isActive: boolean,
  isEnabled: boolean,
  children: React$Node,
  onClick: () => mixed,
  containerStyle?: any,
};

@observer
export class NavigationButton extends Component<Props> {
  render() {
    const {
      isActive,
      isEnabled,
      onClick,
      containerStyle,
      ...other
    } = this.props;

    return (
      <Container
        {...this.props}
        {...other}
        underlayColor="#f5f6f7"
        onClick={isActive ? null : isEnabled ? onClick : null}
      >
        <ViewContainer>{this.props.children}</ViewContainer>
      </Container>
    );
  }
}

type WithTextProps = {
  children: React$Node,
  ...Props,
};

type ButtonTextProps = {
  isActive: boolean,
  children: React$Node,
};

export const ButtonText = observer(
  ({ isActive, children }: ButtonTextProps) => {
    if (isActive === true) {
      return <Link>{children}</Link>; // eslint-disable-line
    }
    return <Body>{children}</Body>;
  }
);

export const NavigationButtonWithText = observer((props: WithTextProps) => {
  const { children, ...other } = props;
  return (
    <NavigationButton {...other}>
      <ButtonText {...other}>{children}</ButtonText>
    </NavigationButton>
  );
});

export default NavigationButton;

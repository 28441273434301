/*
 * @author: Dinesh
 * @flow
 */

import networkCallWithApisauce from '../../../Common/utils/AppAPI'
import Urls from '../../utils/Urls'

import {
  type APITransactionLogsParameter,
  type APIGetPaymentSummaryRequest,
  type APIGetPaymentSummaryResponse,
  type APITransactionLogsResponse,
  type APIGetChannelsByFranchiseIdParameter,
  type APIGetChannelsByFranchiseIdResponse,
  type APIAddExpenditureParameter,
  type APIAddExpenditureResponse,
  type APIExpenditureLogsRequest,
  type APIExpenditureLogsMailRequest,
  type APIExpenditureLogResponse,
  type APIGetRevenueSummaryRequest
} from '../../stores/type'

class RevenueStoreService {
  async getRevenueSummary(
    requestObject: APIGetRevenueSummaryRequest
  ): Promise<*> {
    const url = Urls.revenue.getRevenueSummary
    return networkCallWithApisauce(url, requestObject)
  }

  // ENTITY related apis
  async getEntityRevenueDetails(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getEntityRevenueDetails
    return networkCallWithApisauce(url, requestObject)
  }
  // Graphs  related apis
  async getRevenueGraphSummary(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getRevenueGraphSummary
    return networkCallWithApisauce(url, requestObject)
  }

  // Graphs  related apis
  async getRevenueDonutGraphSummary(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getRevenueDonutGraphSummary
    return networkCallWithApisauce(url, requestObject)
  }

  // Graphs  related apis
  async getExpenditureGraphSummary(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getExpenditureGraphSummary
    return networkCallWithApisauce(url, requestObject)
  }

  async getRevenueChannelsList(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getRevenueChannelsList
    return networkCallWithApisauce(url, requestObject)
  }

  async getExpenditureChannelsList(requestObject: Object = {}): Promise<*> {
    const url = Urls.revenue.getExpenditureChannelsList
    return networkCallWithApisauce(url, requestObject)
  }

  async getTransactionLogs(
    requestObject: APITransactionLogsParameter
  ): Promise<APITransactionLogsResponse> {
    const url = Urls.revenue.getTransactionLogs
    return networkCallWithApisauce(url, requestObject)
  }

  async getExpenditureLogs(
    requestObject: APIExpenditureLogsRequest
  ): Promise<APIExpenditureLogResponse> {
    const url = Urls.revenue.getExpenditureLogs
    return networkCallWithApisauce(url, requestObject)
  }

  async sendExpenditureLogsToMail(
    requestObject: APIExpenditureLogsMailRequest
  ): Promise<*> {
    const url = Urls.revenue.sendExpenditureLogsToMail
    return networkCallWithApisauce(url, requestObject)
  }

  async getChannelsByFranchise(
    request: APIGetChannelsByFranchiseIdParameter
  ): Promise<APIGetChannelsByFranchiseIdResponse> {
    const url = Urls.revenue.getExpenditureChannelsByFranchiseId
    return networkCallWithApisauce(url, request)
  }

  async addExpenditure(
    request: APIAddExpenditureParameter
  ): Promise<APIAddExpenditureResponse> {
    const url = Urls.revenue.addExpenditure
    return networkCallWithApisauce(url, request)
  }

  async getPaymentSummary(
    requestObject: APIGetPaymentSummaryRequest
  ): Promise<APIGetPaymentSummaryResponse> {
    const url = Urls.revenue.getPaymentSummary
    return networkCallWithApisauce(url, requestObject)
  }
}

const revenueStoreService = new RevenueStoreService()
export { revenueStoreService as default, RevenueStoreService }

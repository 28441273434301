/*
 * @flow
 * @author: Chinmaya
 */

import networkCallWithApisauce from '../../../Common/utils/AppAPI'
import urls from '../../utils/Urls.js'

import type {
  APIGetDashboard,
  APIGetSurveillanceRequest,
  APIGetSurveillanceResponse
} from '../../stores/type'

class DashboardService {
  getDashboardDetails(): Promise<APIGetDashboard> {
    const url = urls.getDashboardDetails
    return networkCallWithApisauce(url, {})
  }

  getSurveillanceVideos(
    request: APIGetSurveillanceRequest
  ): Promise<APIGetSurveillanceResponse> {
    const url = urls.getSurveillanceVideos
    return networkCallWithApisauce(url, request)
  }
}

export default DashboardService

import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  width: 32.5%;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 20px 30px 0px;
  padding: 19px 20px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  background-color: #ffffff;
`;

export const TextWithAmountDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: CerebriSans;
  padding-left: 14px;
`;

export const Text = styled.div`
  font-family: CerebriSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #13274b;
`;

export const Amount = styled.div`
  font-family: CerebriSans;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #13274b;
`;

/*
 * @flow
 */
import { observable, action } from 'mobx'

import GroupBarGraphData from '../../../../Common/stores/GraphsData/GroupBarGraph/GroupBarGraphData'
import BarDataSet from '../../../../Common/stores/GraphsData/GroupBarGraph/GroupBarDataSet'
import GraphUtils from '../../../../Common/utils/GraphUtils'
import Colors from '../../../../Common/themes/Colors'

import type { APICustomersSection, APINewOldCustomers } from '../../type.js'

class CustomersSection {
  @observable rating: number
  @observable customerCount: number
  @observable graphData: GroupBarGraphData

  constructor(customersSection: APICustomersSection) {
    this.rating = customersSection.rating
    this.customerCount = customersSection.customer_count
    this.setGraphData(customersSection.graph_data)
  }

  @action.bound
  setRating(rating: number) {
    this.rating = rating
  }

  @action.bound
  setCustomerCount(customerCount: number) {
    this.customerCount = customerCount
  }

  @action.bound
  setGraphData(graphData: APINewOldCustomers) {
    this.graphData = new GroupBarGraphData()
    this.graphData.setData(
      observable([
        new BarDataSet(
          graphData.new_customers.data,
          graphData.new_customers.legend,
          Colors.bumi
        ),
        new BarDataSet(
          graphData.old_customers.data,
          graphData.old_customers.legend,
          Colors.mango
        )
      ])
    )
    this.graphData.setXValues(
      GraphUtils.formatDateTimeXvaluesToMMMYY(graphData.labels)
    )
  }
}
export default CustomersSection

import React from 'react'

const NextArrow = (props) => (
   <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 7 12">
      <g fill="none" fill-rule="evenodd">
         <g>
               <g>
                  <g>
                     <path d="M0 0H24V24H0z" transform="translate(-978 -102) translate(517 80) translate(453 16)"/>
                     <g stroke={props.color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                           <path d="M0 10L5 5 0 0" transform="translate(-978 -102) translate(517 80) translate(453 16) translate(9 7)"/>
                     </g>
                  </g>
               </g>
         </g>
      </g>  
   </svg>
)

NextArrow.defaultProps = {
   width: 7,
   height: 12,
   color: '#4F5E79'
}

export default NextArrow

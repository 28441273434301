/*
 * @flow
 */
import { observable, action } from 'mobx'

import LineGraphData from '../../../../Common/stores/GraphsData/LineGraph/LineGraphData'
import LineGraphDataSet from '../../../../Common/stores/GraphsData/LineGraph/LineGraphDataSet'
import GraphUtils from '../../../../Common/utils/GraphUtils'
import Colors from '../../../../Common/themes/Colors'

import type { APIRevenuesSection, APIIncomeExpenditure } from '../../type.js'

class RevenuesSection {
  @observable graphData: LineGraphData
  @observable settlementAmount: number
  @observable approxSettlementAmount: boolean
  @observable expenditure: number
  @observable income: number

  constructor(revenuesSection: APIRevenuesSection) {
    this.setGraphData(revenuesSection.graph_data)
    this.settlementAmount = revenuesSection.settlement_amount
    this.approxSettlementAmount = revenuesSection.approx_settlement_amount
    this.expenditure = revenuesSection.current_day_expenditure
    this.income = revenuesSection.current_day_income
  }

  @action.bound
  setGraphData(graphData: APIIncomeExpenditure) {
    // TODO: May need to change the fill color from 'transparent'
    this.graphData = new LineGraphData()
    this.graphData.setData(
      observable([
        new LineGraphDataSet(
          graphData.income.data,
          graphData.income.legend,
          Colors.transparent,
          Colors.amon
        )
      ])
    )
    this.graphData.setXValues(
      GraphUtils.formatDateTimeXvaluesToMMMYY(graphData.labels)
    )
  }

  @action.bound
  setSettlementAmount(settlementAmount: number) {
    this.settlementAmount = settlementAmount
  }

  @action.bound
  setExpenditure(expenditure: number) {
    this.expenditure = expenditure
  }

  @action.bound
  setIncome(income: number) {
    this.income = income
  }
}
export default RevenuesSection

/**
 * @flow
 * @author: @ib/Chinmaya
 */

import styled from "@emotion/styled";

export const DashboardSummaryItemContentContainer = styled.div`
  width: 48%;
  height: 350px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 30px;
`;

export const FranchiseDetailsContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ArrowAndNameContainer = styled.div`
  display: flex;
`;

export const DashboardFranchiseStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ArrowContainerButton = styled.button`
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  :focus {
    outline: none;
  }
  :active {
    outline: none;
  }
`;

export const LeftArrowDiv = styled.div`
  margin-right: 10px;
`;

export const RightArrowDiv = styled.div`
  margin-left: 10px;
`;

export const FranchiseStatus = styled.img`
  /* width: 35px; */
  height: 35px;
`;
export const ScoreAndRating = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "CerebriSans";
  font-weight: 400;
`;

export const TodayStatus = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "CerebriSans";
  font-weight: 400;
  margin: 20px 0px;
`;
export const TodaysStatusContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TodaysQuality = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const QualityText = styled.div`
  font-size: 14px;
  margin: 10px 45px 5px 0px;
`;

export const TodayStatusText = styled.div`
  margin: 15px 43px 10px 0px;
  font-size: 17px;
`;
export const ArcadeQualityScoreDiv = styled.div`
  width: 45%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
`;

export const ArcadeTitle = styled.div`
  margin-bottom: 8px;
`;

export const FranchiseCountContainer = styled.span`
  position: absolute;
  bottom: 10px;
  right: 14px;
`;

// /**
//  * @flow
//  * @author: @ib/Chinmaya
//  */

// import styled from 'styled-components/native'
// import Touchable from '@rn/commons/app/components/GenericComponents/Touchable'

// import Image from '../../../Common/components/Image'
// import Colors from '../../../Common/themes/Colors'

// import { CalendarDates } from '../../../Common/styledComponents/Text/StyledTexts'
// import DashboardSummaryItemContentContainer from '../../styledComponents/DashboardSummaryItemContentContainer'

// export const MultipleFranchiseContentContainer = styled(
//   DashboardSummaryItemContentContainer
// )`
//   min-height: 245;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   padding-top: 0;
//   padding-bottom: 5;
// `

// export const FranchiseDetailsContainer = styled.View``

// export const DashboardFranchiseStatus = styled.View`
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   min-height: 41;
// `

// export const MultipleFranchisesHeaderContainer = styled.TouchableWithoutFeedback``

// export const MultipleFranchisesHeader = styled(DashboardFranchiseStatus)`
//   padding-horizontal: 10;
//   padding-vertical: 10;
//   border-top-left-radius: 3;
//   border-top-right-radius: 3;
//   background-color: ${Colors.veryLightBlue};
// `

// export const FranchiseStatus = styled(Image)`
//   width: 35;
//   height: 35;
// `

// export const ActiveArrowContainer = styled(Touchable)`
//   width: 24;
//   height: 24;
//   justify-content: center;
//   align-items: center;
// `

// export const InActiveArrowContainer = styled.View`
//   width: 24;
//   height: 24;
//   justify-content: center;
//   align-items: center;
// `

// export const FranchiseNameWithPreviousArrow = styled.View`
//   flex-direction: row;
//   align-items: center;
// `

// export const FranchiseStatusWithNextArrow = styled.View`
//   flex-direction: row;
//   align-items: center;
// `

// export const DisplayFranchiseCount = styled(CalendarDates)`
//   margin-top: 6;
//   text-align: right;
// `

/*
* @flow
* @author : Dinesh
*/

import { observable, action } from 'mobx'
import type { IObservableArray } from 'mobx'
import LineGraphDataSet from './LineGraphDataSet'

class LineGraphData {
  @observable data: IObservableArray<LineGraphDataSet>
  @observable xValues: IObservableArray<string>

  constructor() {
    this.data = observable([])
    this.xValues = observable([])
  }

  @action.bound
  setData(data: Array<LineGraphDataSet>) {
    this.data = observable(data)
  }

  @action.bound
  setXValues(xValues: Array<string>) {
    this.xValues = observable(xValues)
  }
}

export default LineGraphData

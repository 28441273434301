import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import TransactionsLogTable from "../TransactionsLogTable";
import ExpendituresLogTable from "../ExpendituresLogTable";

// import { TabContainerCSS } from '../../../FolderManagement/components/FolderManagementHomePage/styledComponents'

// import { LabelValueType } from '../../stores/types'

import {
  HeaderContainerDiv,
  TabBarContainer,
  TabTextContainer,
  TabContainerDiv,
  TabBarDiv,
  TabBarValueContainer,
  SummaryText,
  ReactSelectAndDatePicker,
  ReactSelectButton,
  SummaryTextDiv,
} from "./styledComponents";
import { summaryByTrasactionLogs } from "../../constants/TransactionLogsConstants";
import DatePickerWithPresetButtons from "../DatePickerWithPresetButtons";
import ReactSelect from "../ReactSelect";
import FranchiseEntity from "../../stores/models/FranchiseEntity";

const tabsList = [
  {
    name: summaryByTrasactionLogs.revenueLogs,
    value: summaryByTrasactionLogs.revenueLogs,
  },
  {
    name: summaryByTrasactionLogs.expenditureLogs,
    value: summaryByTrasactionLogs.expenditureLogs,
  },
];

type TransactionsAndExpenditureLogsProps = {
  franchiseEntity: FranchiseEntity,
};

@observer
class TransactionsAndExpenditureLogs extends Component<TransactionsAndExpenditureLogsProps> {
  @observable selectedTab = summaryByTrasactionLogs.revenueLogs;

  constructor(props) {
    super(props);
  }

  onClickTab = (value) => {
    this.selectedTab = value;
  };

  renderTabs = () => {
    return tabsList.map((tab) => {
      const onClickTabItem = () => this.onClickTab(tab.value);
      return (
        <TabContainerDiv
          state={this.selectedTab === tab.value}
          key={tab.value}
          onClick={onClickTabItem}
        >
          <TabTextContainer>{tab.name}</TabTextContainer>
        </TabContainerDiv>
      );
    });
  };

  formatOptionsForReactSelect = (data) => {
    return data.map((item) => {
      return {
        label: item.filterName,
        value: item.filterName,
        ...item,
      };
    });
  };

  handleSelectChange = (selectedOption) => {
    const { franchiseEntity } = this.props;

    const { transactionLogsStore, expenditureLogsStore } = franchiseEntity;
    let store;
    if (this.selectedTab === summaryByTrasactionLogs.revenueLogs) {
      store = transactionLogsStore;
    } else {
      store = expenditureLogsStore;
    }
    let selectedData = [];
    if (selectedOption !== null) {
      selectedData = store.displayChannelFilters.filter(
        (item) => item.filterName === selectedOption.value
      );
      const index = store.displayChannelFilters.findIndex(
        (ele) => ele.name === selectedOption.name
      );
      if (selectedData[0].filterId !== "-1") {
        store.onFilterChanged(index, selectedData[0]);
      } else {
        store.onFilterReset(index, selectedData[0]);
      }
    }
  };

  render() {
    const { franchiseEntity, t } = this.props;

    const {
      summaryByTransactionLogsTabs,
      selectedTabTransactionsSummaryBy,
      onChangeSelectedTransactionLogsTab,
      transactionLogsStore,
      expenditureLogsStore,
    } = franchiseEntity;

    let store;

    if (this.selectedTab === summaryByTrasactionLogs.revenueLogs) {
      store = transactionLogsStore;
    } else {
      store = expenditureLogsStore;
    }

    const { paginationStore } = store;
    const {
      currentPageEntities,
      entitiesFetchingStatus,
      totalPages,
      currentPage,
      apiError,
    } = paginationStore;

    const startDate = new moment(store.basicFilters.currentDateFilter.fromDate);
    const endDate = new moment(store.basicFilters.currentDateFilter.toDate);

    return (
      <TabBarDiv>
        <HeaderContainerDiv>
          <TabBarContainer>
            <SummaryTextDiv>
              {t("revenueManagement:revenueModule.transactionLogs")}
              {/* {` `} */}
              {/* {summaryByTrasactionLogs.revenueLogs} */}
            </SummaryTextDiv>
            {/* {this.renderTabs()} */}
          </TabBarContainer>
          <ReactSelectAndDatePicker>
            <ReactSelectButton>
              <ReactSelect
                options={store.displayChannelFilters}
                onChange={this.handleSelectChange}
                formatOptionsForReactSelect={this.formatOptionsForReactSelect}
                placeholder={t("common:franchiseCommon.allChannels")}
              />
            </ReactSelectButton>
            <DatePickerWithPresetButtons
              onDatesChange={store.onDateFilterChanged}
              initialStartDate={startDate}
              initialEndDate={endDate}
            />
          </ReactSelectAndDatePicker>
        </HeaderContainerDiv>
        <TabBarValueContainer>
          {this.selectedTab === summaryByTrasactionLogs.revenueLogs ? (
            <TransactionsLogTable
              transactions={currentPageEntities}
              loadingStatus={entitiesFetchingStatus}
              onSortChange={store.onSortChanged}
              totalPagesCount={totalPages}
              currentPage={currentPage}
              onPagePress={store.onPagePress}
              error={apiError}
              onRetry={store.onReload}
            />
          ) : (
            <ExpendituresLogTable
              expenditures={currentPageEntities}
              loadingStatus={entitiesFetchingStatus}
              onSortChange={store.onSortChanged}
              totalPagesCount={totalPages}
              currentPage={currentPage}
              onPagePress={store.onPagePress}
              error={apiError}
              onRetry={store.onReload}
            />
          )}
        </TabBarValueContainer>
      </TabBarDiv>
    );
  }
}

export default withTranslation()(TransactionsAndExpenditureLogs);

// interface TabBarProps {
//    tabsList: Array<LabelValueType>
//    defaultSelectedTab?: string
//    onClickTab: Function
//    containerCSS?: React.CSSProperties
//    itemCSS?: React.CSSProperties
//    tabContainerCSS?: React.CSSProperties
//    isRootFolderTabBar?: boolean
// }

// interface State {
//    selectedTab: string
// }

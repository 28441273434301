/**
 * @flow
 * @author: Prasanth
 */

import { observable, computed } from 'mobx'
import CommonMethods from '../../../../../Common/utils/CommonMethods'
import AttachmentMultimedia from '../../../../../Common/stores/models/AttachmentMultimedia'
import { TRANSACTION_DATE_FORMAT } from '../../../../constants/AppConstants'
import { getDisplayDate } from '../../../../../Common/utils/DateUtils'
import Channel from '../Channel'
import User from '../User'
import Franchise from '../Franchise'
import { type APIExpenditureLogItem } from '../../../type'

class ExpenditureLogItem {
  @observable channel: Channel
  @observable attachments: IObservableArray<AttachmentMultimedia>
  @observable remarks: string
  @observable addedBy: User
  @observable amount: number
  @observable transactionId: string
  @observable description: string
  @observable franchise: Franchise
  @observable expenditureDateTime: string

  constructor(expenditureLogItem: APIExpenditureLogItem) {
    this.channel = new Channel(expenditureLogItem.channel)
    this.attachments = expenditureLogItem.attachments.map(
      (attachment) => new AttachmentMultimedia(attachment)
    )
    this.remarks = expenditureLogItem.remarks
    this.addedBy = new User(expenditureLogItem.added_by)
    this.amount = expenditureLogItem.amount
    this.transactionId = expenditureLogItem.transaction_id
    this.description = expenditureLogItem.description
    this.franchise = new Franchise(expenditureLogItem.franchise)
    this.expenditureDateTime = expenditureLogItem.expenditure_date_time
  }

  @computed
  get dateTime(): string {
    return getDisplayDate(this.expenditureDateTime, TRANSACTION_DATE_FORMAT)
  }

  @computed
  get expenditureAmount(): any {
    return CommonMethods.toCurrency(this.amount)
  }
}

export default ExpenditureLogItem

// /*
// * @flow
// * @author : Dinesh
// */

import styled from "@emotion/styled";
import { NoTransactions } from "../ExpendituresLogTable/styledComponents";

// import styled from 'styled-components/native'

// import BasePaginationNavigation from '../../../Common/components/PaginationNavigation'
// import CardsOnBoard from '../../../Common/styledComponents/View/CardsOnBoard'

export const PaymentSummaryTableDiv = styled.div`
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  border-radius: 3px;
  font-family: "CerebriSans";
`;

export const PaymentSummaryHeaderContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 18px 20px;
  box-shadow: inset 0 -1px 0 0 #dfe3e8;
`;

export const PaymentSummaryHeading = styled.span`
  font-size: 18px;
  margin-right: 20px;
`;

export const PaymentSummaryTableDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  margin-top: 46px;
  margin-bottom: 50px;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;
    border: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border: 0;
    }

    th {
      font-family: "CerebriSans";
      font-weight: 400;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      background-color:#ebedf0;

      :last-child {
        border-right: 0;
      }
      border: 0;
      text-align: center;
      box-shadow: inset 0 -1px 0 0 #dfe3e8;
    }

    td {
      font-family: "CerebriSans";
      font-weight: 400;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
      border: 0;
      text-align: center;
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

export const LoaderViewDiv = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 250px;
  background-color:white;
`

export const NoTransaction = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44), 0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  background-color: #ffffff;
  font-size: 18px;
`


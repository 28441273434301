/*
 * @flow
 * @author: Chinmaya
 */
import { API_FETCHING, API_SUCCESS, API_FAILED } from "@ib/api-constants";
import type UIStore from "../../Common/stores/UIStore";
import type DashboardStore from "../stores/DashboardStore";

export const onDashboardOpened = async (
  uiStore: UIStore,
  dashboardStore: DashboardStore,
  userProfile
) => {
  const promises = [];
  uiStore.setHomeStatus(API_FETCHING);

  const dashboardPromise = dashboardStore.getDashboardDetails();

  promises.push(dashboardPromise);

  if (dashboardStore.doesntHaveSelectedFranchise) {
    dashboardStore.setSelectedFranchise(
      userProfile.franchiseDetails[0].franchiseId
    );
  }

  if (dashboardStore.selectedFranchiseDoesntHaveSurveillanceVideos) {
    const surveillancePromise = dashboardStore.getSurveillanceVideos();
    promises.push(surveillancePromise);
  }

  if (userProfile.userProfileStatus !== API_SUCCESS) {
    const userProfilePromise = userProfile.getUserProfileApi();
    promises.push(userProfilePromise);
  }
  try {
    await Promise.all(promises);
    uiStore.setHomeStatus(API_SUCCESS);
  } catch (e) {
    // TODO Handle error and send to sentry
    uiStore.setHomeStatus(API_FAILED);
    uiStore.setHomeError(e);
  }
};

export const onOperatorDashboardOpened = async (
  uiStore: UIStore,
  userProfile
) => {
  uiStore.setHomeStatus(API_FETCHING);
  const appConfigPromise = userProfile.getAppConfig();

  const franchisesPromise = userProfile.getFranchiseDetails();
  try {
    await Promise.all([franchisesPromise, appConfigPromise]);
    uiStore.setHomeStatus(API_SUCCESS);
  } catch (e) {
    // TODO Handle error and send to sentry
    uiStore.setHomeStatus(API_FAILED);
    uiStore.setHomeError(e);
  }
};

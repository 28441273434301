import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";

import { clearUserSession } from "../../../FranchiseLogin/utils/StorageUtils";
import { FRANCHISE_DASHBOARD_SCREEN } from "../../constants/NavigationConstants";
import GenericAvatar from "../../Icons/GenericAvatar";

import Profile from "./Profile";

import {
  HeaderDiv,
  TopBar,
  DashboardHeaderDiv,
  BackWrapperImg,
  IbcLogoImage,
  DashboardHeaderText,
  HeaderNotify,
  SignOut,
  ProfileContainer,
  SignOutButton,
  UserName,
} from "./styledComponents";

@observer
class DashboardHeader extends Component {
  static defaultProps = {
    title: "Franchise Owner Dashboard",
    showBackArrow: false,
    showIBCLogo: true,
  };

  navigateToDashboard = () => {
    this.props.history.push(FRANCHISE_DASHBOARD_SCREEN);
  };

  render() {
    const {
      title,
      showBackArrow,
      showIBCLogo,
      hideHeaderAndWelcomeText,
      franchiseName,
      username,
    } = this.props;
    return (
      <HeaderDiv>
        {hideHeaderAndWelcomeText ? null : (
          <TopBar>
            <DashboardHeaderDiv>
              <IbcLogoImage src="https://res.cloudinary.com/due4dmz2b/image/fetch/dpr_auto,w_auto,f_auto,q_auto/https://proyuga-media-assets.s3.ap-south-1.amazonaws.com/ib-cricket-logo-reg.png" />
              <DashboardHeaderText>{`${franchiseName}  Dashboard`}</DashboardHeaderText>
            </DashboardHeaderDiv>
            <Profile username={username} />
          </TopBar>
        )}
        {this.props.children}
      </HeaderDiv>
    );
  }
}

export default withRouter(DashboardHeader);

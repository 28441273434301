/*
 * @flow
 */

import { action, observable, computed } from "mobx";
import type { APIStatus } from "../../../../../Common/constants/AppAPIConstants";
import { API_INITIAL } from "../../../../../Common/constants/AppAPIConstants";
import { bindPromiseWithOnSuccess } from "../../../../../Common/utils/MobxPromise";
import PieChartGraphData from "../../../../../Common/stores/GraphsData/PieChartGraph/PieChartGraphData";
import GraphUtils from "../../../../../Common/utils/GraphUtils";
import { ENTITY_TYPE_ALL_FRANCHISES } from "../../../../constants/BackendConstants";
import CommonMethods from "../../../../../Common/utils/CommonMethods";
import BasicFilters from "../BasicFilters/";
import type {
  APIBaseGraphData,
  APIGetExpenditureGraphSummaryParameter,
} from "../../../type";

class BaseGraph {
  @observable graphData: PieChartGraphData;
  @observable total: number;
  @observable basicFilters: BasicFilters;
  @observable graphUrl: string;
  @observable graphSummaryAPIStatus: APIStatus;
  @observable period: string;
  @observable entityId: number;
  @observable entityType: string;
  @observable error: any;

  getGraphSummaryAPI: () => Promise<>;

  constructor(
    entityId: number,
    entityType: string,
    basicFilters: BasicFilters
  ) {
    this.graphData = new PieChartGraphData();
    this.graphUrl = "";
    this.basicFilters = new BasicFilters();
    Object.assign(
      this.basicFilters.currentDateFilter,
      basicFilters.currentDateFilter
    );
    this.period = "";
    this.total = 0;
    this.entityId = entityId;
    this.entityType = entityType;
    this.graphSummaryAPIStatus = API_INITIAL;
  }

  @computed
  get showDateFilters(): boolean {
    if (this.entityType === ENTITY_TYPE_ALL_FRANCHISES) {
      return true;
    }
    return false;
  }

  @action.bound
  onReload() {
    this.getGraphSummary(this.graphSummaryRequest());
  }
  @action.bound
  setGraphSummaryAPIStatus(status: APIStatus) {
    this.graphSummaryAPIStatus = status;
  }
  @action.bound
  setGraphSummary(expenditure: APIBaseGraphData) {
    this.graphData.setData(
      GraphUtils.getFormattedPieGraphDataWithOthers(
        expenditure.graph_data.data,
        expenditure.graph_data.labels,
        15
      )
    );
    this.total = expenditure.total;
  }

  @action.bound
  onDateFilterChange(fromDate: string, toDate: string, index: number) {
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    this.getGraphSummary(this.graphSummaryRequest());
  }

  @action.bound
  onPeriodChange(period: string) {
    this.period = period;
  }

  @computed
  get displayTotal(): any {
    return CommonMethods.toCurrency(this.total, {
      isCurrency: true,
      minimizePrecision: 2,
    });
  }

  @action.bound
  graphSummaryRequest = (): APIGetExpenditureGraphSummaryParameter => {
    const requestObject = {
      entity_id: this.entityId,
      entity_type: this.entityType,
    };
    if (!this.basicFilters.currentDateFilter.isReset) {
      requestObject.date_range = this.basicFilters.currentDateFilter.requestData;
    }

    return requestObject;
  };

  // NETWORK Calls
  @action.bound
  getGraphSummary(requestObject: APIExpenditureGraphSummaryParameter) {
    const apiPromise = this.getGraphSummaryAPI(requestObject);
    bindPromiseWithOnSuccess(apiPromise)
      .to(this.setGraphSummaryAPIStatus, this.setGraphSummary)
      .catch((e) => {
        if (true) console.log("this.error", e);
        this.error = e;
      });
    return apiPromise;
  }
}

export default BaseGraph;

import React, { Component } from "react";
import {
  SettlementIncomeDiv,
  AmountDetails,
  AmountText,
  Amount,
  ApproxText,
} from "./styledComponents";

class SettlementIncome extends Component {
  render() {
    const { settlementAmount } = this.props;
    return (
      <SettlementIncomeDiv>
        <AmountDetails>
          <AmountText>Settlement Amount</AmountText>
          <Amount> ₹ {settlementAmount}</Amount>
        </AmountDetails>
        <ApproxText>* this amount is only an approximation </ApproxText>
      </SettlementIncomeDiv>
    );
  }
}

export default SettlementIncome;

/*
 * @flow
 */

import { observable, computed, action, toJS } from "mobx";
import type { IObservableArray, ObservableMap } from "mobx";
import type { APIStatus } from "@ib/api-constants";
import { API_INITIAL, API_SWIPE_REFRESH } from "@ib/api-constants";

import { bindPromiseWithOnSuccess } from "@ib/mobx-promise";
import type { FilterType, Pagination } from "./types";
/*
T: EntityType
U: FilterType
V: SortOrderType
W: RequestType
X: ResponseType
Y: ResponseEntityType
Z: ErrorResponseType
 */
class PaginationStore<T, U, V, W, X, Y, Z> {
  Model: Class<T>;
  @observable entitiesMap: ObservableMap<T>;
  @observable totalEntities: number;
  @observable currentFilters: ObservableMap<IObservableArray<U>> = new Map();
  @observable currentSortOrder: IObservableArray<V> = [];
  @observable pages: ObservableMap<IObservableArray<number>>;
  @observable currentPage: number = 0;
  @observable showPerPage: number = 10;
  @observable entitiesFetchingStatus: APIStatus;
  requestTransformer: (
    pagination: Pagination,
    filters: ObservableMap<IObservableArray<U>>,
    sortOrder: IObservableArray<V>
  ) => mixed;
  defaultGetEntitiesRequestObject: any;
  getEntitiesResponseCallback: (response: X) => mixed;
  getEntitiesErrorCallback: (response: Z) => mixed;
  @observable apiError: any;
  recentGetEntitiesPromise: Promise<X>;
  createModelInstance: (Y) => T;
  getEntitiesFromResponse: (response: X) => Array<Y>;
  getEntitiesCountFromResponse: (response: X) => number;
  responseItemIterator: (model: T) => mixed;
  getEntitiesListAPI: (W) => Promise<X>;
  getStringEntityIndex: (Y) => string;

  constructor(model: Class<T>) {
    this.Model = model;
    this.entitiesMap = observable.map({});
    this.totalEntities = 0;
    this.pages = observable.map({});
    this.entitiesFetchingStatus = API_INITIAL;
    this.requestTransformer = (pagination) => pagination;
    this.responseItemIterator = () => {};
    this.defaultGetEntitiesRequestObject = {};
    this.getEntitiesResponseCallback = () => {};
    this.getEntitiesErrorCallback = () => {};
    this.apiError = {};

    // FIXME: Flowtype error
    this.createModelInstance = (entity) => new this.Model(entity);
  }

  @computed
  get totalPages(): number {
    // return 10
    return Math.ceil(this.totalEntities / this.showPerPage);
  }

  @computed
  get currentPageEntityIds(): IObservableArray<number> {
    if (!this.pages.has(this.currentPage)) {
      return observable([]);
    }
    return this.pages.get(this.currentPage);
  }

  @computed
  get currentPageEntities(): Array<T> {
    return this.currentPageEntityIds.map((entityId) =>
      this.entitiesMap.get(entityId)
    );
  }

  getPaginationForPage(page: number): Pagination {
    return {
      limit: this.showPerPage,
      offset: (page - 1) * this.showPerPage,
    };
  }

  // disposeShowPerPage = reaction(
  //   () => this.showPerPage,
  //   () => {
  //     this.reloadPagesData()
  //   }
  // )

  // // TODO: Ensure that network call is not done when same page is clicked twice.
  // disposeCurrentPage = reaction(
  //   () => this.currentPage,
  //   (page) => {
  //     if (!this.pages.has(page)) {
  //       this.reloadCurrentPage()
  //     }
  //   }
  // )

  // disposeCurrentFilters = reaction(
  //   () => this.currentFilters.values(),
  //   () => {
  //     this.reloadPagesData()
  //   },
  //   { compareStructural: true }
  // )

  // disposeCurrentSortOrder = reaction(
  //   () => this.currentSortOrder.slice(),
  //   () => {
  //     this.reloadPagesData()
  //   },
  //   { compareStructural: true }
  // )

  @action.bound
  resetCurrentSortOrder() {
    this.currentSortOrder.clear();
  }

  @action.bound
  resetCurrentFilters() {
    this.currentFilters.clear();
  }

  @action.bound
  reloadPagesData() {
    this.clearPagesCache();
    // return this.reloadCurrentPage()
  }

  @action.bound
  resetPaginationState() {
    this.changeCurrentPage(1);
    this.resetCurrentFilters();
    this.resetCurrentSortOrder();
    this.reloadPagesData();
  }

  @action.bound
  clearPagesCache() {
    this.pages.clear();
    this.changeCurrentPage(1);
  }

  @action.bound
  reloadCurrentPage() {
    // FIXME: Flowtype error
    const promise = this.getEntities();
    this.recentGetEntitiesPromise = promise;
    return promise;
  }

  // Assuming onSwiperefresh filters,sorting wont be changed, currentPage will be set to 1
  swipeRefreshPage() {
    // FIXME: Flowtype error
    this.changeCurrentPage(1, false);
    const promise = this.getEntities({}, true, this.currentPage, () => {}, {
      loadingStatus: API_SWIPE_REFRESH,
    });
    this.recentGetEntitiesPromise = promise;
    return promise;
  }

  @action.bound
  loadPage(page: number) {
    // FIXME: Flowtype error
    const promise = this.getEntities({}, true, page);
    this.recentGetEntitiesPromise = promise;
    return promise;
  }

  // @action.bound
  // changeCurrentPage(page: number) {
  //   if (typeof page === 'number' && Math.round(page) === page && page > 0) {
  //     this.currentPage = page
  //   } else {
  //     throw Error(`current page: ${page} is not a valid number`)
  //   }
  // }

  @action.bound
  changeCurrentPage(page: number, shouldReactImmediately: boolean = true) {
    if (typeof page === "number" && Math.round(page) === page && page > 0) {
      this.currentPage = page;
    } else {
      throw Error(`current page: ${page} is not a valid number`);
    }
    if (shouldReactImmediately) {
      this.reloadCurrentPage();
    }
  }

  // @action.bound
  // changeShowPerPage(showPerPage: number) {
  //   if (
  //     typeof showPerPage === 'number' &&
  //     Math.round(showPerPage) === showPerPage &&
  //     showPerPage > 0
  //   ) {
  //     this.showPerPage = showPerPage
  //   } else {
  //     throw Error(`show per page: ${showPerPage} is not a valid number`)
  //   }
  // }

  @action.bound
  changeShowPerPage(
    showPerPage: number,
    shouldReactImmediately: boolean = true
  ) {
    if (
      typeof showPerPage === "number" &&
      Math.round(showPerPage) === showPerPage &&
      showPerPage > 0
    ) {
      this.showPerPage = showPerPage;
    } else {
      throw Error(`show per page: ${showPerPage} is not a valid number`);
    }
    if (shouldReactImmediately) {
      this.reloadPagesData();
    }
  }

  // @action.bound
  // changeCurrentFilter(filterKey: FilterType, filterValues: Array<U>) {
  //   this.currentFilters.set(filterKey, observable(filterValues))
  // }

  @action.bound
  changeCurrentFilter(
    filterKey: FilterType,
    filterValues: Array<U>,
    shouldReactImmediately: boolean = true
  ) {
    this.currentFilters.set(filterKey, observable(filterValues));
    if (shouldReactImmediately) {
      this.reloadPagesData();
    }
  }

  @action.bound
  addFilterValueForCurrentFilterKey(filterKey: FilterType, filterValue: U) {
    if (this.currentFilters.has(filterKey)) {
      const cfValues = this.currentFilters.get(filterKey);
      cfValues.push(filterValue);
    } else {
      this.currentFilters.set(filterKey, observable.array([filterValue]));
    }
  }

  @action.bound
  removeFilterValueFromCurrentFilterKey(filterKey: FilterType, filterValue: U) {
    if (this.currentFilters.has(filterKey)) {
      const cfValues = this.currentFilters.get(filterKey);
      cfValues.remove(filterValue);
    }
  }

  @action.bound
  removeFilterObjectFromCurrentFiltersByKey(filterKey: FilterType) {
    if (this.currentFilters.has(filterKey)) {
      this.currentFilters.delete(filterKey);
    }
  }

  // @action.bound
  // changeCurrentSortOrder(sortValues: Array<V>) {
  //   this.currentSortOrder.replace(observable(sortValues))
  // }

  @action.bound
  changeCurrentSortOrder(
    sortValues: Array<V>,
    shouldReactImmediately: boolean = true
  ) {
    this.currentSortOrder.replace(observable(sortValues));
    if (shouldReactImmediately) {
      this.reloadPagesData();
    }
  }

  @action.bound
  addSortFieldToCurrentSortOrder(sortValue: V) {
    this.currentSortOrder.push(sortValue);
  }

  @action.bound
  async getEntities(
    requestObject?: W,
    shouldUpdateImmediately?: boolean = true,
    currentPage?: number = this.currentPage,
    callback?: (response: ?X) => mixed = () => {},
    options?: any
  ): Promise<X> {
    const pagination = this.getPaginationForPage(this.currentPage);
    console.log("pagination", toJS(pagination));
    const request = Object.assign(
      {},
      this.defaultGetEntitiesRequestObject,
      this.requestTransformer(
        pagination,
        this.currentFilters,
        this.currentSortOrder
      ),
      requestObject
    );
    const entitiesPromise = this.getEntitiesListAPI(request);
    if (shouldUpdateImmediately === true) {
      return bindPromiseWithOnSuccess(entitiesPromise, options)
        .to(
          (status: APIStatus) => {
            if (currentPage === this.currentPage) {
              this.setEntitiesFetchingStatus(status);
            }
          },
          (response) => {
            this.setEntitiesResponse(response, currentPage);
          }
        )
        .then((response) => {
          this.getEntitiesResponseCallback(response);
          callback(response);
        })
        .catch((error) => {
          this.getEntitiesErrorCallback(error);
          this.apiError = error;
        });
    }
    return entitiesPromise;
  }

  @action.bound
  setEntitiesResponse(entitiesResponse: X, page: number) {
    this.addEntitiesToPage(
      this.getEntitiesFromResponse(entitiesResponse),
      page
    );
    const totalEntities = this.getEntitiesCountFromResponse(entitiesResponse);
    if (typeof totalEntities === "number") {
      this.totalEntities = totalEntities;
    } else {
      throw Error(
        "total entities is not number in getEntitiesCountFromResponse"
      );
    }
  }

  @action.bound
  setEntitiesFetchingStatus(status: APIStatus): void {
    this.entitiesFetchingStatus = status;
  }

  @action.bound
  addEntitiesToPage(entities: Array<Y>, page: number) {
    if (entities instanceof Array) {
      const entityIds = entities.map((entity) => {
        const entityId = this.getStringEntityIndex(entity);
        const model = this.createModelInstance(entity);
        this.entitiesMap.set(entityId, model);
        this.responseItemIterator(model);
        return entityId;
      });
      if (this.pages.has(page)) {
        this.pages.get(page).replace(entityIds);
      } else {
        this.pages.set(page, observable(entityIds));
      }
    } else {
      throw Error(`Entities is not an instance of array for page ${page}`);
    }
  }
}

export default PaginationStore;

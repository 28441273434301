import styled from "@emotion/styled";

export const Typo24WhiteCerebriSans = styled.div`
  font-family: CerebriSans;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
`
/*
 * @flow
 * @Author: Chinmaya
 */

export const FULL_SCREEN_ERROR = 'FULL_SCREEN_ERROR'
export const SECTION_ERROR = 'SECTION_ERROR'

export const NO_INTERNET_CONNECTION_CODE = 503

export type ErrorViewType = 'FULL_SCREEN_ERROR' | 'SECTION_ERROR'

export const ACCESS_TOKEN_EXPIRED_CODE = 401

/*
 * @flow
 */

import { observable } from 'mobx'
import type { APIQualityScore } from '../../type.js'

class QualityScore {
  @observable current: number
  @observable total: number

  constructor(qualityScore: APIQualityScore) {
    this.current = qualityScore.current
    this.total = qualityScore.total
  }
}
export default QualityScore

/*
 * @author: Pavan
 * @flow
 */

import { observable } from 'mobx'
import type { APIStatItem } from './type'

class StatItem {
  @observable value: string
  @observable key: string
  @observable statId: string
  @observable image: string

  constructor(statItem: APIStatItem) {
    this.value = statItem.value || '0'
    this.key = statItem.key || ''
    this.statId = statItem.stat_id || ''
    this.image = statItem.image || ''
  }
}
export default StatItem

/*
 * @flow
 */

import { observable, action } from 'mobx'
import type { APIFranchise } from '../../type.js'

class Franchise {
  @observable name: string
  @observable franchiseId: number

  constructor(franchise: APIFranchise) {
    this.name = franchise.name
    this.franchiseId = franchise.franchise_id
  }

  @action.bound
  setName(name: string) {
    this.name = name
  }

  @action.bound
  setFranchiseId(franchiseId: number) {
    this.franchiseId = franchiseId
  }
}
export default Franchise

import React from "react";
import styled from "@emotion/styled";
import { useTable, useSortBy } from "react-table";
import moment from "moment";
import { API_FETCHING } from "@ib/api-constants";

import PaginationNavigation from "../../../Common/components/PaginationNavigation";
import SortByIcon from "../../../Common/Icons/SortByIcon";
import Loader from "../../../Common/Icons/Loader";

import DatePickerWithPresetButtons from "../DatePickerWithPresetButtons";
import NoDataView from "../NoDataView/NoDataView";

import {
  Container,
  PaymentSummaryTableDiv,
  PaymentSummaryHeaderContainer,
  PaymentSummaryHeading,
  PaymentSummaryTableDetails,
  NoTransaction,
  LoaderViewDiv,
} from "./styledComponents";
import { withTranslation } from "react-i18next";

function Table({ columns, data, onDateFilterChanged, paymentSummaryStore, t }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const startDate = new moment(
    paymentSummaryStore.basicFilters.currentDateFilter.fromDate
  );
  const endDate = new moment(
    paymentSummaryStore.basicFilters.currentDateFilter.toDate
  );

  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {column.render("Header")}
                  <span
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {column.disableSortBy ? "" : <SortByIcon />}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const renderBody = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <PaymentSummaryTableDiv>
      <PaymentSummaryHeaderContainer>
        <PaymentSummaryHeading>
          {t("revenueManagement:revenueModule.paymentSummary")}
        </PaymentSummaryHeading>
        <DatePickerWithPresetButtons
          onDatesChange={onDateFilterChanged}
          initialStartDate={startDate}
          initialEndDate={endDate}
        />
      </PaymentSummaryHeaderContainer>
      <PaymentSummaryTableDetails>
        <table {...getTableProps()}>
          {renderHeader()}
          {renderBody()}
        </table>
      </PaymentSummaryTableDetails>
    </PaymentSummaryTableDiv>
  );
}

function PaymentSummaryTable(props) {
  const {
    loadingStatus,
    paymentSummaryItems,
    totalPagesCount,
    currentPage,
    onPagePress,
    paymentSummaryStore,
    error,
    t,
    onRetry,
  } = props;
  const { onDateFilterChanged } = paymentSummaryStore;

  const columns = React.useMemo(
    () => [
      {
        Header: t("revenueManagement:revenueModule.date"),
        accessor: "date",
        Cell: (row) => {
          const date = moment(new Date(row.value)).format("Do MMM YYYY");
          return <span>{`${date}`}</span>;
        },
      },
      {
        Header: t("revenueManagement:revenueModule.franchise"),
        accessor: "franchiseName",
        disableSortBy: true,
      },
      {
        Header: t("revenueManagement:revenueModule.cashCollectedAmount"),
        accessor: "cashCollectedAmount",
        disableSortBy: true,
      },
      {
        Header: t("revenueManagement:revenueModule.digitalModeCollectedAmount"),
        accessor: "digitalCollectedAmount",
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <Container>
      <Table
        columns={columns}
        data={paymentSummaryItems}
        onDateFilterChanged={onDateFilterChanged}
        paymentSummaryStore={paymentSummaryStore}
        t={t}
      />
      {loadingStatus === API_FETCHING && paymentSummaryItems.length === 0 ? (
        <LoaderViewDiv>
          <Loader width={40} height={40} />
        </LoaderViewDiv>
      ) : paymentSummaryItems.length === 0 ? (
        <NoTransaction>
          <NoDataView
            text={t("revenueManagement:revenueModule.noPayments")}
            spanText={t("revenueManagement:revenueModule.removeFilters")}
          />
        </NoTransaction>
      ) : null}
      <PaginationNavigation
        totalPagesCount={totalPagesCount}
        currentPage={currentPage}
        maxDisplayPagesCount={9}
        onPagePress={onPagePress}
      />
    </Container>
  );
}

export default withTranslation()(PaymentSummaryTable);

// /**
//  * @flow
//  */

// import React, { Component } from 'react'
// import { observer } from 'mobx-react/native'
// import type { IObservableArray } from 'mobx'
// import I18n from 'react-native-i18n'
// import Touchable from '@rn/commons/app/components/GenericComponents/Touchable'
// // import FlatListContainer from '@rn/commons/app/components/FlatListContainer/FlatListContainer'
// // import { API_FETCHING } from '@rn/commons/app/constants/AppAPIConstants'
// import type { APIStatus } from '@rn/commons/app/constants/AppAPIConstants'
// import LoadingWindow from 'Common/components/LoadingWindow'
// import SortArrows from 'Common/themes/icons/SortArrows'
// import { SectionHeading, Body } from 'Common/styledComponents/Text/StyledTexts'
// import Divider from 'Common/styledComponents/View/Divider'
// import { Row } from '../../../Common/components/Table'
// import Window from '../../../Common/styledComponents/View/Window'
// import NoItemsView from '../../../Common/components/NoItemsView'
// import PaymentSummaryStore from '../../stores/PaymentSummaryStore'
// import PaymentSummaryItem from '../../stores/models/common/PaymentSummaryItem'
// import ErrorView from '../../../Common/components/ErrorView'
// import { transactionLogSortTypes } from '../../constants/BackendConstants'
// import DateFilterWrapper from '../../../Common/components/DateFilterWrapper'
// import { SECTION_ERROR } from '../../../Common/constants/ErrorViewConstants'
// import {
//   IconMargin,
//   TableHeader,
//   Section,
//   SummaryText,
//   WhiteContainer,
//   TableCell1,
//   TableCell2,
//   TableCell3,
//   TableCell4,
//   PaginationNavigation,
//   BodyRow,
//   MainContainer,
//   Card
// } from './styledComponents'
// import styles from './styles'

// type Props = {
//   paymentSummaryItems: IObservableArray<PaymentSummaryItem>,
//   loadingStatus: APIStatus,
//   onSortChange: string => mixed,
//   totalPagesCount: number,
//   currentPage: number,
//   onPagePress: () => mixed,
//   error: any,
//   onRetry: () => mixed,
//   paymentSummaryStore: PaymentSummaryStore
// }

// type PaymentSummaryItemProps = {
//   paymentSummaryItem: PaymentSummaryItem // eslint-disable-line
// }
// @observer
// class PaymentSummaryTable extends Component<Props> {
//   onPress = (column: string) => {
//     this.props.onSortChange(column)
//   }

//   applyDateSort = () => {
//     this.onPress(transactionLogSortTypes.date)
//   }

//   renderFailedView = () => (
//     <Window style={styles.tableBody}>
//       <ErrorView
//         error={this.props.error}
//         errorViewType={SECTION_ERROR}
//         onRetry={this.props.onRetry}
//       />
//     </Window>
//   )

//   renderLoader = () => <LoadingWindow style={styles.tableBody} />

//   renderNoItemsWindow = () => (
//     <NoItemsView
//       noItemsText={I18n.t('revenueModule.noPayments')}
//       noItemsDescription={I18n.t('franchiseCommon.tryRemovingFilters')}
//       style={styles.tableBody}
//     />
//   )

//   renderDateFilter = () => {
//     const { paymentSummaryStore } = this.props
//     const { onDateFilterChanged, basicFilters } = paymentSummaryStore
//     return (
//       <DateFilterWrapper
//         onDatesSelected={onDateFilterChanged}
//         onResetOptionSelect={onDateFilterChanged}
//         fromDefaultDate={basicFilters.currentDateFilter.formattedFromDate}
//         toDefaultDate={basicFilters.currentDateFilter.formattedToDate}
//         defaultIndex={basicFilters.currentDateFilter.dateFilterIndex}
//       />
//     )
//   }

//   renderHeader = () => (
//     <WhiteContainer>
//       <Section>
//         <SummaryText>
//           <SectionHeading>
//             {I18n.t('revenueModule.paymentSummary')}
//           </SectionHeading>
//         </SummaryText>
//         {this.renderDateFilter()}
//       </Section>
//       <Divider />
//       <TableHeader>
//         <Row>
//           <Touchable onPress={this.applyDateSort}>
//             <TableCell1>
//               <Body>{I18n.t('revenueModule.date')}</Body>
//               <IconMargin>
//                 <SortArrows />
//               </IconMargin>
//             </TableCell1>
//           </Touchable>

//           <TableCell2>
//             <Body>{I18n.t('revenueModule.franchise')}</Body>
//           </TableCell2>

//           <TableCell3>
//             <Body>{I18n.t('revenueModule.cashCollectedAmount')}</Body>
//           </TableCell3>

//           <TableCell4>
//             <Body>{I18n.t('revenueModule.digitalModeCollectedAmount')}</Body>
//           </TableCell4>
//         </Row>
//       </TableHeader>
//       <Divider />
//     </WhiteContainer>
//   )

//   renderRow = (props: PaymentSummaryItemProps) => {
//     const {
//       paymentDate,
//       cashCollectedAmount,
//       franchiseName,
//       digitalCollectedAmount
//     } = props.paymentSummaryItem

//     return observer(
//       <BodyRow>
//         <Row rowStyles={styles.tableBodyStyles}>
//           <TableCell1>
//             <Body>{paymentDate}</Body>
//           </TableCell1>
//           <TableCell2>
//             <Body>{franchiseName}</Body>
//           </TableCell2>
//           <TableCell3>
//             <Body>{cashCollectedAmount}</Body>
//           </TableCell3>
//           <TableCell4>
//             <Body>{digitalCollectedAmount}</Body>
//           </TableCell4>
//         </Row>
//       </BodyRow>
//     )
//   }

//   render() {
//     const {
//       loadingStatus,
//       paymentSummaryItems,
//       totalPagesCount,
//       currentPage,
//       onPagePress
//     } = this.props
//     return (
//       <MainContainer>
//         <Card>
//           {this.renderHeader()}
//           <FlatListContainer
//             style={styles.listStyle}
//             data={paymentSummaryItems}
//             renderComponent={this.renderRow}
//             loadingStatus={loadingStatus}
//             renderLoader={this.renderLoader}
//             itemPropLabel="paymentSummaryItem"
//             renderFailedView={this.renderFailedView}
//             renderNoItemsView={this.renderNoItemsWindow}
//           />
//         </Card>
//         {loadingStatus !== API_FETCHING ? (
//           <PaginationNavigation
//             totalPagesCount={totalPagesCount}
//             currentPage={currentPage}
//             maxDisplayPagesCount={9}
//             onPagePress={onPagePress}
//           />
//         ) : null}
//       </MainContainer>
//     )
//   }
// }

// export default PaymentSummaryTable

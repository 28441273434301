/*
* @flow
* @author: Dinesh
*/

export const TRANSACTION_LOGS = 'Transactions'
export const EXPENDITURE_LOGS = 'Expenditures'

export const SUMMARY_BY_REVENUE_TRANSACTION_LOGS = 'Revenue'
export const SUMMARY_BY_EXPENDITURE_TRANSACTION_LOGS =
  'Expenditure Transactions'

export const summaryByTrasactionLogs = {
  revenueLogs: SUMMARY_BY_REVENUE_TRANSACTION_LOGS,
  expenditureLogs: SUMMARY_BY_EXPENDITURE_TRANSACTION_LOGS
}

export type SummaryByTransactionLogsType =
  | 'Revenue Transactions'
  | 'Expenditure Transactions'

/**
 * @flow
 */

import React, { Component } from "react";
import { observable } from "mobx";
import { observer, inject } from "mobx-react";
import moment from "moment";
import { withTranslation } from "react-i18next";

import RevenueSummaryAreaGraph from "../../../Dashboard/components/RevenueSummaryAreaGraph";
import userProfile from "../../../FranchiseLogin/stores/UserProfile";
import DashboardHeader from "../../../Common/components/DashboardHeader/DashboardHeader";
import LoadingWrapper from "../../../Common/components/LoadingWrapper";
import { onDashboardOpened } from "../../../Dashboard/utils/SceneNavigationUtils";
import FranchiseDetailsSummaryCard from "../../../Dashboard/components/FranchiseDetailsSummaryCard";
import RevenueCurrencyIcon from "../../../Common/Icons/RevenueCurrencyIcon";
import LoadingView from "../../../Common/components/LoadingView";

import type { RevenueStore } from "../../stores";
import TotalIconTextAmountCard from "../../components/TotalIconTextAmountCard/TotalIconTextAmountCard";
import InflowOutFlowBarGraph from "../../components/InflowOutFlowBarGraph";
import ExpenditurePieChart from "../../components/ExpenditurePieChart";
import SettlementCard from "../../components/SettlementCard";
import StatsRevenueSummary from "../../components/StatsRevenueSummary/StatsRevenueSummary";
import SampleTable from "../../components/SampleTable";
import ReactSelect from "../../components/ReactSelect";
import TransactionsAndExpenditureLogs from "../../components/TransactionsAndExpenditureLogs";
import RevenuePieChart from "../../components/RevenuePieChart";
import RevenueExpenditureChannelsTabBar from "../../components/RevenueExpenditureChannelsTabBar/RevenueExpenditureChannelsTabBar";
import PaymentSummary from "../../components/PaymentSummary";
import DatePickerWithPresetButtons from "../../components/DatePickerWithPresetButtons";
import NoDataGraphView from "../../components/NoDataGraphView/NoDataGraphView";

import {
  MainContainer,
  WelcomeText,
  TotalIconTextAmountCardDiv,
  SummaryCards,
  Subcontainer,
  OverAllStats,
  RevenueGraphDiv,
  SummaryTabsAndFilters,
  RevenueGraphSubDiv,
  RevenueGraphContainer,
  NoTransaction,
  GraphDiv,
  ReactSelectComponent,
  ReactSelectAndDatePickerDiv,
  ReactSelectButtonDiv,
  SummaryWithChannelsFilterContainer,
  FranchiseTabBarMainDiv,
  FranchiseTabBarSubDiv,
  AllButton,
  EachButton,
  LoaderContainer,
  DisplayInMobile,
  DisplayInTabletAndDesktop,
  ViewInMobileContainer,
  ViewInMobileText,
} from "./styledComponents";

type Props = {};

@inject("uiStore", "userProfile", "dashboardStore", "revenueStore")
@observer
class RevenueSummaryWithFranchisesTabbar extends Component<
  Props & { revenueStore: RevenueStore }
> {
  @observable displayAllFranchisesData = true;

  @observable individualFranchiseTotalRevenueAmount = 0;

  @observable bothAPISNotDone = true;

  async handleOnChangeTab(franchiseId: string) {
    this.bothAPISNotDone = true;
    this.props.revenueStore.changeSelectedFranchiseWithFranchiseId(franchiseId);
    const { uiStore, dashboardStore, userProfile, revenueStore } = this.props;
    await onDashboardOpened(uiStore, dashboardStore, userProfile);
    await this.props.revenueStore.onFranchiseChange();

    const { selectedFranchiseEntity } = revenueStore;

    const revenueStartDate = new moment("1-1-2017");
    const revenueEndDate = new moment();

    //NOTE: Here, we are making a function call to get the total revenue of the individual franchise
    await selectedFranchiseEntity.onDateFilterChanged(
      revenueStartDate,
      revenueEndDate,
      1
    );

    const { totalRevenueAmount } = selectedFranchiseEntity.total;
    this.individualFranchiseTotalRevenueAmount = totalRevenueAmount;

    const revenueStartDate1 = new moment().subtract(6, "days");
    const revenueEndDate1 = new moment();

    await selectedFranchiseEntity.onDateFilterChanged(
      revenueStartDate1,
      revenueEndDate1,
      1
    );
    this.bothAPISNotDone = false;
  }

  async componentDidMount() {
    const { uiStore, dashboardStore, userProfile } = this.props;
    await onDashboardOpened(uiStore, dashboardStore, userProfile);
    await this.props.revenueStore.onFranchiseChange();
  }

  formatOptionsForReactSelect = (data) => {
    return data.map((item) => {
      return {
        label: item.filterName,
        value: item.filterName,
        ...item,
      };
    });
  };

  handleRevenueGraphSelectChange = (selectedOption) => {
    const { revenueStore } = this.props;
    const { entityDetails } = revenueStore;
    const { inflowVsOutFlow } = entityDetails.graphsAndAnalytics;
    let selectedData = [];
    if (selectedOption !== null) {
      selectedData = inflowVsOutFlow.displayChannelFilters.filter(
        (item) => item.filterName === selectedOption.value
      );
      const index = inflowVsOutFlow.displayChannelFilters.findIndex(
        (ele) => ele.name === selectedOption.name
      );
      if (selectedData[0].filterId !== "-1") {
        inflowVsOutFlow.onFilterChanged(index, selectedData[0]);
      } else {
        inflowVsOutFlow.onFilterReset(index, selectedData[0]);
      }
    }
  };

  //TODO: write  a common util for this
  returnAnArrayOfObjects = (values) => {
    const dataXValues = [];
    const dataYValues = [];
    const data = [];
    {
      values.xValues.length > 0 &&
        values.xValues.map((eachItem) =>
          dataXValues.push({
            name: eachItem,
          })
        );
      values.data.length > 0 &&
        values.data[0].values.map((eachItem) =>
          dataYValues.push({
            y: eachItem.y,
            marker: eachItem.marker,
          })
        );
      dataXValues.map((eachItem, index) =>
        data.push({
          name: eachItem.name,
          y: dataYValues[index].y,
          marker: dataYValues[index].marker,
        })
      );
    }
    return data;
  };

  render() {
    const {
      revenueStore,
      t,
      hideHeaderAndWelcomeText,
      dashboardStore,
      uiStore,
    } = this.props;

    const {
      revenueData,
      franchisesDetails,
      displayFranchiseIndex,
      changeDisplayFranchiseIndex,
      franchisesHealth,
    } = dashboardStore;

    const {
      revenueHomeStatus,
      onRevenueSummarySectionReload,
      error,
      selectedFranchiseEntity,
      franchiseEntityList,
      entityDetails,
    } = revenueStore;

    let entityData = entityDetails;
    if (this.displayAllFranchisesData) {
      entityData = entityDetails;
    } else {
      entityData = selectedFranchiseEntity;
    }

    const username =
      userProfile.name || t("revenueManagement:revenueModule.guest");
    const {
      inflowVsOutFlow,
      expenditures,
      revenues,
    } = entityData.graphsAndAnalytics;
    const {
      totalSettlementAmount,
      totalRevenueAmount,
      totalExpenditureAmount,
      approxSettlementAmount,
    } = entityData.total;
    const {
      todayRevenueAmount,
      todayExpenditureAmount,
    } = entityData.todayRevenueExpenditure;
    let graphData;
    if (inflowVsOutFlow != undefined) {
      graphData = this.returnAnArrayOfObjects(inflowVsOutFlow.graphData);
    }

    const inflowVsOutFlowGraphStartDate = new moment(
      inflowVsOutFlow.basicFilters.currentDateFilter.fromDate
    );
    const inflowVsOutFlowGraphEndDate = new moment(
      inflowVsOutFlow.basicFilters.currentDateFilter.toDate
    );

    let franchiseName = this.props.t(
      "revenueManagement:revenueModule.franchise"
    );
    let totalRevenue;
    if (franchisesDetails != undefined) {
      if (!this.displayAllFranchisesData) {
        franchiseName = franchisesDetails[displayFranchiseIndex].franchise.name;
        totalRevenue = this.individualFranchiseTotalRevenueAmount;
      } else {
        franchiseName = this.props.t(
          "revenueManagement:revenueModule.franchise"
        );
        totalRevenue = totalRevenueAmount;
      }
    }

    const getInflowAndOutFlowGraphDateFilterFunction = () => {
      return this.displayAllFranchisesData
        ? inflowVsOutFlow.onDateFilterChange
        : entityData.onDateFilterChanged;
    };

    return (
      <>
        <DisplayInMobile>
          <ViewInMobileContainer>
            <ViewInMobileText>
              {t("common:franchiseCommon.showInDesktopOrTablet")}
            </ViewInMobileText>
          </ViewInMobileContainer>
        </DisplayInMobile>
        <DisplayInTabletAndDesktop>
          <DashboardHeader
            franchiseName={franchiseName}
            showIBCLogo={false}
            showBackArrow={true}
            hideHeaderAndWelcomeText={hideHeaderAndWelcomeText}
            username={username}
          >
            {franchisesDetails !== undefined && franchisesDetails.length > 1 ? (
              <FranchiseTabBarMainDiv>
                <FranchiseTabBarSubDiv>
                  <AllButton
                    onClick={() => {
                      changeDisplayFranchiseIndex(0);
                      this.displayAllFranchisesData = true;
                    }}
                    isSelected={this.displayAllFranchisesData}
                  >
                    {t("common:franchiseCommon.all")}
                  </AllButton>
                  {franchisesDetails.map((franchise, index) => (
                    <EachButton
                      onClick={() => {
                        this.handleOnChangeTab(franchise.franchise.franchiseId);
                        changeDisplayFranchiseIndex(index);
                        this.displayAllFranchisesData = false;
                      }}
                      isSelected={
                        displayFranchiseIndex === index &&
                        !this.displayAllFranchisesData
                      }
                      key={franchise.franchise.franchiseId}
                    >
                      {franchise.franchise.name}
                    </EachButton>
                  ))}
                </FranchiseTabBarSubDiv>
              </FranchiseTabBarMainDiv>
            ) : null}
            <LoadingWrapper
              apiStatus={
                this.displayAllFranchisesData ? revenueHomeStatus : 200
              }
              apiError={error}
              onRetry={onRevenueSummarySectionReload}
            >
              {/* Here We are writing this condition to handle display the loader until both the API calls in handleOnChangeTab function are done */}
              {!this.displayAllFranchisesData && this.bothAPISNotDone ? (
                <LoaderContainer>
                  <LoadingView />
                </LoaderContainer>
              ) : (
                <MainContainer>
                  <Subcontainer>
                    {hideHeaderAndWelcomeText ? null : (
                      <WelcomeText>{`Welcome ${username}!`}</WelcomeText>
                    )}
                    <TotalIconTextAmountCardDiv>
                      <TotalIconTextAmountCard
                        title={t(
                          "revenueManagement:revenueModule.totalRevenue"
                        )}
                        icon={RevenueCurrencyIcon}
                        width={54}
                        height={54}
                        color={"#33c6dd"}
                        amount={totalRevenue}
                      />
                      <TotalIconTextAmountCard
                        title={t("dashboard:dashboardModule.todayIncome")}
                        icon={RevenueCurrencyIcon}
                        width={54}
                        height={54}
                        color={"#9879ce"}
                        amount={todayRevenueAmount}
                      />
                      <SettlementCard
                        amount={totalSettlementAmount}
                        displayHelpText={approxSettlementAmount}
                      />
                    </TotalIconTextAmountCardDiv>
                    <OverAllStats>
                      <RevenueGraphDiv>
                        <RevenueGraphContainer>
                          <span>
                            {t("revenueManagement:revenueModule.revenue")}
                          </span>
                          <ReactSelectAndDatePickerDiv>
                            <ReactSelectButtonDiv>
                              <ReactSelectComponent
                                options={inflowVsOutFlow.displayChannelFilters}
                                onChange={this.handleRevenueGraphSelectChange}
                                formatOptionsForReactSelect={
                                  this.formatOptionsForReactSelect
                                }
                                placeholder={t(
                                  "common:franchiseCommon.allChannels"
                                )}
                              />
                            </ReactSelectButtonDiv>
                            <DatePickerWithPresetButtons
                              onDatesChange={getInflowAndOutFlowGraphDateFilterFunction()}
                              initialStartDate={inflowVsOutFlowGraphStartDate}
                              initialEndDate={inflowVsOutFlowGraphEndDate}
                            />
                          </ReactSelectAndDatePickerDiv>
                        </RevenueGraphContainer>
                        <RevenueGraphSubDiv>
                          <RevenueSummaryAreaGraph graphData={graphData} />
                        </RevenueGraphSubDiv>
                      </RevenueGraphDiv>
                      <FranchiseDetailsSummaryCard
                        franchisesDetails={franchisesDetails}
                        displayFranchiseIndex={displayFranchiseIndex}
                        changeDisplayFranchiseIndex={
                          changeDisplayFranchiseIndex
                        }
                        franchisesHealth={franchisesHealth}
                        hideArrows={this.displayAllFranchisesData}
                      />
                    </OverAllStats>
                    <SummaryTabsAndFilters>
                      <TransactionsAndExpenditureLogs
                        franchiseEntity={entityData}
                      />
                    </SummaryTabsAndFilters>
                    <PaymentSummary franchiseEntity={entityData} />
                    <SummaryWithChannelsFilterContainer>
                      <RevenueExpenditureChannelsTabBar entity={entityData} />
                    </SummaryWithChannelsFilterContainer>
                  </Subcontainer>
                </MainContainer>
              )}
            </LoadingWrapper>
          </DashboardHeader>
        </DisplayInTabletAndDesktop>
      </>
    );
  }
}

export default withTranslation()(RevenueSummaryWithFranchisesTabbar);

/**
 * @flow
 */

import { observer } from "mobx-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import CustomerRating from "../../../Common/components/CustomerRating/CustomerRating";
import ImageTitleAndNumber from "../../../Common/components/ImageTitleAndNumber/ImageTitleAndNumber";
import ScoreInPercentage from "../../../Common/components/ScoreInPercentage/ScoreInPercentage";
import DashboardSummaryItemCardContainer from "../DashboardSummaryItemCardContainer";
import DashboardSummaryItemTitleWithIcon from "../DashboardSummaryItemTitleWithIcon";
import i18n from "../../../Common/i18n";
import FranchiseIcon from "../../../Common/Icons/FranchiseIcon";
import FranchiseClosedIcon from "../../../Common/Icons/FranchiseClosedIcon";
import PreviousArrow from "../../../Common/Icons/PreviousArrow";
import NextArrow from "../../../Common/Icons/NextArrow";
import FranchiseOpenIcon from "../../../Common/Icons/FranchiseOpenIcon";

import {
  DashboardSummaryItemContentContainer,
  DashboardFranchiseStatus,
  FranchiseDetailsContainer,
  FranchiseStatus,
  ScoreAndRating,
  TodayStatus,
  TodaysQuality,
  QualityText,
  TodayStatusText,
  ArcadeQualityScoreDiv,
  ArcadeTitle,
  TodaysStatusContent,
  ArrowAndNameContainer,
  RightArrowDiv,
  LeftArrowDiv,
  ArrowContainerButton,
  FranchiseCountContainer,
} from "./styledComponents";

@observer
class FranchiseDetailsSummaryCard extends Component {
  decreaseDisplayFranchiseIndex = () => {
    const { displayFranchiseIndex, changeDisplayFranchiseIndex } = this.props;
    changeDisplayFranchiseIndex(displayFranchiseIndex - 1);
  };

  increaseDisplayFranchiseIndex = () => {
    const { displayFranchiseIndex, changeDisplayFranchiseIndex } = this.props;
    changeDisplayFranchiseIndex(displayFranchiseIndex + 1);
  };

  renderSingleFranchiseHeader = (isFranchiseOpen, franchiseName) => {
    const { displayFranchiseIndex, franchisesDetails, hideArrows } = this.props;
    return (
      <DashboardFranchiseStatus>
        <ArrowAndNameContainer>
          {franchisesDetails.length > 1 && hideArrows ? (
            <ArrowContainerButton
              onClick={this.decreaseDisplayFranchiseIndex}
              disabled={displayFranchiseIndex - 1 < 0}
            >
              <LeftArrowDiv>
                <PreviousArrow />
              </LeftArrowDiv>
            </ArrowContainerButton>
          ) : null}
          <DashboardSummaryItemTitleWithIcon
            title={franchiseName}
            icon={FranchiseIcon}
          />
        </ArrowAndNameContainer>
        <ArrowAndNameContainer>
          {isFranchiseOpen ? (
            <FranchiseOpenIcon />
          ) : (
            <FranchiseClosedIcon width={36} height={36} />
          )}
          {franchisesDetails.length > 1 && hideArrows ? (
            <ArrowContainerButton
              onClick={this.increaseDisplayFranchiseIndex}
              disabled={displayFranchiseIndex + 1 >= franchisesDetails.length}
            >
              <RightArrowDiv>
                <NextArrow />
              </RightArrowDiv>
            </ArrowContainerButton>
          ) : null}
        </ArrowAndNameContainer>
      </DashboardFranchiseStatus>
    );
  };

  renderSingleFranchiseDetails = (franchiseDetails) => {
    const isFranchiseOpen = franchiseDetails.isOpen;
    const franchiseName = franchiseDetails.franchise.name;
    const arcadeScore = franchiseDetails.qualityScore;
    const qualityCheck = franchiseDetails.qualityChecklistItems;
    const stats = franchiseDetails.stats;
    const {
      franchisesHealth,
      t,
      franchisesDetails,
      hideArrows,
      displayFranchiseIndex,
    } = this.props;
    const customerRating = franchiseDetails.aggregateRating;
    return (
      <>
        {this.renderSingleFranchiseHeader(isFranchiseOpen, franchiseName)}
        {/* <ScoreAndRating>
          <ArcadeQualityScoreDiv>
            <ArcadeTitle>
              {t("dashboard:dashboardModule.qualityScoreHeading")}
            </ArcadeTitle>
            <ScoreInPercentage
              color="rgb(255,171,0)"
              level={arcadeScore.current}
              total={arcadeScore.total}
            />
          </ArcadeQualityScoreDiv>
          <CustomerRating customerRating={customerRating} />
        </ScoreAndRating> */}
        <TodayStatus>
          <TodayStatusText>
            {t("dashboard:dashboardModule.todayStatus")}
          </TodayStatusText>
          <TodaysStatusContent>
            {stats.map((eachItem) => {
              return (
                <ImageTitleAndNumber
                  imageSRC={eachItem.image}
                  size={40}
                  text={eachItem.key}
                  number={eachItem.value}
                />
              );
            })}
          </TodaysStatusContent>
        </TodayStatus>
        <TodaysQuality>
          <QualityText>
            {t("dashboard:dashboardModule.qualityChecklistHeading")}
          </QualityText>
          <ScoreInPercentage
            color="rgb(59,173,106)"
            level={qualityCheck.current}
            total={qualityCheck.total}
          />
        </TodaysQuality>
        {franchisesDetails.length > 1 && hideArrows ? (
          <FranchiseCountContainer>
            {displayFranchiseIndex}/{franchisesDetails.length - 1}
          </FranchiseCountContainer>
        ) : null}
      </>
    );
  };

  renderFranchisesDetails = (franchisesDetails) => {
    if (franchisesDetails) {
      if (franchisesDetails.length === 1) {
        return <>{this.renderSingleFranchiseDetails(franchisesDetails[0])}</>;
      }
      return (
        <>
          {this.renderSingleFranchiseDetails(
            franchisesDetails[this.props.displayFranchiseIndex]
          )}
        </>
      );
    }
    return null;
  };

  render() {
    const { franchisesDetails } = this.props;
    return (
      <DashboardSummaryItemCardContainer>
        {this.renderFranchisesDetails(franchisesDetails)}
      </DashboardSummaryItemCardContainer>
    );
  }
}

export default withTranslation()(FranchiseDetailsSummaryCard);

// import DashboardSummaryItemTitleWithIcon from '../DashboardSummaryItemTitleWithIcon'
// import DashboardFranchiseNameWithIcon from '../DashboardFranchiseNameWithIcon'
// import SingleFranchiseDetails from '../SingleFranchiseDetails'

// import type FranchiseDetail from '../../stores/models/FranchiseDetail'
// import type FranchiseHealth from '../../stores/models/FranchiseHealth'
// import Images from '../../themes/Images'
// import { observable } from 'mobx'
// import I18n from 'react-native-i18n'

// import type { IObservableArray } from 'mobx'

// import Colors from '../../../Common/themes/Colors'
// import Next from '../../../Common/themes/icons/Next'
// import Previous from '../../../Common/themes/icons/Previous'

// import { gotoFranchiseHomeScene } from '../../../FranchiseManagement/utils/NavigationUtils'
// import {
//   FranchiseDetailsContainer,
//   DashboardFranchiseStatus,
//   FranchiseStatus,
//   ActiveArrowContainer,
//   InActiveArrowContainer,
//   FranchiseNameWithPreviousArrow,
//   FranchiseStatusWithNextArrow,
//   DisplayFranchiseCount,
//   MultipleFranchiseContentContainer,
//   MultipleFranchisesHeaderContainer,
//   MultipleFranchisesHeader
// } from './styledComponents'
// import styles from './styles'

// type FranchiseDetailsSummaryCardProps = {
//   franchisesDetails: IObservableArray<FranchiseDetail>,
//   displayFranchiseIndex: number,
//   changeDisplayFranchiseIndex: () => void,
//   franchisesHealth: IObservableArray<FranchiseHealth>
// }

// @observable displayLoader: boolean = false

// navigateToFranchiseManagement = () => {
//   gotoFranchiseHomeScene()
// }

// loaderTimeOut = () => {
//   setTimeout(() => {
//     this.displayLoader = false
//   }, 1000)
// }

// renderDisplayFranchiseNumber = () => {
//   const { displayFranchiseIndex, franchisesDetails } = this.props
//   const franchisesCount = franchisesDetails.length
//   const currentFranchiseNumber = displayFranchiseIndex + 1
//   return (
//     <DisplayFranchiseCount>
//       ({currentFranchiseNumber}/{franchisesCount})
//     </DisplayFranchiseCount>
//   )
// }

// decreaseDisplayFranchiseIndex = () => {
//   const { displayFranchiseIndex, changeDisplayFranchiseIndex } = this.props
//   this.displayLoader = true
//   this.loaderTimeOut()
//   changeDisplayFranchiseIndex(displayFranchiseIndex - 1)
// }

// increaseDisplayFranchiseIndex = () => {
//   const { displayFranchiseIndex, changeDisplayFranchiseIndex } = this.props
//   this.displayLoader = true
//   this.loaderTimeOut()
//   changeDisplayFranchiseIndex(displayFranchiseIndex + 1)
// }

// displayRightArrow = () => {
//   const { displayFranchiseIndex } = this.props
//   if (displayFranchiseIndex < this.props.franchisesDetails.length - 1) {
//     return (
//       <ActiveArrowContainer
//         style={styles.rightArrowStyles}
//         onPress={this.increaseDisplayFranchiseIndex}
//       >
//         <Next />
//       </ActiveArrowContainer>
//     )
//   }
//   return (
//     <InActiveArrowContainer style={styles.rightArrowStyles}>
//       <Next color={Colors.silverTwo} />
//     </InActiveArrowContainer>
//   )
// }

// displayLeftArrow = () => {
//   const { displayFranchiseIndex } = this.props
//   if (displayFranchiseIndex > 0) {
//     return (
//       <ActiveArrowContainer
//         style={styles.leftArrowStyles}
//         onPress={this.decreaseDisplayFranchiseIndex}
//       >
//         <Previous />
//       </ActiveArrowContainer>
//     )
//   }
//   return (
//     <InActiveArrowContainer style={styles.leftArrowStyles}>
//       <Previous color={Colors.silverTwo} />
//     </InActiveArrowContainer>
//   )
// }

// renderMultipleFranchiseHeader = (
//   franchiseName: string,
//   isFranchiseOpen: boolean
// ) => (
//   <MultipleFranchisesHeaderContainer>
//     <MultipleFranchisesHeader>
//       <FranchiseNameWithPreviousArrow>
//         {this.displayLeftArrow()}
//         <DashboardFranchiseNameWithIcon
//           source={Images.centerManagement}
//           title={franchiseName}
//         />
//       </FranchiseNameWithPreviousArrow>
//       <FranchiseStatusWithNextArrow>
//         <FranchiseStatus
//           source={
//             isFranchiseOpen ? Images.openFranchise : Images.closedFranchise
//           }
//         />
//         {this.displayRightArrow()}
//       </FranchiseStatusWithNextArrow>
//     </MultipleFranchisesHeader>
//   </MultipleFranchisesHeaderContainer>
// )

// renderMultipleFranchisesDetails = (
//   franchisesDetails: IObservableArray<FranchiseDetail>
// ) => {
//   const { displayFranchiseIndex, franchisesHealth } = this.props
//   const displayFranchise = franchisesDetails[displayFranchiseIndex]
//   const isFranchiseOpen = displayFranchise.isOpen
//   const franchiseName = displayFranchise.franchise.name
//   const franchiseHealth = franchisesHealth[displayFranchiseIndex]
//   return (
//     <FranchiseDetailsContainer>
//       {this.renderMultipleFranchiseHeader(franchiseName, isFranchiseOpen)}
//       <MultipleFranchiseContentContainer>
//         <SingleFranchiseDetails
//           franchiseDetails={displayFranchise}
//           partOfMultipleFranchises
//           franchiseHealth={franchiseHealth}
//         />
//         {this.renderDisplayFranchiseNumber()}
//       </MultipleFranchiseContentContainer>
//     </FranchiseDetailsContainer>
//   )
// }

// renderSingleFranchiseHeader = (isFranchiseOpen: boolean) => (
//   <DashboardFranchiseStatus>
//     <DashboardSummaryItemTitleWithIcon
//       source={Images.centerManagement}
//       title={I18n.t('dashboardModule.dashboardFranchiseManagement')}
//     />
//     <FranchiseStatus
//       source={isFranchiseOpen ? Images.openFranchise : Images.closedFranchise}
//     />
//   </DashboardFranchiseStatus>
// )

// renderSingleFranchiseDetails = (franchiseDetails: FranchiseDetail) => {
//   const isFranchiseOpen = franchiseDetails.isOpen
//   const { franchisesHealth } = this.props
//   return (
//     <FranchiseDetailsContainer>
//       {this.renderSingleFranchiseHeader(isFranchiseOpen)}
//       <SingleFranchiseDetails
//         franchiseDetails={franchiseDetails}
//         partOfMultipleFranchises={false}
//         franchiseHealth={franchisesHealth[0]}
//       />
//     </FranchiseDetailsContainer>
//   )
// }

// renderFranchisesDetails = (
//   franchisesDetails: IObservableArray<FranchiseDetail>
// ) => {
//   if (franchisesDetails.length === 1) {
//     return (
//       <DashboardSummaryItemContentContainer>
//         {this.renderSingleFranchiseDetails(franchisesDetails[0])}
//       </DashboardSummaryItemContentContainer>
//     )
//   }
//   return this.renderMultipleFranchisesDetails(franchisesDetails)
// }

/**
 * @flow
 * @author: Dinesh
 * */

import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'

import { type ZendeskConfig as Zendesk } from '../../UserProfile/type'

class ZendeskConfig {
  @persist
  @observable
  department: string
  @persist
  @observable
  email: string

  @action.bound
  updateDetails(zendeskConfig: Zendesk) {
    this.department = zendeskConfig.department_name
    this.email = zendeskConfig.email
  }
}

export default ZendeskConfig

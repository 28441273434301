/*
* @flow
* @author:Dinesh
*/
import { observable, action, computed } from 'mobx'
import moment from 'moment'
import type { APIDateItem } from '../../../type'
import { CALENDAR_FORMAT } from '../../../../constants/AppConstants'

class DateFilter {
  @observable fromDate: string
  @observable toDate: string
  @observable dateFilterIndex: number

  constructor(dateFilter: APIDateItem = { from_date: '', to_date: '' }) {
    this.fromDate = dateFilter.from_date
    this.toDate = dateFilter.to_date
    this.dateFilterIndex = -1
  }

  @action.bound
  setFromDate(fromDate: string) {
    this.fromDate = fromDate
  }

  @action.bound
  setToDate(toDate: string) {
    this.toDate = toDate
  }

  @action.bound
  setDateFilterIndex(index: number) {
    this.dateFilterIndex = index
  }

  @computed
  get isReset(): boolean {
    return this.fromDate === '' || this.toDate === ''
  }

  @action.bound
  reset() {
    this.setFromDate('')
    this.setToDate('')
    this.setDateFilterIndex(-1)
  }

  @computed
  get requestData(): APIDateItem {
    return {
      from_date: this.fromDate,
      to_date: this.toDate
    }
  }

  @computed
  get noOfDaysBetween(): number {
    if (this.isReset) {
      return 1000
    }
    const a = moment(this.fromDate)
    const b = moment(this.toDate)
    return b.diff(a, 'days')
  }

  @computed
  get formattedFromDate(): string {
    if (this.fromDate) {
      return moment(this.fromDate).format(CALENDAR_FORMAT)
    }
    return moment().format(CALENDAR_FORMAT)
  }

  @computed
  get formattedToDate(): string {
    if (this.toDate) {
      return moment(this.toDate).format(CALENDAR_FORMAT)
    }
    return moment().format(CALENDAR_FORMAT)
  }
}
export default DateFilter

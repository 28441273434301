/*
* @flow
* @author : Dinesh
*/

import { observable } from 'mobx'

class GroupBarValue {
  @observable y: number
  @observable marker: string

  constructor(y: number, marker?: string = '') {
    this.y = y
    this.marker = marker
  }
}

export default GroupBarValue

import { toJS } from "mobx";
import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      dominantBaseline="central"
      textAnchor="middle"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function RevenuePieChart(props) {
  return (
    <PieChart width={180} height={180}>
      <Pie
        data={props.revenues.graphData.dataSet}
        cx={80}
        cy={80}
        innerRadius={40}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
        nameKey="legend"
        labelLine={false}
        label={renderCustomizedLabel}
      >
        {props.revenues.graphData.dataSet.map((entry, index) => {
          return (
            <Cell
              key={`cell-${index}`}
              fill={entry.color}
              stroke={entry.color}
            />
          );
        })}
      </Pie>
      <Tooltip />
      <Legend margin={{ top: "20px" }} height={4} layout="horizontal" />
    </PieChart>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";

import { FRANCHISE_LOGIN_SCREEN } from "../../FranchiseLogin/constants/NavigationConstants";
import { getAccessToken } from "../../FranchiseLogin/utils/StorageUtils";

import { FRANCHISE_DASHBOARD_SCREEN } from "../constants/NavigationConstants";

interface RouteParams {
  component: any;
  [x: string]: any;
}

export const ProtectedRoute = ({
  component: Component,
  ...other
}: RouteParams) => {
  const renderComponent = (props: any) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      return <Component key={props.location.pathname} {...props} {...other} />;
    }
    return (
      <Redirect
        to={{
          pathname: FRANCHISE_LOGIN_SCREEN,
          state: { from: props.location },
        }}
      />
    );
  };
  return <Route {...other} render={renderComponent} />;
};

export const ReactRoute = ({ component: Component, ...other }: RouteParams) => {
  const renderComponent = (props: any) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      return (
        <Redirect
          to={{
            pathname: FRANCHISE_DASHBOARD_SCREEN,
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} {...other} />;
  };

  return <Route {...other} render={renderComponent} />;
};

/*
* @flow
* Author: Prasanth
*/

import AuthStore from '../../FranchiseLogin/stores/AuthStore'
import urls from '../../FranchiseLogin/utils/urls'

import { ACCESS_TOKEN_EXPIRED_CODE } from '../constants/ErrorViewConstants'

import { networkCallWithApisauce as networkCall } from './CommonAppAPI'

const networkCallWithApisauce = async (
  url,
  requestObject,
  onSuccess,
  onFailure,
  type,
  loadingFunction
) => {
  let response = await networkCall(
    url,
    requestObject,
    onSuccess,
    onFailure,
    type,
    loadingFunction,
    AuthStore
  )

  if (response) {
    if (response.ok === false) {
      if (
        response.status === ACCESS_TOKEN_EXPIRED_CODE &&
        url !== urls.auth.userLogout
      ) {
        const getAccessTokenResponse = await AuthStore.getNewAccessTokenApi()

        if (!getAccessTokenResponse) {
          AuthStore.userLogOutApi()
        } else {
          response = await networkCall(
            url,
            requestObject,
            onSuccess,
            onFailure,
            type,
            loadingFunction,
            AuthStore
          )
        }
      } else {
        throw Error(JSON.stringify(response.data))
      }
    }
  }
  return response
}
export default networkCallWithApisauce

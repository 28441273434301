/*
 * @flow
 */

import { observable } from 'mobx'
import type { APIQualityChecklistItems } from '../../type.js'

class QualityChecklistItems {
  @observable current: number
  @observable total: number

  constructor(qualityChecklistItems: APIQualityChecklistItems) {
    this.current = qualityChecklistItems.current
    this.total = qualityChecklistItems.total
  }
}
export default QualityChecklistItems

/**
 * @flow
 * Author: Pavan
 */

import styled from "@emotion/styled";
import { NoTransactions } from "../../components/ExpendituresLogTable/styledComponents";
import ReactSelect from "../../components/ReactSelect";

export const DisplayInMobile = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DisplayInTabletAndDesktop = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const ViewInMobileContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f8ff;
`;

export const ViewInMobileText = styled.p`
  font-family: CerebriSans;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #13274b;
  text-align: center;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FranchiseTabBarMainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FranchiseTabBarSubDiv = styled.div`
  flex: 1;
  padding-top: 80px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1120px;
  justify-content: flex-start;
  overflow: auto;
`;

export const AllButton = styled.button`
  padding: 6px 21px 6px 21px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  background-color: ${(props) => (props.isSelected ? "#0d47a1" : "#d2e4ff")};
  color: ${(props) => (props.isSelected ? "white" : "black")};
  font-family: "CerebriSans";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  :focus {
    outline: none;
  }
`;

export const EachButton = styled.button`
  padding: 6px 21px 6px 21px;
  background-color: ${(props) => (props.isSelected ? "#0d47a1" : "#d2e4ff")};
  color: ${(props) => (props.isSelected ? "white" : "black")};
  font-family: "CerebriSans";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  :focus {
    outline: none;
  }
`;

export const MainContainer = styled.div`
  flex: 1;
  padding-top: 40px;
  background-color: #f3f8ff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Subcontainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding-right: 40px;
  padding-left: 40px;
`;

export const WelcomeText = styled.div`
  font-family: "CerebriSans";
  font-size: 18px;
`;
export const OverAllStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

export const RevenueGraphDiv = styled.div`
  width: 49%;
  height: 300px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 40px;
  }
`;

export const RevenueGraphSubDiv = styled.div`
  width: 100%;
  height: 190px;
  background-color: white;
  margin-bottom: 30px;
  border-radius: 3px;
  padding-right: 50px;
`;

export const TotalIconTextAmountCardDiv = styled.div`
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
`;

export const SummaryTabsAndFilters = styled.div`
  display: flex;
  flex-direction: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-top: 46px;
`;

export const SummaryWithChannelsFilterContainer = styled(SummaryTabsAndFilters)`
  margin-bottom: 145px;
`;

export const RevenueGraphContainer = styled.div`
  margin: 0 0 18px;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #dfe3e8;
  font-size: 18px;
`;

export const PaymentSummaryDiv = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const NoTransaction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const GraphDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ReactSelectComponent = styled(ReactSelect)`
  width: 140px;
`;

export const ReactSelectAndDatePickerDiv = styled.div`
  display: flex;
`;
export const ReactSelectButtonDiv = styled.div`
  margin-right: 10px;
`;

import * as React from "react";
import ReactLoader from "react-loader-spinner";

import colors from "../../themes/Colors";

class Loader extends React.Component {
  static defaultProps = {
    type: "TailSpin",
    color: colors.aang,
    height: 25,
    width: 25,
  };

  render() {
    const { height, width, type, color, ...other } = this.props;
    return (
      <ReactLoader
        type={type}
        color={color}
        height={height}
        width={width}
        {...other}
      />
    );
  }
}

export default Loader;

import { observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import onClickOutside from "react-onclickoutside";

import { clearUserSession } from "../../../FranchiseLogin/utils/StorageUtils";
import { FRANCHISE_DASHBOARD_SCREEN } from "../../constants/NavigationConstants";
import GenericAvatar from "../../Icons/GenericAvatar";

import {
  HeaderDiv,
  TopBar,
  DashboardHeaderDiv,
  BackWrapperImg,
  IbcLogoImage,
  DashboardHeaderText,
  HeaderNotify,
  SignOut,
  ProfileContainer,
  SignOutButton,
  UserName,
} from "./styledComponents";

@observer
class Profile extends Component {
  @observable showSignOut = false;

  onToggle = () => {
    this.showSignOut = !this.showSignOut;
  };

  signOut = () => {
    clearUserSession();
    window.localStorage.clear();
    location.reload();
  };

  handleClickOutside = () => {
    this.showSignOut = false;
  };

  render() {
    const { username } = this.props;
    return (
      <HeaderNotify>
        <SignOut>
          <ProfileContainer onClick={this.onToggle}>
            <GenericAvatar width={35} height={35} />
            <UserName>{username}</UserName>
          </ProfileContainer>
          {this.showSignOut ? (
            <SignOutButton onClick={this.signOut}>SignOut</SignOutButton>
          ) : null}
        </SignOut>
      </HeaderNotify>
    );
  }
}

export default onClickOutside(Profile);

import React, { Component } from 'react';
import { NoData, NoDataImage, NoDataText, NoDataSpan } from './styledComponents'
import NoFilterIcon from '../../../Common/Icons/NoFilterIcon'

class NoDataView extends Component {
    static defaultProps = {
        text:'No Data',
        spanText:''
    }
    render() {
        const {text, spanText} = this.props
        return (
            <NoData>
                <NoFilterIcon />
                <NoDataText>{text}</NoDataText>
                <NoDataSpan>{spanText}</NoDataSpan>
            </NoData>
        );
    }
}

export default NoDataView;
/*
 * @flow
 * @Author: Chinmaya
 */

import styled from "@emotion/styled";
import Colors from "../../themes/Colors"; // eslint-disable-line

export const PageItem = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  margin-top: 15px;
`;
export const SubContainer = styled.div`
  display: flex;
  box-shadow:${(props) => (props.pagesCount > 1) ? '0px 1px 2px 1px rgba(64, 80, 109, 0.32);': 'none'};
  border:${(props) => (props.pagesCount > 1) ? '1px solid #ebedf0': 'none'};
  border-radius:4px;
`;
export const NavigationImg = styled.img``;

/**
 * @flow
 * @author: @ib/Chinmaya
 */

import styled from "@emotion/styled";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* width: 45%; */
`;

export const Title = styled.p`
  font-family: "CerebriSans";
  font-weight: 400;
  font-size: 20px;
  margin-left: 5px;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/*
 *
 * @flow
 * Author: Prasanth
 */

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import APIConstants from "@ib/api-constants";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { action, reaction, computed, observable } from "mobx";
import { observer, inject } from "mobx-react";

import type { AuthStore } from "../../stores/AuthStore";
import type { UserProfile } from "../../stores/UserProfile";

import { validatePhone } from "../../../Common/utils/validations";
import { FRANCHISE_OTP_SCREEN } from "../../../constants/RouteConstants";
import CommonButton from "../../../Common/components/CommonButton/Button";
import Loader from "../../../Common/Icons/Loader";

import {
  SignInPage,
  Logo,
  LoginForm,
  LogoText,
  LoginPageDiv,
  InputLabel,
  Input,
  LoginButton,
  ErrorMessageSpan,
} from "./styledComponents";
@inject("authStore", "userProfile")
@observer
class LoginUsernameScreen extends Component<{
  authStore: AuthStore,
  userProfile: UserProfile,
}> {
  @observable displayError = false;
  @observable errorText = "";

  componentWillUnmount() {
    if (this.navigateReaction) {
      this.navigateReaction();
      this.navigateReaction = null;
    }
    if (this.serverLoginErrorReaction) {
      this.serverLoginErrorReaction();
      this.serverLoginErrorReaction = null;
    }
  }
  navigateReaction = reaction(
    () => this.props.authStore.userLoginStatus,
    (userLoginStatus) => {
      if (userLoginStatus === APIConstants.API_SUCCESS) {
        this.props.history.push(FRANCHISE_OTP_SCREEN);
      }
    }
  );

  serverLoginErrorReaction = reaction(
    () => this.props.authStore.userLoginAPIError,
    (userLoginAPIError) => {
      if (userLoginAPIError === "") {
        this.setDisplayError(false);
      } else {
        this.setDisplayError(true);
        this.setErrorText(userLoginAPIError);
      }
    }
  );

  onChangeText = action((e) => {
    this.setDisplayError(false);
    this.props.userProfile.setPhoneNumber(e.target.value);
  });

  setDisplayError = action((bool) => {
    this.displayError = bool;
  });

  setErrorText = action((error) => {
    this.errorText = error;
  });

  callLoginAPI = (e) => {
    e.preventDefault();
    const { authStore, userProfile } = this.props;
    const validationResponse = validatePhone(userProfile.phoneNumber);
    if (validationResponse.displayError) {
      this.setDisplayError(true);
      this.setErrorText(validationResponse.errorMsg);
    } else {
      this.setDisplayError(false);
      authStore.userLogin();
    }
  };

  render() {
    const { t, authStore, userProfile } = this.props;
    const { userLoginStatus } = authStore;
    const shouldDisplayLoader = userLoginStatus === APIConstants.API_FETCHING;
    return (
      <SignInPage>
        <LoginPageDiv>
          <Logo src="/images/i-bc-nav-icon.png" />
          <LogoText>
            {t("franchiseLogin:franchiseLogin.usernameHeading")}
          </LogoText>
          <LoginForm onSubmit={this.callLoginAPI}>
            <InputLabel>
              {t("franchiseLogin:franchiseLogin.usernameSubHeading")}
            </InputLabel>
            <Input
              isErrorPresent={this.displayError}
              placeholder={t(
                "franchiseLogin:franchiseLogin.usernamePlaceholder"
              )}
              type="number"
              onChange={this.onChangeText}
              value={
                userProfile.phoneNumber && userProfile.phoneNumber.length > 0
                  ? userProfile.phoneNumber
                  : ""
              }
            />
            <ErrorMessageSpan displayError={this.displayError}>
              {this.displayError ? this.errorText : "error"}
            </ErrorMessageSpan>

            <LoginButton type="submit">
              {shouldDisplayLoader ? (
                <Loader color="white" height={25} width={25} />
              ) : (
                t("franchiseLogin:franchiseLogin.usernameButtonText")
              )}
            </LoginButton>
          </LoginForm>
        </LoginPageDiv>
      </SignInPage>
    );
  }
}

export default withRouter(withTranslation()(LoginUsernameScreen));

// import I18n from "react-native-i18n";
// import TextInputField from "../../../Common/components/TextInputField";
// import LoginBlackThemeBackground from "../../styledComponents/View/LoginBlackThemeBackground";
// import {
//   Button,
//   DisplayBook24W,
// } from "../../../Common/styledComponents/Text/StyledTexts";
// import ButtonWithLoader from "../../../Common/components/ButtonWithLoader/ButtonWithLoader";
// import ButtonConstants from "../../../Common/styledComponents/Button/constants";

// import Colors from "../../../Common/themes/Colors";
// import Images from "../../../Common/themes/Images";
// import { MOBILE_NUMBER_MAX_LENGTH } from "../../constants/AppConstants";
// import { gotoLoginOTPScreen } from "../../utils/LoginNavigationUtils";

// @computed
// get animating() {
//   const { userLoginStatus } = this.props.authStore
//   if (userLoginStatus === APIConstants.API_FETCHING) {
//     return true
//   }
//   return false
// }

// requestAnimationFrame(() => {
// })

// import styles from "./styles";

// const ButtonWhiteText = styled(Button)`
//   color: ${Colors.white};
// `;

import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "mobx-react";
import { I18nextProvider, withTranslation } from "react-i18next";

import {
  FRANCHISE_OTP_SCREEN,
  FRANCHISE_DASHBOARD_SCREEN,
  REVENUE_SUMMARY_SCREEN,
  FRANCHISE_LOGIN_SCREEN,
} from "./constants/RouteConstants";

import stores from "./Common/stores";
import i18n from "./Common/i18n";
import "./Common/i18n";

import "./App.css";
import "./fonts.css";
import LoginUsernameScreen from "./FranchiseLogin/containers/LoginUsernameScreen";
import LoginOtpScreen from "./FranchiseLogin/containers/LoginOtpScreen";
import DashboardContainer from "./Dashboard/containers/DashboardContainer";
import RevenueSummaryWithFranchisesTabbar from "./FranchiseRevenueManagement/containers/RevenueSummaryWithFranchisesTabbar";
import { ProtectedRoute, ReactRoute } from "./Common/RouteUtils";
import LoadingView from "./Common/components/LoadingView";

//TODO: Seperate Routes based on modules

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="suspense-loading-view">
          <LoadingView />
        </div>
      }
    >
      <Provider {...stores}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Switch>
              <ReactRoute
                exact
                path={FRANCHISE_OTP_SCREEN}
                component={LoginOtpScreen}
              ></ReactRoute>
              {/* <ProtectedRoute
                exact
                path={FRANCHISE_DASHBOARD_SCREEN}
                component={DashboardContainer}
              ></ProtectedRoute> */}
              <ProtectedRoute
                exact
                path={FRANCHISE_DASHBOARD_SCREEN}
                component={RevenueSummaryWithFranchisesTabbar}
              ></ProtectedRoute>
              <ReactRoute
                exact
                path={FRANCHISE_LOGIN_SCREEN}
                component={LoginUsernameScreen}
              />
            </Switch>
          </Router>
        </I18nextProvider>
      </Provider>
    </Suspense>
  );
};

export default withTranslation()(App);

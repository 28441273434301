/*
 * @flow
 * @author: Mani
 */

import { action, observable } from "mobx";
import { API_SUCCESS } from "../../../../../Common/constants/AppAPIConstants";
import InflowVsOutflowGraph from "../InflowVsOutflowGraph";
import ExpenditureGraph from "../ExpenditureGraph";
import RevenueGraph from "../RevenueGraph";
import type {
  APIInflowVsOutFlowGraphData,
  APIEntityRevenueDetails,
} from "../../../type";
import type { APIBaseGraphData } from "../BaseGraph/type";

import BasicFilters from "../BasicFilters";
import { Services } from "../../../../services";
//

class GraphsAndAnalytics {
  // graphs related data for v2
  @observable inflowVsOutFlow: InflowVsOutflowGraph;

  @observable expenditures: ExpenditureGraph;
  @observable revenues: RevenueGraph;

  @observable basicFilters: BasicFilters;
  services: Services;

  constructor(
    entityId: number,
    entityType: string,
    basicFilters: BasicFilters,
    services: Services
  ) {
    this.basicFilters = basicFilters;
    this.services = services;
    this.inflowVsOutFlow = new InflowVsOutflowGraph(
      entityId,
      entityType,
      this.basicFilters,
      this.services
    );
    this.expenditures = new ExpenditureGraph(
      entityId,
      entityType,
      this.basicFilters,
      this.services
    );

    this.revenues = new RevenueGraph(
      entityId,
      entityType,
      this.basicFilters,
      this.services
    );
  }
  @action.bound
  setGraphsData(response: APIEntityRevenueDetails) {
    // eslint-disable-next-line camelcase
    const { inflow_vs_outflow, expenditure } = response;
    this.setInflowVsOutFlow(inflow_vs_outflow);
    this.setExpenditure(expenditure);
  }
  @action.bound
  setInflowVsOutFlow(inflowVsOutflow: APIInflowVsOutFlowGraphData) {
    this.inflowVsOutFlow.setGraphSummary(inflowVsOutflow);
    this.inflowVsOutFlow.setGraphSummaryAPIStatus(API_SUCCESS);
  }
  @action.bound
  setExpenditure(expenditure: APIBaseGraphData) {
    this.expenditures.setGraphSummary(expenditure);
    this.expenditures.setGraphSummaryAPIStatus(API_SUCCESS);
  }

  @action.bound
  setRevenue(revenue: APIBaseGraphData) {
    this.revenues.setGraphSummary(revenue);
    this.revenues.setGraphSummaryAPIStatus(API_SUCCESS);
  }
}

export default GraphsAndAnalytics;

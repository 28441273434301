/*
* @flow
* @author : Dinesh
*/

import { observable, action, computed } from 'mobx'
import type { IObservableArray } from 'mobx'
import PieChartValue from './PieChartValue'
import PieDataSet from './PieDataSet'

class PieChartGraphData {
  @observable dataSet: IObservableArray<number | PieChartValue>

  constructor() {
    this.dataSet = observable([])
  }

  @action.bound
  setData(dataSet: IObservableArray<number>) {
    this.dataSet = dataSet
  }

  @computed
  get values(): IObservableArray<number | PieDataSet> {
    return this.dataSet.map((eachValue) => eachValue.value)
  }

  @computed
  get legends(): IObservableArray<string> {
    return this.dataSet.map((eachValue) => eachValue.legend)
  }

  @computed
  get colors(): IObservableArray<string> {
    return this.dataSet.map((eachValue) => eachValue.color)
  }
}

export default PieChartGraphData

import styled from "@emotion/styled";
import Colors from "../../../Common/themes/Colors";
import tw from "tailwind.macro";

export const CustomerRatingDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "CerebriSans";
  font-weight: 400;
`;

export const CustomerRatingTitle = styled.div`
  font-size: 14px;
`;

export const Review = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "CerebriSans";
  font-weight: 400;
`;

export const ReviewRating = styled.div`
  font-size: 20px;
  color: #13274b;
`;

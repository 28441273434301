/*
 * @flow
 * @Author: Chinmaya
 */
import * as React from 'react'
import { observer } from 'mobx-react'
import {
  APIStatus,
  API_FETCHING,
  API_SUCCESS,
  API_FAILED
} from '@ib/api-constants'

import FailureView from './FailureView'
import LoadingView from '../LoadingView'

import { Container } from './styledComponents'

@observer
export class LoadingWrapper extends React.Component {
  static defaultProps = {
    onRetry: () => 0,
    renderLoadingView: () => (
       <div data-testid='loader'>
          <LoadingView />
       </div>
    ),
    renderFailureView: (apiStatus, onRetry, failureText) => (
       <FailureView
          apiStatus={apiStatus}
          onRetry={onRetry}
          failureText={failureText}
       />
    ),
    containerClassName: '',
    containerStyle: {}
 }

  renderFailure = () => {
    const {
      renderFailure: RenderFailure,
      onRetry,
      error,
      errorViewType,
      errorContainerStyle
    } = this.props
    return (
      <RenderFailure
        onRetry={onRetry}
        error={error}
        errorViewType={errorViewType}
        style={errorContainerStyle}
      />
    )
  }

  renderLoader = () => {
    const { loadingStyle, renderLoader: RenderLoader } = this.props
    return <RenderLoader mainViewStyle={loadingStyle} />
  }

  renderChildrenWithContainer = (children: any) => {
    const { containerClassName, containerStyle } = this.props
    return (
       <Container style={containerStyle} className={containerClassName}>
          {children}
       </Container>
    )
  }

  renderContent = () => {
    const {
       renderFailureView,
       renderLoadingView,
       apiStatus,
       apiError,
       onRetry,
       children
    } = this.props
    switch (apiStatus) {
       case API_FETCHING:
          return this.renderChildrenWithContainer(renderLoadingView())
       case API_SUCCESS:
          return children
       case API_FAILED:
          return this.renderChildrenWithContainer(
             renderFailureView(apiStatus, onRetry)
          )
       default:
          return this.renderChildrenWithContainer(renderLoadingView())
    }
  }

  render() {
    return <>{this.renderContent()}</>
  }

}
export default LoadingWrapper




// render() {
//   const { children, ...other } = this.props
//   return (
//     <LoadingWrapperWithFailure
//       {...other}
//       renderFailure={this.renderFailure}
//       renderLoader={this.renderLoader}
//     >
//       {children}
//     </LoadingWrapperWithFailure>
//   )
// }
// import ErrorView from '../ErrorView'

// import {
//   FULL_SCREEN_ERROR,
//   SECTION_ERROR
// } from '../../constants/ErrorViewConstants'
// import type { ErrorObject } from '../ErrorView/BaseErrorView'
// import type { ErrorViewType } from '../../constants/ErrorViewConstants'
// import { observer } from 'mobx-react/native'

// import LoadingWrapperWithFailure from '@rn/commons/app/components/LoadingWrapperWithFailure'

// type Props = $PropertyType<LoadingWrapperWithFailure, 'props'> & {
//   renderFailure?: React.ComponentType,
//   renderLoader?: React.ComponentType,
//   error?: ErrorObject,
//   errorViewType: ErrorViewType,
//   errorContainerStyle?: Object
// }

// const errorViewTypeConstants = {
//   fullScreenError: FULL_SCREEN_ERROR,
//   sectionError: SECTION_ERROR
// }
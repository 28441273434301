/*
 * @flow
 */

import styled from '@emotion/styled'

export const CardView = styled.div`
    display:flex;
    flex-direction:column;
    width: 25%;
    justify-content:flex-start;
    align-items:flex-start;
    margin: 0px 0px 30px 0px;
    padding: 19px 20px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44), 0 -1px 1px 0 rgba(151, 160, 176, 0.12);
    background-color: #f3f8ff;
`

export const SettlementDetails = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    font-family: CerebriSans;
    color: #13274b;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
`

export const SettlementText = styled.div`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #13274b;
`

export const Amount = styled.div` 
  font-size: 24px;
`

export const HelpTextView = styled.div`
  font-family: CerebriSans;
  margin-top: 16;
  justify-content: center;
`
export const HelpText = styled.div`
  text-align: center;
  font-size:10px;
  color: #6a778e;
`

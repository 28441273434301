import styled from "@emotion/styled";
import Colors from "../../themes/Colors";
import tw from "tailwind.macro";

export const ScoreInPercentageContainer = styled.div`
  background-color: rgb(210, 228, 255);
  width: 100%;
  border-radius: 10px;
  font-family: "CerebriSans";
  font-weight: 400;
`;
export const ScoreInPercentageCompletionBg = styled.div`
  position: relative;
  width: ${(props) => props.width}%;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  font-size: 14px;
`;

export const ScoreText = styled.div`
  padding-left: ${(props) => (props.giveSpaceWhenWidthIsZero ? "15px" : 0)};
`;

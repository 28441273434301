import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family: "CerebriSans";
  font-size: 13px;
`;

export const CustomToolTipContainer = styled.div`
  position: relative;
  background: #4f5e79;
  font-family: CerebriSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
  padding: 5px 14px;
  border-radius: 2px;
  /* &::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(79, 94, 121, 0);
    border-top-color: #4f5e79;
    border-width: 6px;
    margin-left: -6px;
  } */
`;

export const LabelText = styled.p`
  text-align: center;
`;

export const LabelTextHeading = styled.p`
  font-size: 10px;
`;

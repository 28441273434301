import styled from '@emotion/styled'
import Colors from '../../../Common/themes/Colors'
import tw from 'tailwind.macro'

export const SettlementIncomeDiv = styled.div`
    font-family:'CerebriSans';
    display:flex;
    background-color: rgb(243,248,255);
    border-radius:4px;
    padding:10px;
    justify-content:space-between;
    align-items:center;
    width:60%;
`
export const AmountDetails = styled.div`
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`

export const AmountText = styled.div`
    font-size:12px;
`
export const Amount = styled.div`
    font-family:PingFangSC-Medium;
    font-size:16px;
`
export const ApproxText = styled.div`
    display:flex;
    width: 50%;
    align-items:center;
    font-size:10px;
    color:rgb(87,109,126);
    font-style:italic;
    padding:10px;
`

import styled from '@emotion/styled'
import Colors from '../../../Common/themes/Colors'
import tw from 'tailwind.macro'

export const MainDiv = styled.div`
    display:flex;
    width:30%;
    align-items:center;
    font-family: 'CerebriSans';
`

export const Logo = styled.img`
    object-fit:contain;
    width: ${(props) => props.size}px;
    height:${(props) => props.size}px;
`

export const SubDiv = styled.div`
    display:flex;
    flex-direction:column;
    margin-left:10px;
`

export const Text = styled.div`
    font-size:12px;
`

export const Number = styled.div`
    font-size:16px;
`
/**
 * @flow
 * @author: @ib/Chinmaya
 */

import React, { Component } from "react";
import { computed, toJS } from "mobx";
import { API_FETCHING } from "@ib/api-constants";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import { onDashboardOpened } from "../../utils/SceneNavigationUtils";
// import NavBarUnreadMessagesCount from "../../../Common/stores/NavBarUnreadMessagesCount";
// import { type NotificationStore } from "../../../Notifications/stores/NotificationStore";
import type DashboardStore from "../../stores/DashboardStore";
import type UIStore from "../../../Common/stores/UIStore";
import { REVENUE_SUMMARY_SCREEN } from "../../../constants/RouteConstants";

import ImageTitleAndNumber from "../../../Common/components/ImageTitleAndNumber/ImageTitleAndNumber";
import CustomerRating from "../../../Common/components/CustomerRating/CustomerRating";
import ArcadeQualityScore from "../../../Common/components/ScoreInPercentage/ScoreInPercentage";
import SettlementIncome from "../../../Common/components/SettlementIncome/SettlementIncome";
import ScoreInPercentage from "../../../Common/components/ScoreInPercentage/ScoreInPercentage";
import RevenueSummaryAreaGraph from "../../components/RevenueSummaryAreaGraph";
import LoadingWrapper from "../../../Common/components/LoadingWrapper";
import RevenueSummaryItemCard from "../../components/RevenueSummaryItemCard";
import FranchiseDetailsSummaryCard from "../../components/FranchiseDetailsSummaryCard";
import DashboardHeader from "../../../Common/components/DashboardHeader/DashboardHeader";
import RevenueSummaryWithFranchisesTabbar from "../../../FranchiseRevenueManagement/containers/RevenueSummaryWithFranchisesTabbar";

import {
  MainDashboard,
  RevenueFranchiseCards,
  Container,
} from "./styledComponents";

type DashboardContainerProps = {};

type InjectProps = {
  dashboardStore: DashboardStore,
  uiStore: UIStore,
  userProfile: any,
  // navBarUnreadMessagesCountStore: NavBarUnreadMessagesCount,
  // notificationsStore: NotificationStore
};

@inject(
  "uiStore",
  "userProfile",
  "dashboardStore"
  // "navBarUnreadMessagesCountStore",
  // "notificationsStore"
)
@observer
class DashboardContainer extends Component<
  DashboardContainerProps & InjectProps
> {
  // navigateToCashOuts = () => {
  //   goToCashOutsScreen();
  // };

  async componentDidMount() {
    const { uiStore, dashboardStore, userProfile } = this.props;
    // const { userProfile } = this.props.location.state;
    await onDashboardOpened(uiStore, dashboardStore, userProfile);
  }

  @computed
  get isFetchingDashboardDetails() {
    const { dashboardStore } = this.props;
    const { dashboardStatus, surveillanceStatus } = dashboardStore;
    return (
      dashboardStatus === API_FETCHING || surveillanceStatus === API_FETCHING
    );
  }

  //TODO: Need to write test cases for this function.
  returnAnArrayOfObjects = (values) => {
    const dataXValues = [];
    const dataYValues = [];
    const data = [];
    {
      values.xValues.length > 0 &&
        values.xValues.map((eachItem) =>
          dataXValues.push({
            name: eachItem,
          })
        );
      values.data.length > 0 &&
        values.data[0].values.map((eachItem) =>
          dataYValues.push({
            y: eachItem.y,
            marker: eachItem.marker,
          })
        );
      dataXValues.map((eachItem, index) =>
        data.push({
          name: eachItem.name,
          y: dataYValues[index].y,
          marker: dataYValues[index].marker,
        })
      );
    }
    return data;
  };

  render() {
    const { uiStore, userProfile, dashboardStore, ...other } = this.props;
    const {
      revenueData,
      franchisesDetails,
      displayFranchiseIndex,
      changeDisplayFranchiseIndex,
      franchisesHealth,
    } = dashboardStore;
    let graphData;
    if (revenueData != undefined) {
      graphData = this.returnAnArrayOfObjects(revenueData.graphData);
    }
    return (
      <DashboardHeader revenueData={revenueData}>
        <LoadingWrapper
          apiStatus={uiStore.homeStatus}
          apiError={uiStore.homeError}
          onRetry={this.onRetryDashboardDetails}
        >
          <Container>
            <MainDashboard>
              <RevenueFranchiseCards>
                <RevenueSummaryItemCard
                  revenueData={revenueData}
                  navigateToCashOuts={this.navigateToCashOuts}
                  graphData={graphData}
                  hideHeaderAndWelcomeText
                />
                <FranchiseDetailsSummaryCard
                  franchisesDetails={franchisesDetails}
                  displayFranchiseIndex={displayFranchiseIndex}
                  changeDisplayFranchiseIndex={changeDisplayFranchiseIndex}
                  franchisesHealth={franchisesHealth}
                />
              </RevenueFranchiseCards>
            </MainDashboard>
            <RevenueSummaryWithFranchisesTabbar hideHeaderAndWelcomeText />
          </Container>
        </LoadingWrapper>
      </DashboardHeader>
    );
  }
}

export default withRouter(DashboardContainer);

{
  // <div>
  //       <div>Revenue Data: {JSON.stringify(revenueData)}</div>
  //       <br />
  //       <div>franchisesDetails Data: {JSON.stringify(franchisesDetails)}</div>
  //       <br />
  //       <div>
  //         displayFranchiseIndex Data: {JSON.stringify(displayFranchiseIndex)}
  //       </div>
  //       <br />
  //       <div>
  //         franchisesHealth Data: {JSON.stringify(displayFranchiseIndex)}
  //       </div>
  //       <br />
  //       <button
  //         onClick={() => this.props.history.push(REVENUE_SUMMARY_SCREEN)}
  //         style={{
  //           border: "1px solid black",
  //           padding: "10px 15px",
  //           background: "grey",
  //           color: "white",
  //         }}
  //       >
  //         Goto revenue summary
  //       </button>
  //     </div>
  /* <LoadingWrapper
        loadingState={uiStore.homeStatus}
        onRetry={this.onRetryDashboardDetails}
        error={uiStore.homeError}
        errorViewType={LoadingWrapper.errorViewType.fullScreenError}
      >
        <MainContainer>
          <BackgroundImage source={Images.loginBackground} />
          <DashboardContentContainer
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={this.isFetchingDashboardDetails}
                onRefresh={this.onSwipeRefresh}
              />
            }
          >
            <DashboardSummaryItemsContainer>
              <RevenueSummaryItemCard
                revenueData={revenueData}
                navigateToCashOuts={this.navigateToCashOuts}
              />
              <FranchiseDetailsSummaryCard
                franchisesDetails={franchisesDetails}
                displayFranchiseIndex={displayFranchiseIndex}
                changeDisplayFranchiseIndex={changeDisplayFranchiseIndex}
                franchisesHealth={franchisesHealth}
              />
            </DashboardSummaryItemsContainer>
            <NavigationOptions {...other} />
            {/* <SurveillanceVideos
              dashboardStore={dashboardStore}
              userProfile={userProfile}
            /> /}
          </DashboardContentContainer>
        </MainContainer>
      </LoadingWrapper> */
}

// import LoadingWrapper from "../../../Common/components/LoadingWrapper";
// import RefreshControl from "../../../Common/components/RefreshControl";
// import Images from "../../../Common/themes/Images";

// import NavigationOptions from "../../components/NavigationOptions";
// import RevenueSummaryItemCard from "../../components/RevenueSummaryItemCard";
// import { goToCashOutsScreen } from "../../../CashOuts/utils/NavigationUtils";
// import FranchiseDetailsSummaryCard from "../../components/FranchiseDetailsSummaryCard";
// import SurveillanceVideos from "../../components/SurveillanceVideos";

// onRetryDashboardDetails = () => {
//   const { dashboardStore, uiStore, userProfile } = this.props;
//   onDashboardOpened(uiStore, dashboardStore, userProfile);
// };

// onSwipeRefresh = () => {
//   const { dashboardStore, notificationsStore } = this.props;
//   dashboardStore.getDashboardDetails().catch((e) => {
//     dashboardStore.setDashboardAPIError(e);
//   });
//   dashboardStore.getSurveillanceVideos().catch((e) => {
//     dashboardStore.setSurveillanceAPIError(e);
//   });
//   notificationsStore.fetchUnreadNotificationCountAPI();
// };

import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
    <svg width={width} height={height} viewBox="0 0 15 16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-3-1h20v20H-3z" />
        <g
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(1 1)"
        >
          <rect width={12.6} height={12.6} y={1.4} rx={2} />
          <path d="M9.1 0v2.8M3.5 0v2.8M0 5.6h12.6" />
        </g>
      </g>
    </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 15,
   height: 16,
   fill: Colors.white
}
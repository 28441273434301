/**
 * @flow
 * @author: Dinesh
 * */

import revenueService, { type RevenueStoreService } from './RevenueStore'
import revenueStoreFixtureService from './RevenueStore/index.fixture'

export const serviceConfig = {
  useRevenueStoreFixtures: false
}

export type ServiceConfig = {
  useRevenueStoreFixtures: boolean
}

class Services {
  revenueStoreService: RevenueStoreService

  constructor(config: ServiceConfig = serviceConfig) {
    this.revenueStoreService = config.useRevenueStoreFixtures
      ? revenueStoreFixtureService
      : revenueService
  }
}

const services = new Services()
export { services as default, Services }

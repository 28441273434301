/**
 * @flow
 * @author: Prasanth
 */

import { observable } from 'mobx'
import type { APIUserDetails } from '../../../type'

class User {
  @observable userId: number
  @observable name: string

  constructor(user: APIUserDetails) {
    this.userId = user.user_id
    this.name = user.name
  }
}

export default User

import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f8ff;
  height: 100vh;
  width: 100%;
`;

export const FailureViewImage = styled.img`
  object-fit: contain;
`;

export const FailureText = styled.p`
  font-family: CerebriSans;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #13274b;
  text-align: center;
  margin-top: 96px;
`;

export const FailureSupportText = styled.p`
  font-family: CerebriSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #13274b;
  text-align: center;
`;

export const TryAgainButton = styled.button`
  padding: 8px 18px 8px 19px;
  border-radius: 3px;
  background-color: #004acd;
  margin-top: 24px;
  font-family: CerebriSans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  outline: none;
  &:active,
  &:focus {
    outline: none;
  }
`;

/*
 * @flow
 * @author : Dinesh
 */

import { observable, action } from "mobx";
import { persist } from "mobx-persist";
// import SocialStore from '../../../../Social/stores/SocialStore'
// import SocialApi from '../../../../Social/services/SocialService/index'
import type { APILoginFranchiseDetail } from "../../UserProfile/type";

class LoginFranchiseDetails {
  @persist
  @observable
  franchiseId: string;
  @persist
  @observable
  franchiseName: string;
  @persist("list")
  @observable
  designations: Array<string>;

  // social: SocialStore;

  constructor() {
    // this.social = new SocialStore(new SocialApi(), this);
  }

  @action.bound
  setFranchiseDetails(franchiseDetails: APILoginFranchiseDetail) {
    this.franchiseId = franchiseDetails.franchise_id;
    this.franchiseName = franchiseDetails.name;
    this.setDesignations(franchiseDetails.designations);
  }

  setSocialStore(socialStore: SocialStore) {
    // this.social = socialStore;
  }

  @action.bound
  setDesignations(designations: Array<string>) {
    this.designations = observable([]);
    if (designations && designations.length > 0) {
      this.designations = designations.map(
        (eachDesignation) => eachDesignation
      );
    }
  }
}

export default LoginFranchiseDetails;

/*
 * @flow
 * @author : Dinesh
 */
import React, { Component } from "react";
// import I18n from 'react-native-i18n'
import { computed } from "mobx";
import { observer } from "mobx-react";
// import DropDown from "../../../Common/components/Dropdown";

export type PeriodFilterItem = {
  id: number,
  name: string,
  enableLimit: number,
};

type Props = {
  noOfDays: number,
  resetEnabled?: boolean,
  onPeriodSelect: Function,
  onPeriodReset?: Function,
};
export const RESET_OPTION_ID = "RESET";
export const DAILY_OPTION_ID = "DAILY";
export const WEEKLY_OPTION_ID = "WEEKLY";
export const MONTHLY_OPTION_ID = "MONTHLY";

class PeriodFilter extends Component<Props> {
  static defaultProps = {
    onPeriodReset: () => {},
    resetEnabled: false,
  };

  componentWillReceiveProps(props) {
    if (this.props.noOfDays !== props.noOfDays) {
      if (this.dropdown) {
        this.dropdown.select(-1);
      }
    }
  }
  @computed
  get defaultOptions() {
    const options = [
      {
        id: DAILY_OPTION_ID,
        name: "I18n.t(franchiseCommon.daily)",
        enableLimit: 1,
      },
      {
        id: WEEKLY_OPTION_ID,
        name: "I18n.t(franchiseCommon.weekly)",
        enableLimit: 7,
      },
      {
        id: MONTHLY_OPTION_ID,
        name: "I18n.t(franchiseCommon.monthly)",
        enableLimit: 30,
      },
    ];
    return options;
  }
  @computed
  get displayOptions() {
    let showOptions = this.defaultOptions;
    if (this.props.resetEnabled === true) {
      showOptions = [
        {
          id: RESET_OPTION_ID,
          name: "I18n.t(franchiseCommon.resetAll)",
          enableLimit: -1,
        },
        ...this.defaultOptions,
      ];
    }
    const computedOptions = showOptions.map((eachOption) => {
      if (eachOption.enableLimit <= this.props.noOfDays) {
        return { disabled: false, ...eachOption };
      }
      return { disabled: true, ...eachOption };
    });
    return computedOptions;
  }

  //TODO: need to add react select
  render() {
    return <div>test</div>;
  }
}

export default observer(PeriodFilter);

{
  /* <DropDown
        options={this.displayOptions}
        ref={(dropdown) => {
          this.dropdown = dropdown;
        }}
        valueKey="name"
        dropdownButtonText={"I18n.t(franchiseCommon.period)"}
        onOptionSelect={this.props.onPeriodSelect}
        resetOptionIndex={this.props.resetEnabled === true ? 0 : -1}
        onResetOptionSelect={this.props.onPeriodReset}
        disabledItemKey="disabled"
        {...this.props}
      /> */
}

/**
 * @flow
 * @author: Anesh Parvatha
 */
/* eslint-disable */
import addHours from "date-fns/add_hours";
import after from "date-fns/is_after";
import withInRange from "date-fns/is_within_range";
import moment from "moment";
// import localization from "moment/locale/hi";
// import localization from "moment/locale/en";
// import localization1 from "moment/locale/te";
// import I18n from 'react-native-i18n'
import { DEFAULT_LANGUAGE_CODE } from "../../FranchiseLogin/constants/AppConstants";
import settingsStore from "../stores/SettingsStore";

export const FORMAT = "MMM DD, hh:mm A";
export const DAY_MONTH_FORMAT = "MMM D";
export const CALENDAR_FORMAT = "YYYY-MM-DD";

export const SERVER_FILTER_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function getDisplayDate(dateString, format = FORMAT) {
  return moment(dateString).locale(DEFAULT_LANGUAGE_CODE).format(format);
}

export function getDisplayDateWithOutVernacular(dateString, format = FORMAT) {
  return moment(dateString).format(format);
}

export function getDisplayDateForDispatchStatus(timeStamp) {
  moment.updateLocale(DEFAULT_LANGUAGE_CODE, {
    calendar: {
      lastDay: "LT, [Yesterday]",
      sameDay: "LT, [Today]",
      nextDay: "LT, [Tomorrow]",
      lastWeek: "LT, [Last] dddd",
      nextWeek: "LT, dddd",
      sameElse: "L",
    },
  });
  const momentInstance = moment(timeStamp);
  momentInstance.locale(settingsStore.appLanguage);
  return momentInstance.calendar();
}

export function getNumberOfHoursForTimeStamps(startTimeStamp, endTimeStamp) {
  const startTime = moment(startTimeStamp);
  const endTime = moment(endTimeStamp);
  const duration = moment.duration(endTime.diff(startTime));
  return duration.asHours();
}

export function getNumberOfHoursFromMomentDates(startDateTime, endDateTime) {
  const duration = moment.duration(endDateTime.diff(startDateTime));
  const numOfHours = duration.asHours();
  return numOfHours;
}

export function addHoursToDate(date: Date | string | number, hours: number) {
  return addHours(date, hours);
}

export function isAfter(date, dateToCompare) {
  return after(date, dateToCompare);
}

export function isWithInRange(date, startDate, endDate) {
  return withInRange(date, startDate, endDate);
}

/** Return today date with YYYY-MM-DD format usually useful for Single date selector default date */
export function getTodayDateInCalendarFormat() {
  return moment().format(CALENDAR_FORMAT);
}

export function todayDate() {
  return moment().endOf("day").format(SERVER_FILTER_DATE_TIME_FORMAT);
}

export function threeMonthsBackDate() {
  return moment()
    .subtract(90, "days")
    .startOf("day")
    .format(SERVER_FILTER_DATE_TIME_FORMAT);
}

export function sevenDaysBackDate() {
  return moment()
    .subtract(6, "days")
    .startOf("day")
    .format(SERVER_FILTER_DATE_TIME_FORMAT);
}

export function thirtyDaysBack() {
  return moment()
    .subtract(30, "days")
    .startOf("day")
    .format(SERVER_FILTER_DATE_TIME_FORMAT);
}

/*
 * @flow
 */

import { observable, computed } from 'mobx'
import CommonMethods from '../../../../../Common/utils/CommonMethods'
import { TRANSACTION_DATE_FORMAT } from '../../../../constants/AppConstants'
import { getDisplayDate } from '../../../../../Common/utils/DateUtils'
import { type APIPaymentSummaryItem } from '../../../type'

class PaymentSummaryItem {
  @observable franchiseId: number
  @observable franchiseName: string

  @observable cashCollected: number
  @observable digitalAmountCollected: number
  @observable date: number

  constructor(item: APIPaymentSummaryItem) {
    this.franchiseId = item.franchise_id
    this.cashCollected = item.cash_collected
    this.digitalAmountCollected = item.digital_amount_collected
    this.date = item.date
    this.franchiseName = item.franchise_name
  }

  @computed
  get paymentDate(): string {
    return getDisplayDate(this.date, TRANSACTION_DATE_FORMAT)
  }

  @computed
  get cashCollectedAmount(): any {
    return CommonMethods.toCurrency(this.cashCollected)
  }

  @computed
  get digitalCollectedAmount(): any {
    return CommonMethods.toCurrency(this.digitalAmountCollected)
  }
}
export default PaymentSummaryItem

/*
 * @flow
 * @author: Chinmaya
 */

import Config from "../../Common/constants/EnvironmentConstants";

const { BASE_URL } = Config;

const DASHBOARD_BASE_URL = `${BASE_URL}api/dashboard/`;
const BASE_FRANCHISE_URL = `${Config.BASE_URL}api/ib_franchise/`;

const urls = {
  getDashboardDetails: `${DASHBOARD_BASE_URL}dashboard/v1/`,
  getSurveillanceVideos: `${BASE_FRANCHISE_URL}cctv/details/v1/`,
};

export default urls;

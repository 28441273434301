import React from "react";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import { useTable, useSortBy } from "react-table";
import moment from "moment";
import { API_FETCHING } from "@ib/api-constants";

import PaginationNavigation from "../../../Common/components/PaginationNavigation";
import LoadingWrapper from "../../../Common/components/LoadingWrapper";
import SortByIcon from "../../../Common/Icons/SortByIcon";
import Loader from "../../../Common/Icons/Loader";

import NoDataView from "../NoDataView/NoDataView";

import {
  PaymentSummaryTableDiv,
  PaymentSummaryHeader,
  PaymentSummaryTableDetails,
  RenderHeaderDiv,
  SortBySpan,
  Container,
  NoTransaction,
  LoaderViewWrapper,
} from "./styledComponents";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const renderHeader = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <RenderHeaderDiv>
                  {column.render("Header")}
                  <SortBySpan>
                    {column.disableSortBy ? "" : <SortByIcon />}
                  </SortBySpan>
                </RenderHeaderDiv>
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const renderBody = () => {
    return (
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <PaymentSummaryTableDiv>
      {/* <PaymentSummaryHeader>Transaction Logs</PaymentSummaryHeader> */}
      <PaymentSummaryTableDetails>
        <table {...getTableProps()}>
          {renderHeader()}
          {renderBody()}
        </table>
      </PaymentSummaryTableDetails>
    </PaymentSummaryTableDiv>
  );
}

function TransactionsLogsTable(props) {
  const {
    loadingStatus,
    transactions,
    totalPagesCount,
    currentPage,
    onPagePress,
    error,
    onRetry,
    t,
  } = props;

  const columns = React.useMemo(
    () => [
      {
        Header: t("revenueManagement:revenueModule.franchise"),
        accessor: "franchiseName",
      },
      {
        Header: t("revenueManagement:revenueModule.channel"),
        accessor: "channelName",
      },
      {
        Header: t("revenueManagement:revenueModule.invoiceAmount"),
        accessor: "amount",
      },
      {
        Header: t("revenueManagement:revenueModule.settlementAmount"),
        accessor: "displayOwnerAmount",
      },
      {
        Header: t("revenueManagement:revenueModule.dateTime"),
        accessor: "dateTime",
        Cell: (row) => {
          const date_and_time = moment(new Date(row.value)).format(
            "Do MMM YYYY hh:mm A"
          );
          return <span>{`${date_and_time}`}</span>;
        },
      },
      {
        Header: t("revenueManagement:revenueModule.paymentMode"),
        accessor: "paymentMode",
      },
    ],
    []
  );

  return (
    <Container>
      <Table columns={columns} data={transactions} />
      {loadingStatus === API_FETCHING && transactions.length === 0 ? (
        <LoaderViewWrapper>
          <Loader width={40} height={40} />
        </LoaderViewWrapper>
      ) : transactions.length === 0 ? (
        <NoTransaction>
          <NoDataView
            text={t("revenueManagement:revenueModule.noTransactions")}
            spanText={t("revenueManagement:revenueModule.removeFilters")}
          />
        </NoTransaction>
      ) : null}
      <PaginationNavigation
        totalPagesCount={totalPagesCount}
        currentPage={currentPage}
        maxDisplayPagesCount={9}
        onPagePress={onPagePress}
      />
    </Container>
  );
}

export default withTranslation()(TransactionsLogsTable);

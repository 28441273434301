/*
 * @flow
 */

import { observable } from 'mobx'
import { NOT_UPDATED } from '../../../constants/FranchiseHealthConstants.js'
import type { APICheckListItem, HealthStatus } from '../../type.js'

class CheckListItem {
  @observable percentage: string
  @observable name: string
  @observable healthStatus: HealthStatus

  constructor(checkListItem: APICheckListItem) {
    this.percentage =
      checkListItem.health_status === NOT_UPDATED
        ? '-'
        : `${checkListItem.percentage.toString()}%`
    this.name = checkListItem.name
    this.healthStatus = checkListItem.health_status
  }
}
export default CheckListItem

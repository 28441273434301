/*
 * @flow
 */

// import { NetInfo, Platform } from 'react-native'
import { create } from "apisauce";

import { API_FETCHING, API_SUCCESS } from "../constants/AppAPIConstants";

import type { APIStatus } from "../constants/AppAPIConstants";

// export const checkNetwork = () => {
//   if (Platform.OS === "ios") {
//     return new Promise((resolve) => {
//       const handleFirstConnectivityChange = (isConnected) => {
//         NetInfo.isConnected.removeEventListener(
//           "connectionChange",
//           handleFirstConnectivityChange
//         );
//         resolve(isConnected);
//       };
//       NetInfo.isConnected.addEventListener(
//         "connectionChange",
//         handleFirstConnectivityChange
//       );
//     });
//   }
//   return NetInfo.isConnected.fetch();
// };

export async function networkCallWithApisauce<T>(
  url: string,
  requestObject: Object,
  onSuccess: (response: T) => mixed = () => {}, // eslint-disable-line
  onFailure: (response: any) => mixed = () => {}, // eslint-disable-line
  type: string = "POST",
  loadingFunction: (response: APIStatus) => mixed = () => {},
  AuthStore
): Promise<T> {
  const isNetworkConnected: boolean = true;
  if (true) {
    console.log(
      "In networkCallWithApisauce isNetworkConnected: ",
      isNetworkConnected
    );
  }
  if (isNetworkConnected === true) {
    const { accessToken, source, languageCode } = AuthStore;

    if (true) {
      console.log("setLanguageAppAPI", "en");
    }
    const api = create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
        "x-source": source,
        "Accept-Language": `en-US`,
      },
    });
    if (true) {
      console.log("In networkCallWithApisauce accessToken: ", accessToken);
    }

    let response;
    const formattedrequestObject: string = JSON.stringify(
      JSON.stringify(requestObject)
    );
    const dataRequestObject: string = JSON.stringify({
      data: formattedrequestObject,
      clientKeyDetailsId: 1,
    });
    switch (type) {
      case "POST":
        loadingFunction(API_FETCHING);
        response = await api.post("", dataRequestObject);
        if (true) {
          console.log("In networkCallWithApisauce response: ", response);
        }
        if (response.ok === false) {
          return response;
          // throw Error(JSON.stringify(response.data));
        }
        loadingFunction(API_SUCCESS);
        return response.data;
      case "GET":
        response = await api.get("");
        if (true) {
          console.log("In networkCallWithApisauce response: ", response);
        }
        if (response.ok === false) {
          return response;
          // throw Error(JSON.stringify(response.data))
        }
        return response.data;
      default:
    }
  } else {
    throw Error(
      JSON.stringify({ res_status: "", response: "", http_status_code: 503 })
    );
    // loadingFunction(API_FAILED)
  }
  return null;
}

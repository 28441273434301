/**
 * @flow
 * @author: Prasanth
 */

import { observable } from 'mobx'
import type { APIChannel } from '../../../type'

class Channel {
  @observable channelId: number
  @observable name: string

  constructor(channel: APIChannel) {
    this.channelId = channel.channel_id
    this.name = channel.name
  }
}

export default Channel

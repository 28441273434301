/*
 * @flow
 * Author: Prasanth
 */

// import { AsyncStorage } from 'react-native'

import { observable, action } from "mobx";
import { APIStatus, API_INITIAL } from "@ib/api-constants";

import { bindPromiseWithOnSuccess } from "../../../Common/utils/MobxPromise";

// import Mqtt from '@rn/react-native-mqtt-iot'
import CommonMethods from "../../../Common/utils/CommonMethods";
// import { gotoLaunchScene } from "../../../Common/utils/NavigationUtils";
// import { getUserTokenData } from "../../../Common/utils/UserTokenUtils";

import { clientSecret, clientId } from "../../utils/urls";

import userProfile from "../UserProfile";
import { APIProfile } from "../UserProfile/type";

import userAPI from "./UserAPI";
import BaseAuthStore from "./BaseAuthStore";

import {
  APIPhoneNumber,
  APIVerifyLoginOtpRequest,
  APIVerifyLoginOtpResponse,
} from "./type";
import { clearUserSession } from "../../utils/StorageUtils";

class AuthStore extends BaseAuthStore {
  @observable userLoginStatus: APIStatus;
  @observable userLoginVerifyStatus: APIStatus;
  @observable userLoginOtpResendStatus: APIStatus;
  @observable getProfileStatus: APIStatus;
  @observable logOutStatus: APIStatus;
  @observable userLoginAPIError: string;

  constructor() {
    super();
    this.initStore();
  }

  @action.bound
  initStore() {
    this.userLoginStatus = API_INITIAL;
    this.userLoginVerifyStatus = API_INITIAL;
    this.userLoginOtpResendStatus = API_INITIAL;
    this.getProfileStatus = API_INITIAL;
    this.logOutStatus = API_INITIAL;
    this.userLoginAPIError = "";
  }

  @action.bound
  setProfile(profile: APIProfile) {
    userProfile.setProfile(profile);
    // if (true) console.log(userProfile)
  }

  @action.bound
  setUserProfile(user: APIVerifyLoginOtpResponse) {
    const profile = {
      user_id: user.user_id,
      user_type: user.user_type,
      franchise_details: user.franchise_details,
      zendesk_config: user.zendesk_config,
    };
    this.setProfile({ ...profile, ...user.profile });
  }

  @action.bound
  setUserLoginStatus(status: APIStatus) {
    this.userLoginStatus = status;
  }

  @action.bound
  userLoginSuccess() {
    // if (true) console.log('Success')
  }

  @action.bound
  setUserLoginVerifyStatus(status: APIStatus) {
    this.userLoginVerifyStatus = status;
  }

  @action.bound
  userLoginVerifySucces(user: APIVerifyLoginOtpResponse) {
    this.setAccessToken(user.access_token);
    this.setRefreshToken(user.refresh_token);
    this.setUserProfile(user);
  }

  @action.bound
  resendOtpSuccess() {
    // if (true) console.log()
  }

  @action.bound
  setUserLoginOtpResendStatus(status: APIStatus) {
    this.userLoginOtpResendStatus = status;
  }

  @action.bound
  clearUserLoginOtpResendStatus() {
    this.userLoginOtpResendStatus = API_INITIAL;
  }

  @action.bound
  setGetProfileStatus(status: APIStatus) {
    this.getProfileStatus = status;
  }

  @action.bound
  setLogOutStatus(status: APIStatus) {
    this.logOutStatus = status;
  }

  @action.bound
  setLogOut() {
    this.logout();
  }

  // TODO:Should make country code dynamic//
  userLogin() {
    const requestObject = {
      phone_number: userProfile.phoneNumber,
      country_code: "91",
    };
    this.userLoginApi(requestObject);
  }

  @action.bound
  async userLoginApi(
    requestObject: APIPhoneNumber,
    shouldUpdateImmediately = true
  ) {
    const userLoginPromise = userAPI.userLoginApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(userLoginPromise)
        .to(this.setUserLoginStatus, this.userLoginSuccess)
        .catch((error) => {
          //TODO: Need to add a observable userLoginAPIError and the this.userLoginAPIError = CommonMethods.diplayApiError(error)
          //TODO: Use that variable in the Login page display error functionality.
          const errorObject = CommonMethods.displayApiError(error, false);
          this.userLoginAPIError = errorObject.description;
        });
    }
    return userLoginPromise;
  }

  // TODO:Should make country code dynamic//
  async userLoginVerify(otp: string) {
    // const userTokenData = await getUserTokenData();
    const requestObject = {
      phone_number: userProfile.phoneNumber,
      country_code: "91",
      client_secret: clientSecret,
      client_id: clientId,
      otp,
      device_id: "1234556",
      device_type: "ANDROID",
      // ...userTokenData,
    };
    return this.userLoginOtpVerifyApi(requestObject);
  }

  @action.bound
  async userLoginOtpVerifyApi(
    requestObject: APIVerifyLoginOtpRequest,
    shouldUpdateImmediately = true
  ) {
    const userOtpPromise = userAPI.userLoginOtpVerifyApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(userOtpPromise)
        .to(this.setUserLoginVerifyStatus, this.userLoginVerifySucces)
        .catch((error) => {
          // if (true) console.log(error)
          const errorObject = CommonMethods.displayApiError(error, false);
          this.userLoginAPIError = errorObject.description;
        });
    }
    return userOtpPromise;
  }

  // TODO:Should make country code dynamic//
  resendUserLoginOtp() {
    const requestObject = {
      phone_number: userProfile.phoneNumber,
      country_code: "91",
    };
    this.userLoginOtpResendApi(requestObject);
  }

  @action.bound
  async userLoginOtpResendApi(
    requestObject: APIPhoneNumber,
    shouldUpdateImmediately = true
  ) {
    const userOtpResendPromise = userAPI.userLoginOtpResendApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(userOtpResendPromise)
        .to(this.setUserLoginOtpResendStatus, this.resendOtpSuccess)
        .catch((error) => {
          // if (true) console.log(error)
          CommonMethods.displayApiError(error);
        });
    }
    return userOtpResendPromise;
  }

  @action.bound
  async userLogOutApi(shouldUpdateImmediately = true) {
    const logoutPromise = userAPI.userLogOutApi();
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(logoutPromise)
        .to(this.setLogOutStatus, this.setLogOut)
        .catch((error) => {
          // if (true) console.log(error)
          this.logout();
        });
    }
    return logoutPromise;
  }

  @action.bound
  async logout() {
    // await Mqtt.logoutChatService()
    this.clearStore();
    userProfile.clearStore();
    clearUserSession();
    // AsyncStorage.clear(() => {
    //    gotoLaunchScene({})
    // })
  }

  @action.bound
  setNewAccessToken(response: Record<string, any>) {
    this.setAccessToken(response.access_token);
  }

  @action.bound
  getNewAccessTokenApi(shouldUpdateImmediately = true) {
    const requestObject = {
      refresh_token: this.refreshToken,
    };
    const getAccessTokenPromise = userAPI.getAccessTokenApi(requestObject);
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(getAccessTokenPromise)
        .to(() => {}, this.setNewAccessToken)
        .catch((error) => {
          if (true) console.log(error);
        });
    }
    return getAccessTokenPromise;
  }

  @action.bound
  clearStore() {
    this.accessToken = "";
    this.refreshToken = "";
    this.userLoginStatus = API_INITIAL;
    this.userLoginVerifyStatus = API_INITIAL;
    this.userLoginOtpResendStatus = API_INITIAL;
    this.getProfileStatus = API_INITIAL;
    this.logOutStatus = API_INITIAL;
  }
}

const authStore = new AuthStore();
export { authStore as default, AuthStore };

import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
    <svg width={width} height={height} viewBox="0 0 19 18" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M-1-2h22v24H-1z" />
            <path
                fill={fill}
                d="M9.5 13.93L3.629 18 5.7 11.18 0 6.875l7.151-.145L9.5 0l2.349 6.73L19 6.875l-5.7 4.305L15.371 18z"
            />
        </g>
    </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 19,
   height: 18,
   fill: Colors.mango
}
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";

import DatePickerWithPresetButtons from "../DatePickerWithPresetButtons";

import { Stats, StatsHeader, Title, StatsComponent } from "./styledComponents";

@observer
class StatsRevenueSummary extends Component {
  render() {
    const { title, store, showDateFilter } = this.props;
    const startDate = new moment(store.basicFilters.currentDateFilter.fromDate);
    const endDate = new moment(store.basicFilters.currentDateFilter.toDate);

    return (
      <Stats>
        <StatsHeader>
          <Title>{title}</Title>
          {showDateFilter ? (
            <DatePickerWithPresetButtons
              onDatesChange={store.onDateFilterChange}
              initialStartDate={startDate}
              initialEndDate={endDate}
            />
          ) : null}
        </StatsHeader>
        <StatsComponent>{this.props.children}</StatsComponent>
      </Stats>
    );
  }
}

export default StatsRevenueSummary;

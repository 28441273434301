/*
 * @flow
 * @author: Dinesh,Manikanta
 */

import Config from "../../Common/constants/EnvironmentConstants";

// const BASE_REVENUE_MANAGEMENT_URL = 'http://192.168.1.160:8000/'
const { BASE_URL } = Config;
const BASE_REVENUE_MANAGEMENT_URL = `${BASE_URL}api/revenues/`;
const urls = {
  revenue: {
    getRevenueSummary: `${BASE_REVENUE_MANAGEMENT_URL}revenue/summary/v1/`,
    getEntityRevenueDetails: `${BASE_REVENUE_MANAGEMENT_URL}entity/revenue/v2/`,
    getRevenueGraphSummary: `${BASE_REVENUE_MANAGEMENT_URL}revenue/graph/summary/v1/`,
    getRevenueDonutGraphSummary: `${BASE_REVENUE_MANAGEMENT_URL}revenue/donut_graph/summary/v1/`,
    getExpenditureGraphSummary: `${BASE_REVENUE_MANAGEMENT_URL}expenditure/graph/summary/v1/`,

    getRevenueChannelsList: `${BASE_REVENUE_MANAGEMENT_URL}channels/revenue/v1/`,
    getExpenditureChannelsList: `${BASE_REVENUE_MANAGEMENT_URL}channels/expenditure/v1/`,

    navigate: `${BASE_REVENUE_MANAGEMENT_URL}navigate/v1/`,
    getTransactionLogs: `${BASE_REVENUE_MANAGEMENT_URL}transaction/logs/v1/`,

    addExpenditure: `${BASE_REVENUE_MANAGEMENT_URL}channel/expenditure/add/v1/`,
    getExpenditureChannelsByFranchiseId: `${BASE_REVENUE_MANAGEMENT_URL}franchise/expenditure/channels/v1/`,
    getExpenditureLogs: `${BASE_REVENUE_MANAGEMENT_URL}expenditure/logs/v1/`,
    sendExpenditureLogsToMail: `${BASE_REVENUE_MANAGEMENT_URL}expenditure/logs/download/v1/`,

    getPaymentSummary: `${BASE_REVENUE_MANAGEMENT_URL}payment/summary/v1/`,
  },
};
export default urls;

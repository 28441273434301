/**
 * @flow
 */

// import mqttConnectionConfig from '@rn/react-native-mqtt-iot/app/stores/ConnectionConfig'
// import MQTTConnectionAPI from '@rn/react-native-mqtt-iot/app/stores/ConnectionAPI'
// import Config from "react-native-config";

// import "../config/MQTTConnectionConfig";

// import networkCallWithApiSauce from "../utils/AppAPI";

import authStore from "../../FranchiseLogin/stores/AuthStore";
import userProfile from "../../FranchiseLogin/stores/UserProfile";
// import settingsStore from "../stores/SettingsStore";
// import eventStore from "../../TrainingCalendar/stores/EventStore";

// import cashOutsStore from "../../CashOuts/stores/CashOutsStore";
// import communityNewsStore from "../../CommunityNews/stores/CommunityNewsStore";
// import customersStore from "../../Customers/stores/CustomersStore";

// // Offers stores
// import offersStore from "../../Offers/stores/offersStore";

// import issueStore from "../../FranchiseIssueTracking/stores/IssueStore";

// import franchiseStore from "../../FranchiseManagement/stores/FranchiseStore";
// import ChatStores from "../../Chat/stores/ChatStores";
// import MQTTConnectionStore from "../../Chat/stores/MQTTConnectionStore";
// import notificationsStore from "../../Notifications/stores/NotificationStore";

// // Dashboard module stores
import DashboardStore from "../../Dashboard/stores/DashboardStore";

// // Dashboard service
import DashboardService from "../../Dashboard/services/DashboardService";

// // Issues service

// import NavBarUnreadMessagesCountStore from "../../Common/stores/NavBarUnreadMessagesCount";

// // Food And Beverages module stores
// import FoodAndBeveragesStore from "../../FoodAndBeverages/stores/FoodAndBeveragesStore";
// import foodAndBeveragesService from "../../FoodAndBeverages/services/FoodAndBeverages/index";

// // Revenue module stores
import revenueStore from "../../FranchiseRevenueManagement/stores/";
import transactionLogsStore from "../../FranchiseRevenueManagement/stores/TransactionLogsStore";
import expenditureLogsStore from "../../FranchiseRevenueManagement/stores/ExpenditureLogsStore";

import UIStore from "./UIStore";

// const { BASE_URL, BASE_CHAT_URL } = Config;

const dashboardStore = new DashboardStore(new DashboardService());
const uiStore = new UIStore();

// const navBarUnreadMessagesCountStore = new NavBarUnreadMessagesCountStore();

// const getData = (baseURL) => (api, url, request) =>
//   networkCallWithApiSauce(baseURL + url, request);

// const mqttConnectionStore = new MQTTConnectionStore(
//   new MQTTConnectionAPI(BASE_CHAT_URL, getData(BASE_CHAT_URL)),
//   mqttConnectionConfig
// );

// const ticketsURL = `${BASE_URL}api/ibc_tickets/`;

// const chatStores = new ChatStores(
//   mqttConnectionStore,
//   getData,
//   ticketsURL,
//   BASE_CHAT_URL
// );

// const foodAndBeveragesStore = new FoodAndBeveragesStore(
//   foodAndBeveragesService
// );

export default {
  authStore,
  userProfile,
  // settingsStore,
  // eventStore,
  // issueStore,
  // franchiseStore,
  // notificationsStore,
  revenueStore,
  transactionLogsStore,
  expenditureLogsStore,
  // cashOutsStore,
  // customersStore,
  // offersStore,
  dashboardStore,
  uiStore,
  // communityNewsStore,
  // navBarUnreadMessagesCountStore,
  // mqttConnectionStore,
  // chatStores,
  // foodAndBeveragesStore,
};

import styled from "@emotion/styled";

export const NoDataGraphDiv = styled.div`
    font-family:'CerebriSans';
    font-size:14px;
    border-radius:100%;
    background-color:#ebedf0;
    width: 100px;
    height: 100px;
    margin: 4px 26px 16px 4px;
    padding: 18px 13px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`
export const NoDataAmount = styled.div``

export const NoDataText = styled.div``
/**
 * @flow
 * @author: Anesh Parvatha
 */

// import I18n from 'react-native-i18n'

import type { ValidationResponse } from "../constants/ValidationTypeConstants";
import { useTranslation } from "react-i18next"; // eslint-disable-line
import i18n from "../../Common/i18n";

const NameRegExp = /^[A-Z a-z]+$/;
const PhoneRegExp = /[6-9]{1}[0-9]{9}/;
const EmailRegExp = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/; // eslint-disable-line

export function getType(entity: any): string {
  let type = Object.prototype.toString.call(entity).toUpperCase().slice(8, -1);

  // PHANTOMJS bug
  if (type === "DOMWINDOW") {
    if (typeof entity === "object") {
      type = "NULL";
    } else if (typeof entity === "undefined") {
      type = "UNDEFINED";
    }
  }
  return type;
}

export function is(entity: any): boolean {
  const types = [].slice
    .call(arguments, 1) //eslint-disable-line
    .map((str) => String(str).toUpperCase());
  return types.indexOf(getType(entity)) !== -1;
}

export function isNull(data: any): boolean {
  return is(data, "null");
}

export function isUndefined(data: any): boolean {
  return is(data, "undefined");
}

export function isNumber(data: any): boolean {
  return is(data, "number");
}

export function isArray(data: any): boolean {
  return is(data, "array");
}

export function isBoolean(data: any): boolean {
  return is(data, "boolean");
}

export function isObject(data: any): boolean {
  return is(data, "object");
}

export function isString(data: any): boolean {
  return is(data, "string");
}

export function isEmpty(data: any): boolean {
  const isDataNull = isNull(data);
  const isDataUndefined = isUndefined(data);
  const isDataString = isString(data);
  const isDataObject = isObject(data);
  const isDataArray = isObject(data);
  if (isDataNull || isDataUndefined) {
    return true;
  } else if (isDataString) {
    return data === "";
  } else if (isDataObject) {
    let size = 0;
    Object.keys(data).forEach((key) => {
      if (data.hasOwnProperty(key)) size++; //eslint-disable-line
    });
    return size === 0;
  } else if (isDataArray) {
    return data.length === 0;
  }
  return false;
}

export function validatePhone(value: string): ValidationResponse {
  if (value) {
    if (value.match(PhoneRegExp)) {
      return { displayError: false, errorMsg: "" };
    }
    return {
      displayError: true,
      errorMsg: i18n.t("franchiseCommon.invalidPhoneNumber"),
    };
  }
  return {
    displayError: true,
    errorMsg: i18n.t("franchiseCommon.emptyInputError"),
  };
}

export function validateName(value: string): ValidationResponse {
  if (value) {
    const sanitizedName = value.trim();
    const { t } = useTranslation();
    if (sanitizedName.match(NameRegExp)) {
      if (sanitizedName.length < 3) {
        return {
          displayError: true,
          errorMsg: t("common:franchiseCommon.invalidNameInputError"),
        };
      }
      return { displayError: false, errorMsg: "" };
    }
    return {
      displayError: true,
      errorMsg: t("common:franchiseCommon.invalidInputError"),
    };
  }
  return {
    displayError: true,
    errorMsg: t("common:franchiseCommon.emptyInputError"),
  };
}

export function validateEmail(value: string): ValidationResponse {
  if (value) {
    if (value.match(EmailRegExp)) {
      return { displayError: false, errorMsg: "" };
    }
    return {
      displayError: true,
      errorMsg: 'I18n.t("franchiseCommon.invalidInputError")',
    };
  }
  return {
    displayError: true,
    errorMsg: 'I18n.t("franchiseCommon.emptyInputError")',
  };
}

import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import moment from "moment";
import {
  DateRangePicker,
  DateRangePickerShape,
  DayPickerRangeController,
} from "react-dates";
import "react-dates/initialize";
import onClickOutside from "react-onclickoutside";
import DatePickerButtonIcon from "../../../Common/Icons/DatePickerButtonIcon";

import "react-dates/lib/css/_datepicker.css";
import "./index.css";

import {
  SelectDatePickerMainDiv,
  SelectDatePickerDiv,
  SelectDatePickerInput,
  ShowDatePickerDiv,
  PresetButton,
  ShowCustomDateDiv,
  CustomDatesTextP,
  StartsOnEndsOnDiv,
  DateInput,
  CancelApplyButtonsDiv,
  CancelButton,
  ApplyButton,
} from "./styledComponents";
import ChevronDownArrow from "../../../Common/Icons/ChevronDownArrow";

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      focusedInput: "startDate",
      showDatePicker: false,
      selectedInputValue: "Last Week",
      presetButtonText: null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.renderDatePresets = this.renderDatePresets.bind(this);
  }

  setSelectInputValue = (text) => {
    this.setState({ selectedInputValue: text });
  };

  onDatesChange({ startDate, endDate }, presetButtonText = null) {
    this.setState({ startDate, endDate, presetButtonText }, () => {
      presetButtonText !== null ? this.applyDateFilter() : null;
    });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: focusedInput || "startDate" });
  }

  renderDatePresets() {
    const { presets, styles } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <div>
        {presets.map(({ text, start, end }) => {
          return (
            <div>
              <PresetButton
                isSelected={this.state.presetButtonText === text}
                key={text}
                type="button"
                class="PresetDateRangePicker_button"
                onClick={() => {
                  this.onDatesChange({ startDate: start, endDate: end }, text);
                }}
              >
                {text}
              </PresetButton>
            </div>
          );
        })}
      </div>
    );
  }

  onToggleDatePicker = () => {
    this.setState({ showDatePicker: !this.state.showDatePicker });
  };

  onCancel = () => {
    this.setState({
      startDate: this.props.initialStartDate,
      endDate: this.props.initialEndDate,
    });
    this.closeDatePicker();
  };

  closeDatePicker = () => {
    this.setState({
      showDatePicker: false,
    });
  };

  handleClickOutside = () => {
    this.closeDatePicker();
  };

  applyDateFilter = () => {
    const { startDate, endDate, presetButtonText } = this.state;
    if ((startDate !== null) & (endDate !== null)) {
      this.props.onDatesChange(startDate, endDate, 1);
      presetButtonText !== null
        ? this.setSelectInputValue(presetButtonText)
        : this.setSelectInputValue("Custom Dates");
    }
    this.closeDatePicker();
  };

  renderCancelAndApplyButtons = () => {
    return (
      <CancelApplyButtonsDiv>
        <CancelButton onClick={this.onCancel}>Cancel</CancelButton>
        <ApplyButton onClick={this.applyDateFilter}>Apply</ApplyButton>
      </CancelApplyButtonsDiv>
    );
  };

  render() {
    const {
      focusedInput,
      startDate,
      endDate,
      showDatePicker,
      selectedInputValue,
    } = this.state;
    return (
      <SelectDatePickerMainDiv>
        <SelectDatePickerDiv
          isFocused={showDatePicker}
          onClick={this.onToggleDatePicker}
        >
          <DatePickerButtonIcon
            width={16}
            height={15}
            fill={showDatePicker ? "white" : "#808080"}
          />
          <SelectDatePickerInput
            type="select"
            name="start date"
            readOnly
            value={selectedInputValue}
          />
          <ChevronDownArrow
            width={10}
            height={10}
            fill={showDatePicker ? "white" : "#808080"}
          />
        </SelectDatePickerDiv>
        {showDatePicker && (
          <ShowDatePickerDiv>
            {this.renderDatePresets()}
            <ShowCustomDateDiv>
              <CustomDatesTextP>Custom Dates</CustomDatesTextP>
              <StartsOnEndsOnDiv>
                <span>Starts on</span>
                <DateInput
                  type="text"
                  name="start date"
                  value={startDate && startDate.format("DD-MM-YYYY")}
                  disabled
                />
                <span>Ends on</span>
                <DateInput
                  type="text"
                  name="end date"
                  value={endDate && endDate.format("DD-MM-YYYY")}
                  disabled
                />
              </StartsOnEndsOnDiv>
            </ShowCustomDateDiv>
            <DayPickerRangeController
              {...this.props}
              hideKeyboardShortcutsPanel={true}
              renderCalendarInfo={this.renderCancelAndApplyButtons}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              // displayFormat={() => "DD/MM/YYYY"}
              numberOfMonths={2}
            />
          </ShowDatePickerDiv>
        )}
      </SelectDatePickerMainDiv>
    );
  }
}

export default onClickOutside(DateRangePickerWrapper);

/*
* @flow
* @author : Dinesh
*/
import { observable, action } from 'mobx'
import type { IObservableArray } from 'mobx'
import GroupBarDataSet from './GroupBarDataSet'

class GroupBarGraphData {
  @observable data: IObservableArray<GroupBarDataSet>
  @observable xValues: IObservableArray<string>

  constructor() {
    this.data = observable([])
    this.xValues = observable([])
  }

  @action.bound
  setData(data: IObservableArray<GroupBarDataSet>) {
    this.data = data
  }

  @action.bound
  setXValues(xValues: IObservableArray<string>) {
    this.xValues = xValues
  }
}

export default GroupBarGraphData

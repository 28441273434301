/*
 * @flow
 */

import type { IObservableArray } from "mobx";
import { action, observable, computed } from "mobx";
// import I18n from 'react-native-i18n'
import { API_INITIAL } from "../../../../Common/constants/AppAPIConstants";
import type { APIStatus } from "../../../../Common/constants/AppAPIConstants";
import { bindPromiseWithOnSuccess } from "../../../../Common/utils/MobxPromise";
import {
  todayDate,
  sevenDaysBackDate,
} from "../../../../Common/utils/DateUtils";
import SortByItem from "../common/SortByItem";
import DateFilter from "../../models/common/DateFilter";
import BasicFilters from "../common/BasicFilters/";
import { dateFilterIndex } from "../../../../Common/constants/DateFilterConstants";

import {
  SORT_TYPE_ASC,
  SORT_TYPE_DESC,
  NAME_SORT_OPTION,
  AMOUNT_SORT_OPTION,
} from "../../../constants/BackendConstants";
import type { APIGetChannelsDetailsResponse } from "../../type";

import Channel from "./Channel";

class BaseChannels {
  @observable channelsList: IObservableArray<Channel>;

  @observable basicFilters: BasicFilters;
  @observable channelsListAPIStatus: APIStatus;
  @observable sortOptionsSelectedIndex: number;
  @observable error: any;
  getChannelsListAPI: () => Promise<>;

  @observable franchiseId: number;

  constructor(franchiseId) {
    this.franchiseId = franchiseId;
    this.basicFilters = new BasicFilters();
    this.basicFilters.setSortBy(this.basicFilters.defaultSortOptions[0]);
    this.channelsList = observable([]);
    this.channelsListAPIStatus = API_INITIAL;
    this.sortOptionsSelectedIndex = 0;
    this.basicFilters.setSortOptions([
      {
        order: SORT_TYPE_ASC,
        key: NAME_SORT_OPTION,
        name: "I18n.t(revenueModule.name)",
      },
      {
        order: SORT_TYPE_DESC,
        key: AMOUNT_SORT_OPTION,
        name: "I18n.t(revenueModule.amount)",
      },
    ]);
    this.setDefaultDateFilter();
  }

  @action.bound
  setDefaultDateFilter = () => {
    this.basicFilters.setDateFilter(
      new DateFilter({
        from_date: sevenDaysBackDate(),
        to_date: todayDate(),
      })
    );
    this.basicFilters.currentDateFilter.setDateFilterIndex(
      dateFilterIndex.lastWeek
    );
  };

  @action.bound
  onChannelSortChanged(index: number, value: SortByItem) {
    console.log("Sort item clicked : >>> ", value);
    this.basicFilters.setSortBy(value);
    this.setSortOptionSelectedIndex(index);
    this.populateChannelsListBasedOnSelectedTab();
  }

  @action.bound
  setSortOptionSelectedIndex(index: number) {
    this.sortOptionsSelectedIndex = index;
  }

  @action.bound
  setDateSelectedIndex(index: number) {
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
  }

  @action.bound
  onChannelDateFilterChanged(fromDate: string, toDate: string, index: number) {
    if (true) {
      console.log("On Channel date filters changed : >>> ", fromDate, toDate);
    }
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    this.setDateSelectedIndex(index);
    this.populateChannelsListBasedOnSelectedTab();
  }

  @action.bound
  updateChannelsListAPIStatus(status: APIStatus) {
    this.channelsListAPIStatus = status;
  }

  @action.bound
  updateChannelsList(response: APIGetChannelsDetailsResponse) {
    const channelsData = response.channels_data;
    this.channelsList = channelsData.map((channel) => new Channel(channel));
  }

  @computed
  get requestObject() {
    const request = {
      sort_by: [],
      franchise_id: this.franchiseId,
    };
    if (!this.basicFilters.currentDateFilter.isReset) {
      request.date_range = this.basicFilters.currentDateFilter.requestData;
    }
    if (!this.basicFilters.currentSortBy.isReset) {
      request.sort_by = [this.basicFilters.currentSortBy.requestObject];
    }
    return request;
  }

  @action.bound
  async getChannelsList(
    requestObject = {},
    shouldUpdateImmediately: boolean = true
  ): Promise<*> {
    const apiPromise = this.getChannelsListAPI(requestObject);
    if (shouldUpdateImmediately === true) {
      return bindPromiseWithOnSuccess(apiPromise)
        .to(this.updateChannelsListAPIStatus, this.updateChannelsList)
        .catch((error) => {
          if (true) console.log(error);
          this.error = error;
        });
    }
    return apiPromise;
  }

  @action.bound
  populateChannelsListBasedOnSelectedTab() {
    this.getChannelsList(this.requestObject);
  }
}

export default BaseChannels;

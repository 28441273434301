import styled from "@emotion/styled";
import Colors from "../../../Common/themes/Colors";
import tw from "tailwind.macro";
import { Typo24WhiteCerebriSans } from "../../../Common/styleGuides/Typos";

//TODO: Need to update the styles with Tailwind

export const BaseTypo = styled.div`
  background-color: transparent;
`;

export const BaseImage = styled.img`
  object-fit: contain;
`;

export const SignInPage = styled.div`
  background-image: linear-gradient(to bottom, #090f18, rgba(9, 15, 24, 0)),
    url("https://res.cloudinary.com/due4dmz2b/image/fetch/dpr_auto,w_auto,f_auto,q_auto/https://ib-franchise-backend-media-static.s3.ap-south-1.amazonaws.com/prod/website/login-background.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #090f18;
`;

export const Logo = styled(BaseImage)`
  object-fit: contain;
  width: 90px;
  height: 90px;
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const LogoText = styled.div`
  font-family: "CerebriSans";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  margin: 18px 164px 18px 2px;
`;

export const LoginPageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const InputLabel = styled.label`
  font-family: "CerebriSans";
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.coolGreyTwo};
`;

export const Input = styled.input`
  font-family: "CerebriSans";
  font-weight: 400;
  width: 240px;
  height: 40px;
  margin: 3px 0 2px;
  padding: 10px 12px 9px;
  border-radius: 3px;
  border: solid 2px #4e94ff;
  border: ${(props) =>
    props.isErrorPresent ? "2px solid #f59896" : "solid 2px #4e94ff"};
  font-size: 14px;
  outline: none;
  background-color: ${(props) =>
    props.isErrorPresent ? Colors.ozai : Colors.white};
  ::placeholder {
    font-family: "CerebriSans";
    font-weight: 400;
    opacity: 1;
    font-size: 14px;
    color: #a6adbb;
  }
`;
export const LoginButton = styled.button`
  ${tw` flex justify-center text-center text-white focus:outline-none focus:shadow-outline`}
  font-family: "CerebriSans";
  font-weight: 500;
  width: 240px;
  height: 36px;
  margin: 2px 0 0 0px;
  padding: 8px 104px;
  border-radius: 3px;
  background-color: #004acd;
  outline: none;
  border: none;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ErrorMessageSpan = styled.span`
  font-family: "CerebriSans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${Colors.coolGreyTwo};
  max-width: 240px;
  margin-bottom: 5px;
  visibility: ${(props) => (props.displayError ? "visible" : "hidden")};
`;

import styled from "@emotion/styled";

export const RevenueCentersDiv = styled.div`
  font-family: CerebriSans;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  height: 220px;
  padding: 18px 8px 17px 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44),
    0 -1px 1px 0 rgba(151, 160, 176, 0.12);
  margin-right: 21.5px;
  margin-bottom: 22px;
`;

export const Icon = styled.div`
  border-radius: 100%;
  padding: 18px;
  background-color: ${(props) => props.color};
  color: white;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 17px;
`;

export const AreaTextAmount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const AreaText = styled.div``;

export const Amount = styled.div``;

/*
* @flow
* @author : Dinesh
*/

import { observable } from 'mobx'
import type { IObservableArray } from 'mobx'
import LineGraphValue from './LineGraphValue'

class LineGraphDataSet {
  @observable values: IObservableArray<LineGraphValue>
  @observable label: string
  @observable fillColor: string
  @observable lineColor: string

  constructor(
    values: Array<number>,
    label: string,
    fillColor: string,
    lineColor: string
  ) {
    this.values = observable(
      values.map((eachValue) => new LineGraphValue(eachValue, eachValue))
    )
    this.label = label
    this.fillColor = fillColor
    this.lineColor = lineColor
  }
}

export default LineGraphDataSet

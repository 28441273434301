import { observer } from "mobx-react";
import React, { Component } from "react";

import ChannelCard from "../ChannelCard";
import NoDataView from "../NoDataView/NoDataView";

import { SummaryCards, NoDataChannel } from "./styledComponents";

@observer
class ChannelCards extends Component {
  render() {
    const { channelsInstance } = this.props;
    const { channelsList } = channelsInstance;
    const isDataAvailable = channelsList.length > 0;
    return (
      <SummaryCards>
        {isDataAvailable ? (
          channelsList.map((channel) => {
            const { title, name, formattedAmount, color, id } = channel;
            return (
              <ChannelCard
                title={title}
                name={name}
                amount={formattedAmount}
                color={color}
              />
            );
          })
        ) : (
          <NoDataChannel>
            <NoDataView text={"No Data"} />
          </NoDataChannel>
        )}
      </SummaryCards>
    );
  }
}

export default ChannelCards;

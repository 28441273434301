/*
 * @flow
 */

export const API_INITIAL = 0
export const API_FETCHING = 100
export const API_SUCCESS = 200
export const API_PAGINATION_FINISHED = 300
export const API_FAILED = 400
export const API_SWIPE_REFRESH = 500
export const API_FETCHING_PAGINATION = 600

export const INIT = 'init'
export const LOAD_MORE = 'loadmore'

export type APIStatus = 0 | 100 | 200 | 300 | 400 | 500 | 600
export type APIType = 'init' | 'loadmore'

export default {
  API_INITIAL,
  API_FETCHING,
  API_SUCCESS,
  API_FAILED,
  INIT,
  LOAD_MORE,
  API_PAGINATION_FINISHED,
  API_SWIPE_REFRESH,
  API_FETCHING_PAGINATION
}

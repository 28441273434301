/*
 * @flow
 * Author: Prasanth
 */

import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import type { APIAdditionalAttr } from './type.js'

class AdditionalAttr {
  @persist
  @observable
  attrKey: string
  @persist
  @observable
  attrValue: string

  @action.bound
  setAttribute(additionalAttr: APIAdditionalAttr) {
    this.attrKey = additionalAttr.attr_key || ''
    this.attrValue = additionalAttr.attr_value || ''
  }

  getRequestData() {
    return {
      attr_key: this.attrKey,
      attr_value: this.attrValue
    }
  }

  @action.bound
  setAttrKey(attrKey: string) {
    this.attrKey = attrKey
  }

  @action.bound
  setAttrValue(attrValue: string) {
    this.attrValue = attrValue
  }
}
export default AdditionalAttr

/*
 * @flow
 * Author: Prasanth
 */

import Config from "../../Common/constants/EnvironmentConstants";

let BASE_URL = Config.BASE_URL;
let BASE_CHAT_URL = Config.BASE_CHAT_URL;

const BASE_FRANCHISE_LOGIN_URL = `${BASE_URL}api/franchise_user/`;

const BASE_FRANCHISE_URL = `${BASE_URL}api/ib_franchise/`;

const BASE_APP_CONFIG_URL = `${BASE_URL}api/app_config/`;

const urls = {
  auth: {
    userLogin: `${BASE_FRANCHISE_LOGIN_URL}login/v1/`,
    verifyLoginOtp: `${BASE_FRANCHISE_LOGIN_URL}login/otp/verify/v1/`,
    resendLoginOtp: `${BASE_FRANCHISE_LOGIN_URL}login/otp/resend/v1/`,
    userLogout: `${BASE_FRANCHISE_LOGIN_URL}logout/v1/`,
    getAccessToken: `${BASE_FRANCHISE_LOGIN_URL}user/access_token/v1/`,
  },
  phone_number: {
    changePhNo: `${BASE_FRANCHISE_LOGIN_URL}user/phone_number/change/v1/`,
    verifyPhNoOtp: `${BASE_FRANCHISE_LOGIN_URL}user/phone_number/change/otp/verify/v1/`,
    resendChangePhNoOtp: `${BASE_FRANCHISE_LOGIN_URL}user/phone_number/change/otp/resend/v1/`,
  },
  profile: {
    getUserProfile: `${BASE_FRANCHISE_LOGIN_URL}user/profile/v1/`,
    updateUserProfile: `${BASE_FRANCHISE_LOGIN_URL}user/profile/edit/v1/`,
    getFranchises: `${BASE_FRANCHISE_URL}users/franchise/v1/`,
    getAppConfig: `${BASE_APP_CONFIG_URL}features/v1/`,
  },
};

export const clientSecret = "franchise_rn_app";
export const clientId = "franchise_rn_app";
export const source = "ib-franchise";
export default urls;

import React, { Component } from 'react';
import {MainDiv, Logo, SubDiv, Text, Number} from './styledComponents'

class ImageTitleAndNumber extends Component {
    render() {
        const {icon:Icon, imageSRC, text, number, size, currency} = this.props
        return (
            <MainDiv>
                {imageSRC? <Logo size={size} src={imageSRC}/> : <Icon width={30} height={30} /> }
                <SubDiv>
                    <Text>{text}</Text>
                    <Number>{currency} {number}</Number>
                </SubDiv>
            </MainDiv>
        );
    }
}

export default ImageTitleAndNumber;
/**
 * @flow
 */

import { observer } from "mobx-react";
import React from "react";
// import { observer } from 'mobx-react/native'
// import I18n from 'react-native-i18n'
// import Image from '@rn/commons/app/components/Image'
// import Images from '../../../Common/themes/Images'
// import {
//   DisplayBook24,
//   Body
// } from '../../../Common/styledComponents/Text/StyledTexts'
import {
  CardView,
  SettlementDetails,
  SettlementText,
  Amount,
  HelpTextView,
  HelpText,
} from "./styledComponents";

export type Props = {
  amount: number,
  displayHelpText?: boolean,
};
// TODO: V2 Should cross-check the today data styles with zeplin

const SettlementCard = observer((props: Props) => (
  <CardView>
    <SettlementDetails>
      <SettlementText>Settlement Amount</SettlementText>
      <Amount>{props.amount}</Amount>
    </SettlementDetails>
    {props.displayHelpText ? (
      <HelpTextView>
        <HelpText>*this amount is only an approximation</HelpText>
      </HelpTextView>
    ) : null}
  </CardView>
));

SettlementCard.defaultProps = {
  displayHelpText: false,
};

export default SettlementCard;

import React, { Component, ReactElement } from "react";
import { withTranslation, WithTranslation } from "react-i18next"; // eslint-disable-line
import { API_FETCHING } from "@ib/api-constants";
import Loader from "../../../Icons/Loader";
import i18n from "../../../i18n";

import {
  Container,
  FailureText,
  FailureSupportText,
  TryAgainButton,
  FailureViewImage,
} from "./styledComponents";

class FailureView extends Component {
  renderTryAgainText = (shouldDisplayLoader) => {
    const { t } = this.props;
    return (
      <TryAgainButtonWrapper isLoaderDisplaying={shouldDisplayLoader}>
        {i18n.t("common.failureView.tryAgain")}
      </TryAgainButtonWrapper>
    );
  };

  renderTryAgainButton = (onRetry, shouldDisplayLoader) => (
    <TryAgainButton onClick={onRetry}>
      <>
        {shouldDisplayLoader ? (
          <Loader height={25} width={25} />
        ) : (
          this.renderTryAgainText(shouldDisplayLoader)
        )}
      </>
    </TryAgainButton>
  );

  render() {
    const {
      apiStatus,
      onRetry,
      failureText,
      failureSupportText,
      className,
      t,
    } = this.props;
    const shouldDisplayLoader = apiStatus === API_FETCHING;
    return (
      <Container className={className}>
        <FailureViewImage
          src="https://res.cloudinary.com/due4dmz2b/image/fetch/dpr_auto,w_386,f_auto,q_auto/https://ib-franchise-backend-media-static.s3.ap-south-1.amazonaws.com/prod/website/failure+view.png"
          alt="Failure image"
        />
        <FailureText>{failureText}</FailureText>
        <FailureSupportText>{failureSupportText}</FailureSupportText>
        <div>
          {onRetry && (
            <TryAgainButton onClick={onRetry}>Try again!</TryAgainButton>
          )}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(FailureView);

FailureView.defaultProps = {
  failureText: "Sorry, Something Went Wrong",
  failureSupportText:
    "We’re having some trouble to complete your request. Please try again",
};

// interface FailureViewProps extends WithTranslation {
//    apiStatus?: number
//    onRetry?: () => void
//    failureText: string
//    buttonText?: string
//    className?: string
// }

import styled from "@emotion/styled";


export const Stats = styled.div`
    width:49%;
    height:350px;
    box-shadow: 0 1px 2px 0 rgba(151, 160, 176, 0.44), 0 -1px 1px 0 rgba(151, 160, 176, 0.12);
    background-color:white;
    border-radius:3px;
    font-family:'CerebriSans';
`

export const StatsHeader = styled.div`
    height: 60px;
    margin: 0 0 18px;
    padding: 14px 20px;
    display:flex;
    justify-content:space-between;
    box-shadow: inset 0 -1px 0 0 #dfe3e8;
`

export const Title = styled.div`
    font-family:'CerebriSans';
    font-size:18px;
`

export const StatsComponent = styled.div`
    display:flex;
    padding:18px 25px 18px 25px;
    width:100%;
`
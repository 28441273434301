import React, { Component } from "react";
import FranchiseReviewStarIcon from "../../Icons/FranchiseReviewStarIcon";
import {
  CustomerRatingDiv,
  CustomerRatingTitle,
  Review,
  ReviewRating,
} from "./styledComponents";

class CustomerRating extends Component {
  render() {
    const { customerRating } = this.props;
    return (
      <CustomerRatingDiv>
        <CustomerRatingTitle>Customer Rating</CustomerRatingTitle>
        <Review>
          <FranchiseReviewStarIcon />
          <ReviewRating>{customerRating}</ReviewRating>
        </Review>
      </CustomerRatingDiv>
    );
  }
}

export default CustomerRating;

/*
 * @flow
 * @author: Chinmaya
 */
import { observable, action, computed } from "mobx";
import { API_INITIAL } from "@ib/api-constants";
import { bindPromiseWithOnSuccess } from "../../../Common/utils/MobxPromise";

import type { APIStatus } from "@ib/api-constants";
import type { IObservableArray, ObservableMap } from "mobx";

// import { Services as FranchiseManagementServices } from "../../../FranchiseManagement/services/";
// import FranchiseCCTVDetails from "../../../FranchiseManagement/stores/FranchiseCCTVDetails";
import CustomersSection from "../models/CustomersSection";
import RevenuesSection from "../models/RevenuesSection";
import FranchiseHealth from "../models/FranchiseHealth";
import FranchiseDetail from "../models/FranchiseDetail";

import type { APIService } from "../../services/DashboardService/type";
import type {
  APICustomersSection,
  APIRevenuesSection,
  APIFranchiseHealth,
  APIFranchiseDetail,
  APIGetDashboard,
  APIGetSurveillanceResponse,
} from "../type";

class DashboardStore {
  @observable dashboardStatus: APIStatus;
  apiService: APIService;
  @observable dashboardAPIError: ?Error;

  @observable customersData: CustomersSection;
  @observable revenueData: RevenuesSection;
  @observable franchisesHealth: IObservableArray<FranchiseHealth>;
  @observable franchisesDetails: IObservableArray<FranchiseDetail>;

  // @observable surveillanceStatus: APIStatus;
  // @observable surveillanceAPIError: Error;

  @observable selectedFranchise: number;

  // @observable surveillanceDetails: ObservableMap<FranchiseCCTVDetails>;

  @observable displayFranchiseIndex: number;

  constructor(service: APIService) {
    this.apiService = service;
    this.init();
  }

  @action.bound
  init() {
    this.dashboardStatus = API_INITIAL;
    // TODO: Write appropriate flow types for following variables to handle undefined case
    this.customersData = undefined;
    this.revenueData = undefined;
    this.franchisesHealth = undefined;
    this.franchisesDetails = undefined;

    // this.surveillanceStatus = API_INITIAL;
    // this.surveillanceAPIError = undefined;

    this.selectedFranchise = undefined;
    // this.surveillanceDetails = new Map();

    this.changeDisplayFranchiseIndex(0);
  }

  @action.bound
  clear() {
    this.init();
  }

  @action.bound
  changeDisplayFranchiseIndex(index: number) {
    this.displayFranchiseIndex = index;
  }

  @action.bound
  setDashboardStatus(status: APIStatus) {
    this.dashboardStatus = status;
  }

  @action.bound
  setCustomersData(customersData: APICustomersSection) {
    this.customersData = new CustomersSection(customersData);
  }

  @action.bound
  setRevenueData(revenueData: APIRevenuesSection) {
    this.revenueData = new RevenuesSection(revenueData);
  }

  @action.bound
  setFranchisesHealth(franchisesHealth: Array<APIFranchiseHealth>) {
    this.franchisesHealth = franchisesHealth.map(
      (franchiseHealth) => new FranchiseHealth(franchiseHealth)
    );
  }

  @action.bound
  setFranchisesDetails(franchisesDetails: Array<APIFranchiseDetail>) {
    this.franchisesDetails = franchisesDetails.map(
      (item) => new FranchiseDetail(item)
    );
  }

  getValueFromResponseOrSendError(key: string, response) {
    const value = response[key];
    if (value === undefined || value === null) {
      this.sentDashboardErrorToAnalytics(`${key} is not found in the response`);
      throw new Error(`${key} is not found in response`);
    }
    return value;
  }

  @action.bound
  setDashboardAPIResponse(response: APIGetDashboard) {
    const customersData = this.getValueFromResponseOrSendError(
      "customers",
      response
    );
    const revenue = this.getValueFromResponseOrSendError("revenues", response);
    const franchisesHealth = this.getValueFromResponseOrSendError(
      "franchises_health",
      response
    );
    const franchisesDetails = this.getValueFromResponseOrSendError(
      "franchises_details",
      response
    );
    this.setCustomersData(customersData);
    this.setFranchisesHealth(franchisesHealth);
    this.setFranchisesDetails(franchisesDetails);
    this.setRevenueData(revenue);
    return response;
  }

  @action.bound
  setDashboardAPIError(error: Error) {
    this.dashboardAPIError = error;
  }

  sentDashboardErrorToAnalytics(message: string) {
    // TODO: send error to analytics
    // if (__DEV__) {
    //   console.error(message);
    // }
  }

  @action.bound
  setSelectedFranchise(franchiseId: number) {
    this.selectedFranchise = franchiseId;
  }

  // @action.bound
  // setSurveillanceStatus(status: APIStatus) {
  //   this.surveillanceStatus = status;
  // }

  // @action.bound
  // setSurveillanceAPIError(error: Error) {
  //   this.surveillanceAPIError = error;
  // }

  @computed
  get doesntHaveSelectedFranchise(): boolean {
    return this.selectedFranchise === undefined;
  }

  // @computed
  // get selectedFranchiseHasSurveillanceVideos(): boolean {
  //   return this.surveillanceDetails.has(this.selectedFranchise);
  // }

  // @computed
  // get selectedFranchiseDoesntHaveSurveillanceVideos(): boolean {
  //   return !this.selectedFranchiseHasSurveillanceVideos;
  // }

  // @computed
  // get franchiseSurveillanceVideos() {
  //   return this.surveillanceDetails.get(this.selectedFranchise);
  // }

  // @computed
  // get franchiseSurveillanceDetails() {
  //   const franchiseSurveillanceVideos = this.surveillanceDetails.get(
  //     this.selectedFranchise
  //   );
  //   return franchiseSurveillanceVideos.formattedCCTVDetails;
  // }

  // @action.bound
  // setSurveillanceAPIResponse(response: APIGetSurveillanceResponse) {
  //   if (this.surveillanceDetails.has(this.selectedFranchise)) {
  //     const franchiseSurveillanceDetails = this.surveillanceDetails.get(
  //       this.selectedFranchise
  //     );
  //     franchiseSurveillanceDetails.setCCTVDetails(response);
  //   } else {
  //     this.surveillanceDetails.set(
  //       this.selectedFranchise,
  //       new FranchiseCCTVDetails(
  //         new FranchiseManagementServices(),
  //         this.selectedFranchise
  //       )
  //     );
  //     this.surveillanceDetails
  //       .get(this.selectedFranchise)
  //       .setCCTVDetails(response);
  //   }
  // }

  getDashboardDetails(
    shouldUpdateImmediately: ?boolean = true
  ): Promise<APIGetDashboard> {
    const dashboardPromise = this.apiService.getDashboardDetails();
    if (shouldUpdateImmediately === true) {
      return bindPromiseWithOnSuccess(dashboardPromise).to(
        this.setDashboardStatus,
        this.setDashboardAPIResponse
      );
    }
    return dashboardPromise;
  }

  // getSurveillanceVideos(
  //   shouldUpdateImmediately: ?boolean = true
  // ): Promise<APIGetSurveillanceResponse> {
  //   const requestObject = {
  //     franchise_id: this.selectedFranchise,
  //   };
  //   const surveillancePromise = this.apiService.getSurveillanceVideos(
  //     requestObject
  //   );
  //   if (shouldUpdateImmediately === true) {
  //     return bindPromiseWithOnSuccess(surveillancePromise).to(
  //       this.setSurveillanceStatus,
  //       this.setSurveillanceAPIResponse
  //     );
  //   }
  //   return surveillancePromise;
  // }
}

export default DashboardStore;

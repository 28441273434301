import React, { Component } from "react";
import {
  ScoreInPercentageContainer,
  ScoreInPercentageCompletionBg,
  ScoreText,
} from "./styledComponents";

class ScoreInPercentage extends Component {
  render() {
    const { level, color, total } = this.props;
    const width = (level / total) * 100;
    const giveSpaceWhenWidthIsZero = width === 0;
    return (
      <ScoreInPercentageContainer>
        <ScoreInPercentageCompletionBg color={color} width={width}>
          <ScoreText giveSpaceWhenWidthIsZero={giveSpaceWhenWidthIsZero}>
            {level}/{total}
          </ScoreText>
        </ScoreInPercentageCompletionBg>
      </ScoreInPercentageContainer>
    );
  }
}

export default ScoreInPercentage;

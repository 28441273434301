/*
 * @author: Chinmaya
 * @flow
 */

import { observable, action, computed } from 'mobx'
import { create, persist } from 'mobx-persist'

const pour = create()

class BaseAuthStore {
   @persist
   @observable
   accessToken = ''
   refreshToken = ''
   source = ''
   @observable languageCode = 'en'

   constructor() {
      this.hydrate()
   }

   @computed
   get isLoggedIn(): boolean {
      if (
         this.accessToken === null ||
         this.accessToken === undefined ||
         this.accessToken === ''
      ) {
         return false
      }
      return true
   }

   @action.bound
   setAccessToken(accessToken: string): void {
      this.accessToken = accessToken
   }

   @action.bound
   setRefreshToken(refreshToken: string): void {
      this.refreshToken = refreshToken
   }

   @action.bound
   setSource(source: string): void {
      this.source = source
   }

   @action.bound
   setLanguageCode(languageCode: string): void {
      this.languageCode = languageCode
      // if (true) {
      //    console.log('setLanguage', this.languageCode)
      // }
   }

   @action.bound
   hydrate() {
      Object.keys(this).forEach(key => {
         pour(key, this)
      })
   }
}

export default BaseAuthStore

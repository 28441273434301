/*
* @flow
*/

export const SUMMARY_BY_REVENUE_CHANNELS = 'Revenue Channels'
export const SUMMARY_BY_EXPENDITURE_CHANNELS = 'Expenditure Channels'
export const summaryByTypes = {
  revenueChannels: SUMMARY_BY_REVENUE_CHANNELS,
  expenditureChannels: SUMMARY_BY_EXPENDITURE_CHANNELS
}

export type SummaryByType = 'Revenue Channels' | 'Expenditure Channels'

import styled from "@emotion/styled";
import {
  ReactSelectAndDatePickerDiv,
  ReactSelectButtonDiv,
} from "../../containers/RevenueSummaryWithFranchisesTabbar/styledComponents";
import ReactSelect from "../ReactSelect";
import {
  HeaderContainer,
  SummaryText,
  TabContainer,
} from "../RevenueExpenditureChannelsTabBar/styledComponents";

export const HeaderContainerDiv = styled(HeaderContainer)`
  margin: 0px;
  padding: 12px 20px;
  background-color: #fff;
  align-items: center;
`;

export const TabBarContainer = styled.div`
  display: flex;
`;

export const TabContainerDiv = styled(TabContainer)``;

export const TabBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabTextContainer = styled.div``;

export const TabBarValueContainer = styled.div``;

export const ReactSelectAndDatePicker = styled.div`
  display: flex;
`;

export const ReactSelectButton = styled.div`
  margin-right: 10px;
`;
// export const SummaryText = styled.div`
//   margin: 5px 12px 24px 0px;
//   font-family: CerebriSans;
//   font-size: 18px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.33;
//   letter-spacing: normal;
//   color: #13274b;
// `;

export const SummaryTextDiv = styled(SummaryText)`
  line-height: normal;
`;

import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
    <svg width={width} height={height} viewBox="0 0 6 10" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M-3-5H9v20H-3z" />
            <path
                fill={fill}
                fillRule="nonzero"
                d="M3 8.134L1.227 6.226a.686.686 0 00-1.017 0 .817.817 0 000 1.094l2.282 2.454a.684.684 0 001.016 0L5.79 7.32a.817.817 0 000-1.094.686.686 0 00-1.017 0L3 8.134zM3 1.866l1.773 1.908a.686.686 0 001.017 0 .817.817 0 000-1.094L3.508.226a.684.684 0 00-1.016 0L.21 2.68a.817.817 0 000 1.094.686.686 0 001.017 0L3 1.866z"
            />
        </g>
    </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 6,
   height: 10,
   fill: Colors.strickler
}
/*
 * @flow
 * Author: Prasanth
 */

import networkCallWithApisauce from '../../../Common/utils/AppAPI'
import urls from '../../utils/urls'
import type { APIPhoneNumber } from '../AuthStore/type'
import type {
  APIChangeUserPhoneNumberRequest,
  APIVerifyChangeUserPhoneNumberOTPRequest,
  APIBasicProfile,
  APIGetFranchisesParameter
} from './type'

class ProfileAPI {
  async changePhoneNumberApi(requestObject: APIChangeUserPhoneNumberRequest) {
    const url = urls.phone_number.changePhNo
    return networkCallWithApisauce(url, requestObject)
  }
  async changePhoneNumberOtpVerifyApi(
    requestObject: APIVerifyChangeUserPhoneNumberOTPRequest
  ) {
    const url = urls.phone_number.verifyPhNoOtp
    return networkCallWithApisauce(url, requestObject)
  }
  async changePhoneNumberOtpResendApi(requestObject: APIPhoneNumber) {
    const url = urls.phone_number.resendChangePhNoOtp
    return networkCallWithApisauce(url, requestObject)
  }
  async updateUserProfileApi(requestObject: APIBasicProfile) {
    const url = urls.profile.updateUserProfile
    return networkCallWithApisauce(url, requestObject)
  }

  async getFranchiseDetailsAPI(requestObject: APIGetFranchisesParameter) {
    const url = urls.profile.getFranchises
    return networkCallWithApisauce(url, requestObject)
  }

  async getAppConfig() {
    const url = urls.profile.getAppConfig
    return networkCallWithApisauce(url, {}, () => {}, () => {}, 'GET')
  }

  async getUserProfileApi() {
    const url = urls.profile.getUserProfile
    return networkCallWithApisauce(url, {}, () => {}, () => {}, 'GET')
  }
}

const profileAPI = new ProfileAPI()
export default profileAPI

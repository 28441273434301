// here the entity might be any things either franchise / chanell
/*
 * @author: Tanmay B
 * @flow
 */

import { action, observable, computed } from "mobx";
import {
  API_INITIAL,
  API_SWIPE_REFRESH,
  API_FETCHING,
} from "@ib/api-constants";
import type { APIStatus } from "@ib/api-constants";
// import I18n from "react-native-i18n";

import { bindPromiseWithOnSuccess } from "../../../../../Common/utils/MobxPromise";

import RevenueExpenditureObject from "../RevenueExpenditureObject/";
import TodayRevenueExpenditureObject from "../TodayRevenueExpenditureObject";
import GraphsAndAnalytics from "../GraphsAndAnalytics";
// import { DAILY_OPTION_ID } from '../../../../../Common/components/PeriodFilter'
import {
  transactionLogFilterTypes,
  filterTypes,
} from "../../../../constants/BackendConstants";
import FilterItem from "../FilterItem";
import BasicFilters from "../BasicFilters";
import DateFilter from "../DateFilter";
import type { APIGetEntityRevenueDetailsRequest } from "../../../type";
import Services from "../../../../services";

class Entity {
  @observable entityType: string;
  @observable entityId: number;

  @observable settlement: number;
  @observable total: RevenueExpenditureObject;
  @observable todayRevenueExpenditure: TodayRevenueExpenditureObject;

  @observable graphsAndAnalytics: GraphsAndAnalytics;

  @observable basicFilters: BasicFilters;
  services: Services;

  @observable entityDetailsStatus: APIStatus;
  @observable error: any;

  @observable refreshing: APIStatus;
  @observable swipeRefreshError: any;

  constructor(
    entityId: number = -1,
    entityType: string = "",
    services: Services,
    selectedDateFilter?: DateFilter
  ) {
    this.basicFilters = new BasicFilters();
    if (selectedDateFilter) {
      this.basicFilters.setDateFilter(selectedDateFilter);
    }
    this.entityDetailsStatus = API_INITIAL;
    this.services = services;
    this.entityId = entityId;
    this.entityType = entityType;
    this.total = new RevenueExpenditureObject();
    this.todayRevenueExpenditure = new TodayRevenueExpenditureObject();
    this.graphsAndAnalytics = new GraphsAndAnalytics(
      entityId,
      entityType,
      this.basicFilters,
      this.services
    );
    this.refreshing = API_INITIAL;
    this.error = undefined;
    this.swipeRefreshError = undefined;
  }

  /**
   * Generic Utils for the Store
   */

  @action.bound
  onEntityDetailsSectionReload() {
    this.getEntityDetailsAPI();
  }

  /**
   * SwipeRefresh Utils
   */

  @action.bound
  onSwipeRefreshDetailsSection() {
    this.getEntityDetailsAPI(
      this.setRefreshStatus,
      () => {},
      this.setRefreshError,
      {},
      true,
      {
        loadingStatus: API_SWIPE_REFRESH,
      }
    );
  }

  @action.bound
  setRefreshStatus(status: APIStatus) {
    this.refreshing = status;
  }

  @action.bound
  setRefreshError(error) {
    this.swipeRefreshError = error;
  }

  /**
   * Generic Utils for the screen
   */

  @action.bound
  onEntityDetailsOpened() {
    // NOTE: The period filter is commented
    // this.graphsAndAnalytics.inflowVsOutFlow.setPeriod(DAILY_OPTION_ID)
    this.getEntityDetailsAPI();
  }

  /**
   * Get Entity Related API call Utils
   */

  @computed
  get getEntityDetailsDefaultRequest(): APIGetEntityRevenueDetailsRequest {
    const appliedFilters = [];
    this.graphsAndAnalytics.inflowVsOutFlow.basicFilters.appliedFilters.forEach(
      (filters) => {
        filters.forEach((eachFilter) => {
          appliedFilters.push(eachFilter.requestObject);
        });
      }
    );
    const request = {
      franchise_id: this.entityId,
      inflow_vs_outflow: {
        filters: appliedFilters,
      },
    };
    if (!this.basicFilters.currentDateFilter.isReset) {
      request.date_range = this.basicFilters.currentDateFilter.requestData;
    }
    return request;
  }

  @action.bound
  setEntityDetailsAPIStatus(status: APIStatus) {
    this.entityDetailsStatus = status;
  }

  @action.bound
  getEntityDetailsAPI(
    setAPIStatus = this.setEntityDetailsAPIStatus,
    onSuccess = this.setBasicDetails,
    onFailure = this.setRevenueHomeError,
    requestObject = {},
    shouldUpdateImmediately: boolean = true,
    options?: any = { loadingStatus: API_FETCHING }
  ) {
    const req = {
      ...this.getEntityDetailsDefaultRequest,
      ...requestObject,
    };
    const apiPromise = this.services.revenueStoreService.getEntityRevenueDetails(
      req
    );
    if (shouldUpdateImmediately) {
      return bindPromiseWithOnSuccess(apiPromise, options)
        .to(setAPIStatus, onSuccess)
        .catch(onFailure);
    }
    return apiPromise;
  }

  @action.bound
  setTotal(total: RevenueExpenditureObject, settlement: number) {
    this.total.setBasicDetails(total, settlement);
  }

  @action.bound
  setTodayRevenueExpenditure(
    todayRevenueExpenditure: TodayRevenueExpenditureObject
  ) {
    this.todayRevenueExpenditure.setBasicDetails(todayRevenueExpenditure);
  }

  @action.bound
  setBasicDetails(response: Object) {
    const {
      // eslint-disable-next-line camelcase
      inflow_vs_outflow,
      expenditure,
      total,
      settlement,
      // eslint-disable-next-line camelcase
      approx_settlement_amount,
      today,
      revenue,
      // eslint-disable-next-line camelcase
      revenue_channels,
    } = response;
    this.setTotal(total, settlement);
    this.setTodayRevenueExpenditure(today);
    this.total.setApproxSettlementAmount(approx_settlement_amount);
    this.graphsAndAnalytics.setInflowVsOutFlow(inflow_vs_outflow);
    this.graphsAndAnalytics.setExpenditure(expenditure);
    this.graphsAndAnalytics.setRevenue(revenue);

    const formattedRevenueChannels = this.getFormattedRevenueChannelFilterOptions(
      revenue_channels
    );
    this.graphsAndAnalytics.inflowVsOutFlow.basicFilters.setFilters(
      formattedRevenueChannels
    );
  }

  /**
   * Filters Related Utils
   */

  getFormattedRevenueChannelFilterOptions(options) {
    return options.map((item) => ({
      filter_id: item.channel_id,
      filter_type: filterTypes.channel,
      name: item.channel_name,
    }));
  }

  @action.bound
  async onDateFilterChanged(fromDate: string, toDate: string, index: number) {
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    await this.getEntityDetailsAPI();
  }

  @action.bound
  onFilterChanged(index: number, value: FilterItem) {
    this.basicFilters.changeCurrentFilter(value.filterType, [value]);
    this.getEntityDetailsAPI();
  }

  @action.bound
  onFilterReset(index: number, value: FilterItem) {
    this.basicFilters.changeCurrentFilter(value.filterType, []);
    this.getEntityDetailsAPI();
  }

  /**
   * Utils Used for displaying UI
   */

  @computed
  get displayFranchiseFilters() {
    const franchiseFilters = [];
    this.basicFilters.filters.forEach((eachFilter) => {
      if (eachFilter.filterType === transactionLogFilterTypes.franchise) {
        franchiseFilters.push(eachFilter);
      }
    });
    return observable([
      new FilterItem({
        name: "I18n.t(franchiseCommon.allFranchises)",
        filter_id: "-1",
        filter_type: transactionLogFilterTypes.franchise,
      }),
      ...franchiseFilters,
    ]);
  }

  @computed
  get franchiseFilterIndex(): number {
    let ind = -1;
    const franchiseFilters = this.basicFilters.appliedFilters.get(
      filterTypes.franchise
    );
    if (franchiseFilters) {
      const currentFranchise = franchiseFilters[0];
      if (currentFranchise) {
        this.displayFranchiseFilters.slice().forEach((filter, index) => {
          if (filter.filterId === currentFranchise.filterId) {
            ind = index;
          }
        });
      }
    }
    return ind;
  }
}
export default Entity;

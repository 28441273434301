/*
 * @flow
 */

import { observable, computed } from 'mobx'

import { getRandomColorForChannelsAndFranchise } from '../../../utils/ColorUtils'
import CommonMethods from '../../../../Common/utils/CommonMethods'
import type { APIChannelDetails } from '../../type'

class Channel {
  @observable amount: number
  @observable name: string
  @observable channelId: number
  @observable color: string
  @observable title: string

  constructor(channelDetails: APIChannelDetails) {
    this.channelId = channelDetails.channel_id
    this.name = channelDetails.name
    this.amount = channelDetails.amount

    this.color = getRandomColorForChannelsAndFranchise()
    this.title = this.name.slice(0, 2).toUpperCase()
  }

  @computed
  get formattedAmount(): any {
    return CommonMethods.toCurrency(this.amount)
  }
}
export default Channel

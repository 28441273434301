import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
    <svg width={width} height={height} viewBox="0 0 30 30" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx={15} cy={15} r={15} fill={fill} />
            <g fill="#FFF">
                <path d="M11.478 16.146v-.849h2.281c.026.002.097.007.208.007.351 0 1.23-.056 1.793-.574.364-.335.548-.824.548-1.453 0-.136-.013-.27-.04-.404h-4.79v-.808h4.397c-.198-.22-.464-.4-.792-.535a3.987 3.987 0 00-1.324-.272h-2.281v-.808h6.708v.808h-1.992c.277.228.495.499.649.807h1.343v.808h-1.103c.02.141.03.272.03.404 0 .867-.273 1.557-.812 2.05-.745.684-1.773.786-2.319.786-.15 0-.244-.008-.26-.01l-1.108.002 3.665 3.432-.55.591-4.251-3.982zm9.837-4.815l1.567-1.643-.554-.581-.899.942V6.111h-.783v3.938l-.899-.942-.554.58 1.567 1.643a.38.38 0 00.555 0" />
                <path d="M22.737 13.714a7.813 7.813 0 01-2.253 6.644 7.93 7.93 0 01-5.619 2.306 7.928 7.928 0 01-5.618-2.307 7.786 7.786 0 01-2.328-5.569c0-2.103.827-4.081 2.328-5.569a7.926 7.926 0 015.618-2.307 8.01 8.01 0 012.862.526c.152-.236.326-.456.522-.657a8.793 8.793 0 00-3.384-.67 8.737 8.737 0 00-6.19 2.542 8.582 8.582 0 00-2.564 6.135 8.58 8.58 0 002.564 6.136 8.736 8.736 0 006.19 2.541 8.733 8.733 0 006.19-2.541 8.583 8.583 0 002.445-7.562c-.24.141-.495.26-.763.352" />
            </g>
        </g>
    </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 24,
   height: 24,
   fill: Colors.amon
}
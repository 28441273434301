/*
 * @flow
 */

import { action, observable, computed } from "mobx";
import moment from "moment";
import type { Pagination } from "../../../Common/stores/types";
import PaginationStore from "../../../Common/stores/PaginationStore";
import {
  todayDate,
  sevenDaysBackDate,
  CALENDAR_FORMAT,
} from "../../../Common/utils/DateUtils";
import FilterItem from "../models/common/FilterItem";
import SortByItem from "../models/common/SortByItem";
import DateFilter from "../models/common/DateFilter";
import {
  SORT_TYPE_DESC,
  paymentSummaryFilterTypes,
  paymentSummarySortTypes,
} from "../../constants/BackendConstants";
import { dateFilterIndex } from "../../../Common/constants/DateFilterConstants";
import BasicFilters from "../models/common/BasicFilters";
import PaymentSummaryItem from "../models/common/PaymentSummaryItem/";
import { Services } from "../../services/";
import {
  type APIGetPaymentSummaryResponse,
  type APIGetPaymentSummaryRequest,
} from "../type";

class PaymentSummaryStore {
  @observable paginationStore: PaginationStore<PaymentSummaryItem>;
  @observable basicFilters: BasicFilters;
  @observable defaultSortOption: SortByItem;

  services: Services;
  constructor(services: Services) {
    this.initStore(services);
  }

  @action.bound
  initStore(services: Services) {
    this.services = services;
    this.basicFilters = new BasicFilters();
    this.defaultSortOption = new SortByItem({
      key: paymentSummarySortTypes.date,
      order: SORT_TYPE_DESC,
      name: "",
    });
    this.basicFilters.setSortBy(this.defaultSortOption);
    this.setDefaultDateFilter();
    this.setupPaginationStore();
  }

  @action.bound
  setDefaultDateFilter = () => {
    this.basicFilters.setDateFilter(
      new DateFilter({
        from_date: sevenDaysBackDate(),
        to_date: todayDate(),
      })
    );
    this.basicFilters.currentDateFilter.setDateFilterIndex(
      dateFilterIndex.lastWeek
    );
  };

  @action.bound
  clearPaymentSummaryStore() {
    this.initStore(this.services);
  }

  @action.bound
  setupPaginationStore() {
    this.paginationStore = new PaginationStore(PaymentSummaryItem);
    this.paginationStore.getEntitiesListAPI = this.services.revenueStoreService.getPaymentSummary;
    this.paginationStore.getStringEntityIndex = (item) => item.date;
    this.paginationStore.getEntitiesFromResponse = (response) =>
      response.results;
    this.paginationStore.getEntitiesCountFromResponse = (response) =>
      response.total;
    this.paginationStore.changeShowPerPage(10, false);
    this.paginationStore.getEntitiesCountFromResponse = this.getEntitiesCountFromResponse;
    this.paginationStore.requestTransformer = (
      pagination: Pagination,
      filters
    ): APIGetPaymentSummaryRequest => {
      const appliedFilters = [];
      filters.forEach((eachFilters) => {
        eachFilters.forEach((eachFilter) => {
          appliedFilters.push(eachFilter.requestObject);
        });
      });
      const request = {
        sort_by: [this.basicFilters.currentSortBy.requestObject],
        franchise_id: appliedFilters[0].filter_id,
        offset_limit: {
          offset: pagination.offset,
          limit: pagination.limit,
        },
      };
      if (!this.basicFilters.currentSortBy.isReset) {
        request.sort_by = [this.basicFilters.currentSortBy.requestObject];
      }
      if (!this.basicFilters.currentDateFilter.isReset) {
        request.date_range = {
          from_date: moment(
            this.basicFilters.currentDateFilter.fromDate
          ).format(CALENDAR_FORMAT),
          to_date: moment(this.basicFilters.currentDateFilter.toDate).format(
            CALENDAR_FORMAT
          ),
        };
      }
      if (
        this.basicFilters.currentFilter &&
        !this.basicFilters.currentFilter.isReset
      ) {
        request.filters = [this.basicFilters.currentFilter.requestObject];
      }

      return request;
    };
  }

  @computed
  get sortOptions(): Array<SortByItem> {
    return [this.defaultSortOption];
  }

  @action.bound
  onPagePress(page: number) {
    this.paginationStore.changeCurrentPage(page);
  }

  @action.bound
  onSortChanged(key: string) {
    if (key) {
      if (this.basicFilters.currentSortBy.sortId === key) {
        this.basicFilters.currentSortBy.toggleOrder();
      } else {
        const findSortItem = this.sortOptions.find(
          (eachSort) => eachSort.sortId === key
        );
        if (findSortItem) {
          this.basicFilters.setSortBy(findSortItem);
        }
      }
      this.paginationStore.reloadPagesData();
    }
  }

  @action.bound
  onDateFilterChanged(fromDate: string, toDate: string, index: number) {
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    this.paginationStore.reloadPagesData();
  }

  @action.bound
  setDefaultFranchiseFilter(franchiseId: number, name: string = "") {
    const formattedFilterObj = new FilterItem({
      name,
      filter_id: franchiseId,
      filter_type: paymentSummaryFilterTypes.franchise,
    });
    this.paginationStore.changeCurrentFilter(
      paymentSummaryFilterTypes.franchise,
      [formattedFilterObj],
      false
    );
  }

  @action.bound
  onFilterChanged(index: number, value: FilterItem) {
    this.paginationStore.changeCurrentFilter(value.filterType, [value], true);
  }

  @action.bound
  onFilterReset(index: number, value: FilterItem) {
    this.paginationStore.changeCurrentFilter(value.filterType, [], true);
  }

  @action.bound
  onReload() {
    this.paginationStore.reloadPagesData();
  }

  @action.bound
  getEntitiesCountFromResponse(response: APIGetPaymentSummaryResponse) {
    return response.total;
  }

  @action.bound
  onPaymentSummaryOpened(franchiseId, name) {
    this.setDefaultFranchiseFilter(franchiseId, name);
    this.paginationStore.reloadPagesData();
  }
}

export default PaymentSummaryStore;

/*
* @flow
* @author:Dinesh
*/

import { observable, action, computed } from 'mobx'
import { type APIFilterBy, type APIFilterByRequest } from '../../../type'

class FilterItem {
  @observable filterId: string
  @observable filterType: string
  @observable filterName: string

  constructor(filterItem: APIFilterBy) {
    this.filterId = filterItem.filter_id
    this.filterType = filterItem.filter_type
    this.filterName = filterItem.name
  }

  @action.bound
  setFilterId(filterId: string) {
    this.filterId = filterId
  }

  @action.bound
  setFilterType(filterType: string) {
    this.filterType = filterType
  }

  @action.bound
  setFilterName(filterName: string) {
    this.filterName = filterName
  }

  @computed
  get isReset(): boolean {
    if (this.filterId === -1) {
      return true
    }
    return false
  }

  @computed
  get requestObject(): APIFilterByRequest {
    return {
      filter_id: this.filterId,
      filter_type: this.filterType
    }
  }
}
export default FilterItem

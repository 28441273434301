/*
 * @flow
 * @author : Dinesh
 */
import { observable } from "mobx";
//TODO: tallam need to update
// import { processColor } from 'react-native'
import type { IObservableArray } from "mobx";
import GroupBarValue from "./GroupBarValue";

class BarDataSet {
  @observable values: IObservableArray<number>;
  @observable label: string;
  @observable config: Object;

  constructor(values: IObservableArray<number>, label: string, color: string) {
    this.values = values.map(
      (eachValue) => new GroupBarValue(eachValue, eachValue)
    );
    this.label = label;
    this.config = {};
    this.config.color = color;
    this.config.drawValues = false;
  }
}

export default BarDataSet;

/*
 * @flow
 */

import { observable, action, computed } from "mobx";

// import I18n from "react-native-i18n";
import RevenueChannels from "../RevenueChannels";
import ExpenditureChannels from "../ExpenditureChannels";

import ExpenditureLogsStore from "../../ExpenditureLogsStore";
import TransactionLogsStore from "../../TransactionLogsStore";
import PaymentSummaryStore from "../../PaymentSummaryStore";

import Entity from "../common/Entity/";
import { FRANCHISE_DEFAULT_ID } from "../../../constants/AppConstants";
import { summaryByTrasactionLogs } from "../../../constants/TransactionLogsConstants";
import type { SummaryByTransactionLogsType } from "../../../constants/TransactionLogsConstants";

import { summaryByTypes } from "../../../constants/ChannelConstants";
import type { SummaryByType } from "../../../constants/ChannelConstants";

class FranchiseEntity extends Entity {
  @observable name: string;
  @observable selectedTabSummaryBy: SummaryByType;
  @observable summarySectionFilter: BasicFilters;
  @observable summarySectionSortFilterIndex: number;
  @observable summarySectionDateFilterIndex: number;

  @observable selectedTabTransactionsSummaryBy: SummaryByTransactionLogsType;

  @observable revenueChannels: RevenueChannels;
  @observable expenditureChannels: ExpenditureChannels;

  @observable transactionLogsStore: TransactionLogsStore;
  @observable expenditureLogsStore: ExpenditureLogsStore;
  @observable paymentSummaryStore: PaymentSummaryStore;

  isFirstLoadCompleteForFranchiseEntity: boolean;

  constructor(name, franchiseId, entityType, services, ...other) {
    super(franchiseId, entityType, services, ...other);
    this.name = name;

    this.revenueChannels = new RevenueChannels(franchiseId, services);
    this.expenditureChannels = new ExpenditureChannels(franchiseId, services);

    this.transactionLogsStore = new TransactionLogsStore(services);
    this.expenditureLogsStore = new ExpenditureLogsStore(services);
    this.paymentSummaryStore = new PaymentSummaryStore(services);

    this.init();
  }

  /**
   * Generic Utils for the Store
   */

  @action.bound
  init() {
    this.summarySectionDateFilterIndex = this.revenueChannels.basicFilters.currentDateFilter.dateFilterIndex;
    this.selectedTabSummaryBy = summaryByTypes.revenueChannels;
    this.summarySectionFilter = this.revenueChannels.basicFilters;
    this.summarySectionSortFilterIndex = -1;

    this.selectedTabTransactionsSummaryBy = summaryByTrasactionLogs.revenueLogs;
    this.setIsFirstLoadCompleteForFranchiseEntity(false);
  }

  /**
   * SummaryBy Transaction Logs - Revenues/Expenditure Section Utils
   */

  @action.bound
  populateTransactionLogsBasedOnSelectedTab = (
    tab: string = this.selectedTabTransactionsSummaryBy
  ) => {
    if (tab === summaryByTrasactionLogs.revenueLogs) {
      this.transactionLogsStore.paginationStore.reloadPagesData();
    } else if (tab === summaryByTrasactionLogs.expenditureLogs) {
      this.expenditureLogsStore.paginationStore.reloadPagesData();
    }
  };

  @action.bound
  onLogsOpened(tab: string = this.selectedTabTransactionsSummaryBy) {
    if (tab === summaryByTrasactionLogs.revenueLogs) {
      this.transactionLogsStore.onTransactionLogsOpened(
        this.entityId,
        this.name
      );
    } else if (tab === summaryByTrasactionLogs.expenditureLogs) {
      this.expenditureLogsStore.onExpenditureLogsOpened(
        this.entityId,
        this.name
      );
    }
  }

  @action.bound
  onPaymentSummaryOpened() {
    this.paymentSummaryStore.onPaymentSummaryOpened(this.entityId, this.name);
  }

  @action.bound
  onChangeSelectedTransactionLogsTab(tab) {
    this.changeSummaryByTransactionLogsSelectedTab(tab);
    if (this.entityId === FRANCHISE_DEFAULT_ID) {
      this.populateTransactionLogsBasedOnSelectedTab();
    } else {
      this.onLogsOpened(tab);
    }
  }

  @computed
  get summaryByTransactionLogsTabs() {
    return [
      {
        key: summaryByTrasactionLogs.revenueLogs,
        value: "I18n.t(revenueModule.transactionsTab)",
      },
      {
        key: summaryByTrasactionLogs.expenditureLogs,
        value: "I18n.t(revenueModule.expenditureTab)",
      },
    ];
  }

  @action.bound
  changeSummaryByTransactionLogsSelectedTab(tab) {
    this.selectedTabTransactionsSummaryBy = tab;
  }

  /**
   * SummaryBy Revenues/Expenditure Section Utils
   */

  @computed
  get summaryByTabs() {
    return [
      {
        key: summaryByTypes.revenueChannels,
        value: "I18n.t(revenueModule.summaryByRevenueChannels)",
      },
      {
        key: summaryByTypes.expenditureChannels,
        value: "I18n.t(revenueModule.summaryByExpenditureChannels)",
      },
    ];
  }

  @action.bound
  onChangeSummaryByChannelsTab(
    tab: SummaryByType = this.selectedTabSummaryBy,
    postSetSummary?: Function
  ) {
    this.selectedTabSummaryBy = tab;

    this.populateSummaryByChannelsList(tab);

    if (postSetSummary) {
      postSetSummary();
    }
  }

  @action.bound
  populateSummaryByChannelsList(tab = this.selectedTabSummaryBy) {
    const revenueChannelsInstance = this.revenueChannels;
    const expenditureChannelsInstance = this.expenditureChannels;

    let channelsInstance;

    if (tab === summaryByTypes.revenueChannels) {
      channelsInstance = revenueChannelsInstance;
    } else if (tab === summaryByTypes.expenditureChannels) {
      channelsInstance = expenditureChannelsInstance;
    }

    this.summarySectionDateFilterIndex =
      channelsInstance.basicFilters.currentDateFilter.dateFilterIndex;
    this.summarySectionSortFilterIndex =
      channelsInstance.sortOptionsSelectedIndex;
    this.summarySectionFilter = channelsInstance.basicFilters;

    channelsInstance.populateChannelsListBasedOnSelectedTab();
  }

  /**
   * Generic Screen Utils
   */

  @action.bound
  onFranchiseEntityOpened() {
    this.onEntityDetailsOpened();
    this.populateSummaryByChannelsList();
    this.onLogsOpened();
    this.onPaymentSummaryOpened();

    this.setIsFirstLoadCompleteForFranchiseEntity(true);
  }

  @action.bound
  setIsFirstLoadCompleteForFranchiseEntity(value: boolean) {
    this.isFirstLoadCompleteForFranchiseEntity = value;
  }

  @action.bound
  onSwipeRefreshFranchiseEntitySummaryScreen() {
    this.onSwipeRefreshDetailsSection();
    this.populateSummaryByChannelsList();
    this.populateTransactionLogsBasedOnSelectedTab();
    this.onPaymentSummaryOpened();
  }
}
export default FranchiseEntity;

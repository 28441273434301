/**
 * @flow
 * @author: @ib/Chinmaya
 */
import styled from "@emotion/styled";
import Colors from "../../../Common/themes/Colors";
import tw from "tailwind.macro";

export const Container = styled.div`
  background-color: #f3f8ff;
`;

export const MainDashboard = styled.div`
  width: 100%;
  /* background-image: url("/images/loginV10.png"); */
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const RevenueFranchiseCards = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
`;

// export const MainContainer = styled.View`
//   flex: 1;
// `

// export const BackgroundImage = styled(Image)`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// `
// export const DashboardContentContainer = styled.ScrollView`
//   margin-horizontal: 20;
//   margin-vertical: 20;
// `
// export const DashboardSummaryItemsContainer = styled.View`
//   flex-direction: row;
//   justify-content: space-between;
// `

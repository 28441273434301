/*
 * @flow
 * Author: Prasanth
 */

import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'

import type { UserType } from '../../UserProfile/type'

class UserBaseProfile {
  @persist
  @observable
  profilePic: string
  @persist
  @observable
  name: string
  @persist
  @observable
  id: number
  @persist
  @observable
  userType: UserType

  getBaseUserData() {
    return {
      profilePic: this.profilePic,
      name: this.name,
      id: this.id,
      userType: this.userType
    }
  }

  @action.bound
  setProfilePic(profilePic: string) {
    this.profilePic = profilePic
  }

  @action.bound
  setName(name: string) {
    this.name = name
  }

  @action.bound
  setId(id: number) {
    this.id = id
  }

  @action.bound
  setUserType(type: UserType) {
    this.userType = type
  }
}
export default UserBaseProfile

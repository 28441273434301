import styled from "@emotion/styled";
import tw from "tailwind.macro";

export const SelectDatePickerMainDiv = styled.div`
  position: relative;
`;

export const SelectDatePickerDiv = styled.div`
  margin-bottom: 16px;
  border: solid 1px #b2b9c4;
  height: 34px;
  margin: 0 0 0 10px;
  padding: 6px 10px 6px 10px;
  border-radius: 3px;
  color: black;
  font-family: "CerebriSans";
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  outline: none;
  background-color: ${(props) => (props.isFocused ? "#0d47a1" : "#ffffff")};
  color: ${(props) => (props.isFocused ? "#ffffff" : "#333333")};
`;
export const SelectDatePickerInput = styled.input`
  width: 90px;
  background-color: inherit;
  color: inherit;
  font-family: "CerebriSans";
  font-size: 14px;
  display: flex;
  margin-right: 7px;
  margin-left: 10px;
  text-align: center;
  outline: none;
  cursor: pointer;
  user-select: none;
`;

export const DatePickerMainDiv = styled.div`
  position: relative;
`;

export const ShowDatePickerDiv = styled.div`
  position: absolute;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0 3px 4px 1px rgba(64, 80, 109, 0.32);
  border-radius: 3px;
  right: 0px;
  margin-top: 5px;
`;

export const PresetButton = styled.div`
  font-size: 14px;
  font-family: "CerebriSans";
  padding: 6px 482px 6px 20px;
  transform: rotate(0deg);
  box-shadow: inset 0 -1px 0 0 #dfe3e8;
  color: #13274b;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#ebedf0" : "#ffffff")};
  &:hover {
    background-color: rgba(235, 237, 240, 0.6);
  }
`;

export const ShowCustomDateDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 82px;
  background-color: #dfe3e8;
`;

export const CustomDatesTextP = styled.p`
  font-family: "CerebriSans";
  font-size: 14px;
  line-height: 1.43;
  color: #13274b;
  padding-left: 20px;
  padding-top: 7px;
`;

export const StartsOnEndsOnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "CerebriSans";
  color: #13274b;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 14px;
  padding-bottom: 9px;
`;

export const DateInput = styled.input`
  height: 32px;
  padding: 6px 8px;
  border-radius: 3px;
  border: solid 1px #b2b9c4;
  background-color: white;
`;

export const CancelApplyButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 52px;
  padding: 10px 0px 10px 372px;
  border-radius: 3px;
  background-color: #ebedf0;
  font-family: "CerebriSans";
  font-size: 14px;
`;

export const CancelButton = styled.button`
  height: 32px;
  border: none;
  outline: none;
  color: #13274b;
  padding: 6px 18px;
  font-weight: 500;
  outline: none;
  :focus {
    outline: 0 !important;
  }
`;

export const ApplyButton = styled.button`
  height: 32px;
  margin: 0 20px 0 14px;
  padding: 6px 18px;
  border-radius: 3px;
  background-color: #004acd;
  font-weight: 500;
  color: white;
  outline: none;
  :focus {
    outline: 0 !important;
  }
`;

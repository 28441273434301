/**
 * @flow
 * @author: Anesh Parvatha
 */

import { observable, action } from 'mobx'
import {
  LANGUAGE_TYPE_ENGLISH,
  LANGUAGE_TYPE_HINDI,
  LANGUAGE_TYPE_TELUGU,
  LANGUAGE_TYPE_TAMIL,
  LANGUAGE_TYPE_KANNADA
} from '../../../FranchiseLogin/constants/BackendConstants'
import { DEFAULT_LANGUAGE_CODE } from '../../../FranchiseLogin/constants/AppConstants'

class SettingsStore {
  @observable appLanguage: string

  constructor() {
    this.appLanguage = this.getLocaleFromLanguage('')
  }

  @action.bound
  setAppLanguage(language: string) {
    this.appLanguage = this.getLocaleFromLanguage(language)
  }

  getLocaleFromLanguage(language: string) {
    switch (language) {
      case LANGUAGE_TYPE_ENGLISH:
        return DEFAULT_LANGUAGE_CODE
      case LANGUAGE_TYPE_HINDI:
        return 'hi'
      case LANGUAGE_TYPE_TELUGU:
        return 'te'
      case LANGUAGE_TYPE_TAMIL:
        return 'ta'
      case LANGUAGE_TYPE_KANNADA:
        return 'kn'
      default:
        return DEFAULT_LANGUAGE_CODE
    }
  }
}

const settingsStore = new SettingsStore()
export { settingsStore as default, SettingsStore }

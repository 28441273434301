/*
* @flow
* @author: Dinesh
*/

export const ENTITY_TYPE_FRANCHISE = 'FRANCHISE'
export const ENTITY_TYPE_ALL_FRANCHISES = 'OVERALL'

export const entityTypes = {
  allFranchises: ENTITY_TYPE_ALL_FRANCHISES,
  singleFranchise: ENTITY_TYPE_FRANCHISE
}

export const FILTER_TYPE_FRANCHISE = 'FRANCHISE'
export const FILTER_TYPE_CHANNEL = 'CHANNEL'

export const filterTypes = {
  franchise: FILTER_TYPE_FRANCHISE,
  channel: FILTER_TYPE_CHANNEL
}

export const SORT_TYPE_ASC = 'ASC'
export const SORT_TYPE_DESC = 'DESC'

export const NAME_SORT_OPTION = 'NAME'
export const AMOUNT_SORT_OPTION = 'AMOUNT'

export const transactionLogSortTypes = {
  date: 'DATE',
  paymentMode: 'PAYMENT_MODE',
  channel: 'CHANNEL',
  franchise: 'FRANCHISE',
  transactedAmount: 'TRANSACTED_AMOUNT',
  toFranchise: 'TO_FRANCHISE',
  expenditureAmount: 'AMOUNT'
}

export const transactionLogFilterTypes = {
  franchise: 'FRANCHISE',
  channel: 'CHANNEL'
}

export const paymentSummaryFilterTypes = {
  franchise: 'FRANCHISE'
}

export const paymentSummarySortTypes = {
  date: 'DATE'
}

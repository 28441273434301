/**
 * @flow
 * @author: Prasanth
 */

import { observable } from 'mobx'
import type { APIFranchise } from '../../../type'

class Franchise {
  @observable franchiseId: number
  @observable name: string

  constructor(franchise: APIFranchise) {
    this.franchiseId = franchise.franchise_id
    this.name = franchise.name
  }
}

export default Franchise

import * as React from 'react'

import Colors from '../../themes/Colors'

function SvgComponent(props) {
   const { width, height, fill } = props
   return (
        <svg width={width} height={height} viewBox="0 0 24 21" {...props}>
            <defs>
            <path id="a" d="M.209 1.969h10.594V.3H.209z" />
            <path id="c" d="M0 1.91V.241h2.931V1.91H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
            <path
                fill="#FCD249"
                d="M19.942 19.79a6.921 6.921 0 01-9.444-2.53 6.921 6.921 0 012.53-9.444 6.923 6.923 0 019.445 2.53c1.905 3.301.77 7.538-2.531 9.444"
            />
            <path
                fill="#F9B938"
                d="M11.606 16.62c-1.799-3.115-.89-7.061 1.991-9.098a6.923 6.923 0 00-3.099 9.738 6.92 6.92 0 009.983 2.185c-3.204 1.476-7.077.29-8.875-2.825"
            />
            <path
                fill="#F9B938"
                d="M13.668 8.923a5.641 5.641 0 00-2.062 7.697 5.64 5.64 0 007.696 2.062 5.64 5.64 0 002.062-7.696 5.64 5.64 0 00-7.696-2.063"
            />
            <path
                fill="#E59C1C"
                d="M12.634 16.026a5.643 5.643 0 011.564-7.373 5.641 5.641 0 00-2.592 7.967 5.64 5.64 0 008.195 1.738 5.642 5.642 0 01-7.167-2.332m2.012-12H1.348a.597.597 0 00-.596.596V5.1c0 .327.268.595.596.595h13.298a.597.597 0 00.596-.595v-.478a.597.597 0 00-.596-.596"
            />
            <path
                fill="#F9B938"
                d="M15.837 2.357H2.54a.598.598 0 00-.597.596v.478c0 .327.269.595.597.595h13.297a.597.597 0 00.596-.595v-.478a.597.597 0 00-.596-.596"
            />
            <path
                fill="#E59C1C"
                d="M14.646.688H1.348a.597.597 0 00-.596.596v.477c0 .328.268.596.596.596h13.298a.597.597 0 00.596-.596v-.477a.597.597 0 00-.596-.596"
            />
            <g transform="translate(1.735 18.748)">
                <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
                </mask>
                <path
                fill="#F9B938"
                d="M.805.3a.597.597 0 00-.596.596v.478c0 .327.268.596.596.596h9.998c-1.717 0-2.697-.63-3.907-1.67H.805z"
                mask="url(#b)"
                />
            </g>
            <path
                fill="#E59C1C"
                d="M7.215 17.378H1.348a.598.598 0 00-.596.596v.478c0 .327.268.596.596.596H8.63a6.956 6.956 0 01-1.415-1.67"
            />
            <path
                fill="#F9B938"
                d="M6.484 15.709H2.54a.598.598 0 00-.596.596v.478c0 .328.268.595.596.595h4.674a6.864 6.864 0 01-.73-1.669"
            />
            <path
                fill="#E59C1C"
                d="M6.22 14.041H3.63a.596.596 0 00-.595.595v.478c0 .327.268.596.596.596h2.853a6.925 6.925 0 01-.263-1.67"
            />
            <path
                fill="#F9B938"
                d="M6.366 12.371H1.348a.597.597 0 00-.596.596v.478c0 .327.268.596.596.596H6.22c-.02-.556.027-1.11.145-1.67"
            />
            <path
                fill="#E59C1C"
                d="M6.952 10.702H2.54a.598.598 0 00-.596.596v.478c0 .327.268.595.596.595h3.826a6.874 6.874 0 01.586-1.669"
            />
            <path
                fill="#F9B938"
                d="M8.13 9.033H2.54a.597.597 0 00-.596.596v.478c0 .327.268.595.596.595h4.412A6.968 6.968 0 018.13 9.033M15.242 6.769c-.004-.451.027-.58-.072-.761a.6.6 0 00-.524-.313H1.348a.598.598 0 00-.596.596c.018.34-.042.512.053.722a.597.597 0 00.543.351h13.298a.595.595 0 00.596-.595"
            />
            <path
                fill="#E59C1C"
                d="M10.612 7.364H3.63a.597.597 0 00-.596.596v.478c0 .327.268.596.596.596h4.498a6.952 6.952 0 012.483-1.67"
            />
            <path
                fill="#D88D16"
                d="M3.085 5.099v-.477c0-.328.268-.596.596-.596H1.348a.597.597 0 00-.596.596v.477c0 .328.268.596.596.596h2.333a.597.597 0 01-.596-.596"
            />
            <path
                fill="#E59C1C"
                d="M4.277 3.43v-.478c0-.327.268-.595.595-.595H2.54a.598.598 0 00-.597.595v.478c0 .328.269.596.597.596h2.332a.597.597 0 01-.595-.596"
            />
            <path
                fill="#D88D16"
                d="M3.085 1.761v-.477c0-.328.268-.596.596-.596H1.348a.597.597 0 00-.596.596v.477c0 .328.268.596.596.596h2.333a.597.597 0 01-.596-.596"
            />
            <path
                fill="#E59C1C"
                d="M4.277 20.121v-.478c0-.328.268-.596.595-.596H2.54a.598.598 0 00-.597.596v.478c0 .327.269.596.597.596h2.332a.598.598 0 01-.595-.596"
            />
            <path
                fill="#D88D16"
                d="M3.085 18.451v-.477c0-.328.268-.596.596-.596H1.348a.597.597 0 00-.596.596v.477c0 .328.268.597.596.597h2.333a.598.598 0 01-.596-.597"
            />
            <path
                fill="#E59C1C"
                d="M4.277 16.783v-.478c0-.327.268-.596.595-.596H2.54a.599.599 0 00-.597.596v.478c0 .327.269.595.597.595h2.332a.597.597 0 01-.595-.595"
            />
            <path
                fill="#D88D16"
                d="M5.368 15.114v-.478c0-.328.268-.596.596-.596H3.63a.597.597 0 00-.596.596v.478c0 .327.268.595.596.595h2.333a.597.597 0 01-.596-.595"
            />
            <path
                fill="#E59C1C"
                d="M3.085 13.444v-.477c0-.328.268-.596.596-.596H1.348a.597.597 0 00-.596.596v.477c0 .328.268.597.596.597h2.333a.598.598 0 01-.596-.597"
            />
            <path
                fill="#D88D16"
                d="M4.277 11.775v-.477c0-.328.268-.596.595-.596H2.54a.598.598 0 00-.597.596v.477c0 .328.269.596.597.596h2.332a.597.597 0 01-.595-.596"
            />
            <path
                fill="#E59C1C"
                d="M4.277 10.107v-.479c0-.328.268-.596.595-.596H2.54a.598.598 0 00-.597.596v.478c0 .327.269.595.597.595h2.332a.597.597 0 01-.595-.595"
            />
            <g transform="translate(.75 5.454)">
                <mask id="d" fill="#fff">
                <use xlinkHref="#c" />
                </mask>
                <path
                fill="#E59C1C"
                d="M2.388 1.559c-.095-.21-.035-.381-.053-.722 0-.328.269-.596.596-.596H.598a.598.598 0 00-.596.596c.018.34-.041.513.053.722a.597.597 0 00.543.351h2.333a.598.598 0 01-.543-.351"
                mask="url(#d)"
                />
            </g>
            <path
                fill="#D88D16"
                d="M5.368 8.438V7.96c0-.328.268-.596.596-.596H3.63a.597.597 0 00-.596.596v.477c0 .328.268.596.596.596h2.333a.597.597 0 01-.596-.596"
            />
            <path
                fill="#FCD249"
                d="M15.994 19.79a6.922 6.922 0 01-9.444-2.53 6.921 6.921 0 012.53-9.444c3.302-1.906 7.539-.771 9.445 2.53 1.906 3.301.77 7.538-2.53 9.444"
            />
            <path
                fill="#F9B938"
                d="M7.658 16.62A6.921 6.921 0 019.65 7.522a7.14 7.14 0 00-.57.293c-3.3 1.907-4.436 6.143-2.53 9.445 1.906 3.301 6.143 4.437 9.444 2.53.187-.108.367-.223.539-.345-3.204 1.476-7.077.29-8.875-2.825"
            />
            <path
                fill="#F9B938"
                d="M9.72 8.923a5.642 5.642 0 00-2.062 7.697 5.64 5.64 0 007.696 2.062 5.64 5.64 0 002.063-7.696A5.642 5.642 0 009.72 8.923"
            />
            <path
                fill="#E59C1C"
                d="M8.687 16.026a5.644 5.644 0 011.563-7.373 5.642 5.642 0 00-2.592 7.967 5.641 5.641 0 008.195 1.738 5.64 5.64 0 01-7.166-2.332"
            />
            <path
                fill="#FFF"
                d="M11.324 10.702h-.809v.81h1.214c.526 0 .971.338 1.138.808h-2.352v.809h2.352a1.21 1.21 0 01-1.138.808h-.809v.976l2.328 2.32.572-.572-1.93-1.922a2.023 2.023 0 001.818-1.61h.851v-.81h-.85c-.06-.3-.194-.572-.373-.808h1.223v-.809h-3.235z"
            />
            <path d="M-3-4h30v30H-3z" />
            </g>
        </svg>
   )
}

export default SvgComponent

SvgComponent.defaultProps = {
   width: 24,
   height: 24,
   fill: Colors.white
}
/*
 * @flow
 */

import { action, observable, reaction } from "mobx";
// import I18n from "react-native-i18n";

import {
  API_INITIAL,
  API_FETCHING,
  API_SWIPE_REFRESH,
} from "@ib/api-constants";
import type { APIStatus } from "@ib/api-constants";

// import { Actions } from "react-native-router-flux";
import { bindPromiseWithOnSuccess } from "../../Common/utils/MobxPromise";

import userProfile from "../../FranchiseLogin/stores/UserProfile";
import { entityTypes } from "../constants/BackendConstants";

import { FRANCHISE_DEFAULT_ID } from "../constants/AppConstants";
import { REVENUE_SUMMARY_SCREEN } from "../constants/NavigationConstants";
import DateFilter from "../stores/models/common/DateFilter";

import { sevenDaysBackDate, todayDate } from "../../Common/utils/DateUtils";
import { dateFilterIndex } from "../../Common/constants/DateFilterConstants";

import revenueService, { Services } from "../services/";
import FranchiseEntity from "./models/FranchiseEntity";

import type { APIGetRevenueSummaryRequest } from "./type";

const defaultDateFilter = new DateFilter({
  from_date: sevenDaysBackDate(),
  to_date: todayDate(),
});
defaultDateFilter.setDateFilterIndex(dateFilterIndex.lastWeek);

class RevenueStore {
  @observable selectedFranchiseEntity: FranchiseEntity;
  @observable franchiseEntityList: IObservableArray<FranchiseEntity> = [];

  @observable entityDetails: FranchiseEntity;
  @observable revenueHomeStatus: APIStatus;

  @observable refreshing: APIStatus;

  @observable error: any;
  @observable swipeRefreshError: any;

  services: Services;

  constructor(services: Services) {
    this.revenueStoreInit(services);
  }

  /**
   * Generic Utils for the Store
   */

  @action.bound
  clearRevenueManagementStores() {
    this.revenueStoreInit(this.services);
  }

  @action.bound
  revenueStoreInit(services: Services) {
    this.services = services;
    this.revenueHomeStatus = API_INITIAL;
    this.refreshing = API_INITIAL;
    this.error = undefined;
    this.swipeRefreshError = undefined;
    this.franchiseEntityList = [];

    this.populateInitialData();
  }

  @action.bound
  reset() {
    this.populateInitialData();
  }

  @action.bound
  populateInitialData() {
    this.entityDetails = new FranchiseEntity(
      't("franchiseCommon.all")',
      FRANCHISE_DEFAULT_ID,
      entityTypes.allFranchises,
      this.services,
      defaultDateFilter
    );
    this.setAllFranchisesAsSelectedEntity();
  }

  /**
   * SwipeRefresh Utils for Home Screen
   */

  @action.bound
  onSwipeRefreshRevenueSummaryScreen() {
    this.getRevenueSummaryAPI(this.setRefreshStatus, this.setRefreshError, {
      loadingStatus: API_SWIPE_REFRESH,
    });
    this.entityDetails.populateSummaryByChannelsList();
    this.entityDetails.populateTransactionLogsBasedOnSelectedTab();
    this.entityDetails.onPaymentSummaryOpened();
  }

  @action.bound
  setRefreshStatus(status: APIStatus) {
    this.refreshing = status;
  }

  @action.bound
  setRefreshError(error) {
    this.swipeRefreshError = error;
  }

  /**
   * Generic Utils for the Home Screen
   */

  @action.bound
  setFranchiseEntityList() {
    this.franchiseEntityList = userProfile.franchiseDetails.map((eachItem) => {
      const entity = new FranchiseEntity(
        eachItem.franchiseName,
        eachItem.franchiseId,
        entityTypes.singleFranchise,
        this.services,
        defaultDateFilter
      );
      return entity;
    });

    this.franchiseEntityList.unshift(this.entityDetails);
  }

  @action.bound
  setAllFranchisesAsSelectedEntity() {
    this.selectedFranchiseEntity = this.entityDetails;
  }

  @action.bound
  onRevenueSummarySectionReload() {
    this.getRevenueSummaryAPI();
  }

  @action.bound
  onRevenuePageOpened() {
    if (this.franchiseEntityList.length === 0) {
      this.setFranchiseEntityList();
    }

    this.getRevenueSummaryAPI();
    this.entityDetails.populateSummaryByChannelsList();
    this.entityDetails.populateTransactionLogsBasedOnSelectedTab();
    this.entityDetails.onPaymentSummaryOpened();

    this.entityDetails.setIsFirstLoadCompleteForFranchiseEntity(true);
  }

  /**
   * Get RevenueSummary
   */

  @action.bound
  setRevenueHomeStatus(status: APIStatus) {
    this.revenueHomeStatus = status;
  }

  @action.bound
  setRevenueHomeError(error) {
    this.error = error;
  }

  @action.bound
  updateRevenueSummaryDetails(response) {
    this.entityDetails.setBasicDetails(response);
  }

  @action.bound
  async getRevenueSummaryAPI(
    setAPIStatus = this.setRevenueHomeStatus,
    onFailure = this.setRevenueHomeError,
    options?: any = { loadingStatus: API_FETCHING }
  ): Promise<APIFranchiseSummaryResponse> {
    const req = this.getRevenueSummaryRequest();
    const revenueSummaryPromise = this.services.revenueStoreService.getRevenueSummary(
      req
    );

    return bindPromiseWithOnSuccess(revenueSummaryPromise, options)
      .to(setAPIStatus, this.updateRevenueSummaryDetails)
      .catch(onFailure);
  }

  @action.bound
  getRevenueSummaryRequest(): APIGetRevenueSummaryRequest {
    const appliedFilters = [];
    this.entityDetails.graphsAndAnalytics.inflowVsOutFlow.basicFilters.appliedFilters.forEach(
      (filters) => {
        filters.forEach((eachFilter) => {
          appliedFilters.push(eachFilter.requestObject);
        });
      }
    );

    const revenueSummaryRequest = {
      expenditure: {
        date_range: this.entityDetails.graphsAndAnalytics.expenditures
          .basicFilters.currentDateFilter.requestData,
      },
      revenue: {
        date_range: this.entityDetails.graphsAndAnalytics.revenues.basicFilters
          .currentDateFilter.requestData,
      },
      inflow_vs_outflow: {
        date_range: this.entityDetails.graphsAndAnalytics.inflowVsOutFlow
          .basicFilters.currentDateFilter.requestData,
        filters: appliedFilters,
      },
    };

    return revenueSummaryRequest;
  }

  /**
   * Franchises Tab bar Utils
   */

  @action.bound
  changeSelectedFranchiseWithFranchiseId = (selectedFranchiseId: number) => {
    const selectedFranchiseEntity = this.franchiseEntityList.find(
      (eachFranchise) => selectedFranchiseId === eachFranchise.entityId
    );
    this.selectedFranchiseEntity = selectedFranchiseEntity;
  };

  onFranchiseChange = (
    selectedFranchiseEntity = this.selectedFranchiseEntity
  ) => {
    if (
      selectedFranchiseEntity.entityId === -1 &&
      !selectedFranchiseEntity.isFirstLoadCompleteForFranchiseEntity
    ) {
      this.onRevenuePageOpened();
      // requestAnimationFrame(() => {
      // });
    } else if (!selectedFranchiseEntity.isFirstLoadCompleteForFranchiseEntity) {
      selectedFranchiseEntity.onFranchiseEntityOpened();
      // requestAnimationFrame(() => {
      // });
    }
  };

  // disposeRevenueSummaryScreen = reaction(
  //   () => Actions.currentScene,
  //   (scene) => {
  //     if (scene === REVENUE_SUMMARY_SCREEN) {
  //       this.onFranchiseChange();
  //     }
  //   }
  // );

  // disposeSelectedFranchiseEntity = reaction(
  // () => this.selectedFranchiseEntity,
  // (selectedFranchiseEntity) => {
  // if (Actions.currentScene === REVENUE_SUMMARY_SCREEN) {
  // this.onFranchiseChange();
  // }
  // }
  // );
}

const revenueStore = new RevenueStore(revenueService);
export { revenueStore as default, RevenueStore };

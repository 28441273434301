/*
 *
 * @flow
 * Author: Prasanth
 */

import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import APIConstants from "@ib/api-constants";

import { observable, action, reaction, computed, toJS } from "mobx";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import type { AuthStore } from "../../stores/AuthStore";
import type { UserProfile } from "../../stores/UserProfile";

import { validateOTP } from "../../utils/validationUtils";
import { USER_TYPE_OWNER } from "../../constants/BackendConstants";
import { FRANCHISE_DASHBOARD_SCREEN } from "../../../constants/RouteConstants";

import {
  SignInOTPPage,
  LoginOTPPageDiv,
  LogoOTP,
  LogoOTPText,
  ErrorMessage,
  LoginOTPForm,
  Label,
  InputBar,
  SubmitButton,
} from "./styledComponents";
import Loader from "../../../Common/Icons/Loader";
import { setCookie, ACCESS_TOKEN } from "../../utils/StorageUtils";

// import styles from "./styles";

// const ButtonWhiteText = styled(Button)`
//   color: ${Colors.white};
// `;

let __DEV__ = true;

@inject("authStore", "userProfile")
@observer
class LoginOtpScreen extends Component<{
  authStore: AuthStore,
  userProfile: UserProfile,
}> {
  @observable otp = "";
  @observable displayError = false;
  @observable errorText = "";
  @observable showResendOtpLoader = false;

  componentWillUnmount() {
    if (this.resendOtpReaction) {
      this.resendOtpReaction();
      this.resendOtpReaction = null;
    }
    if (this.serverLoginErrorReaction) {
      this.serverLoginErrorReaction();
      this.serverLoginErrorReaction = null;
    }
  }

  resendOtpReaction = reaction(
    () => this.props.authStore.userLoginOtpResendStatus,
    (userLoginOtpResendStatus) => {
      if (userLoginOtpResendStatus === APIConstants.API_FETCHING) {
        this.setResendOtpLoader(true);
      } else if (userLoginOtpResendStatus === APIConstants.API_SUCCESS) {
        this.setResendOtpLoader(false);
      } else {
        this.setResendOtpLoader(false);
      }
    }
  );

  serverLoginErrorReaction = reaction(
    () => this.props.authStore.userLoginAPIError,
    (userLoginAPIError) => {
      if (userLoginAPIError === "") {
        this.setDisplayError(false);
      } else {
        this.setDisplayError(true);
        this.setErrorText(userLoginAPIError);
      }
    }
  );

  setResendOtpLoader = action((bool) => {
    this.showResendOtpLoader = bool;
  });

  onChangeText = action((e) => {
    this.setDisplayError(false);
    this.otp = e.target.value;
  });

  setDisplayError = action((bool) => {
    this.displayError = bool;
  });

  setErrorText = action((error) => {
    this.errorText = error;
  });

  callLoginOtpVerifyAPI = async (e) => {
    e.preventDefault();
    const {
      authStore,
      userProfile,
      // chatStores,
      // notificationsStore,
    } = this.props;
    const validationResponse = validateOTP(this.otp);
    if (validationResponse) {
      this.setDisplayError(true);
      this.setErrorText(validationResponse);
    } else {
      this.setDisplayError(false);
      try {
        await authStore.userLoginVerify(this.otp);
        switch (authStore.userLoginVerifyStatus) {
          case APIConstants.API_SUCCESS:
            if (userProfile.userType === USER_TYPE_OWNER) {
              // initializeIntercomChat(userProfile);
              try {
                /*
               Create mqtt connection after login success and add fcm token
             */
                // chatStores.createStoresAndGetOperators();
                // await notificationsStore.onAddUserToken();
                // notificationsStore.setRegisteredUserToken(true);
              } catch (e) {
                // notificationsStore.setRegisteredUserToken(false);
              }

              if (userProfile.additionalAttributes.length === 0) {
                // gotoProfileConfirmationScene({
                //   type: "reset",
                //   hideNavBar: true,
                // });

                //TODO: Need to navigate to profile screen here
                const accessToken = JSON.parse(
                  window.localStorage.refreshToken
                );
                setCookie(ACCESS_TOKEN, accessToken.accessToken);
                this.props.history.push(FRANCHISE_DASHBOARD_SCREEN);
              } else {
                // goToDashboard(userProfile);
                const accessToken = JSON.parse(
                  window.localStorage.refreshToken
                );
                setCookie(ACCESS_TOKEN, accessToken.accessToken);
                this.props.history.push(FRANCHISE_DASHBOARD_SCREEN);
              }
            } else {
              // showToast(I18n.t("franchiseLogin.notValidUser"));
              authStore.userLogOutApi();
            }

            break;

          default:
            if (__DEV__) console.log("OTP Verification failed");
            this.setDisplayError(true);
            break;
        }
      } catch (e) {
        if (__DEV__) console.log(e);
      }
    }
  };

  callResendOtp = () => {
    this.props.authStore.resendUserLoginOtp();
    // requestAnimationFrame(() => {
    // });
  };

  goBack = () => {
    // requestAnimationFrame(() => {
    //   navigateBack();
    // });
  };

  @computed
  get animating(): boolean {
    const {
      userLoginVerifyStatus,
      userLoginOtpResendStatus,
    } = this.props.authStore;
    if (
      userLoginVerifyStatus === APIConstants.API_FETCHING ||
      userLoginOtpResendStatus === APIConstants.API_FETCHING ||
      userLoginVerifyStatus === APIConstants.API_SUCCESS
    ) {
      return true;
    }
    return false;
  }

  // displaySuccessMsg = () => (
  //   <OtpSuccessView
  //     style={styles.otpSuccessOuterView}
  //     childStyle={styles.otpSuccessInnerView}
  //   >
  //     <Image source={Images.tick} style={styles.tickImage} />
  //     <Button>{I18n.t("franchiseLogin.otpResentMsg")}</Button>
  //   </OtpSuccessView>
  // );

  // displayNoMsg = () => (
  //   <View style={[styles.otpSuccessView, styles.noMsgView]} />
  // );

  render() {
    const { t, authStore } = this.props;
    const { userLoginOtpResendStatus, userLoginVerifyStatus } = authStore;
    const successStatus = userLoginOtpResendStatus === APIConstants.API_SUCCESS;
    const shouldDisplayLoader =
      userLoginVerifyStatus === APIConstants.API_FETCHING ||
      userLoginOtpResendStatus === APIConstants.API_FETCHING;
    return (
      <SignInOTPPage>
        <LoginOTPPageDiv>
          <LogoOTP src="/images/i-bc-nav-icon.png" />
          <LogoOTPText>{t("franchiseLogin.otpHeading")}</LogoOTPText>
          <LoginOTPForm onSubmit={this.callLoginOtpVerifyAPI}>
            <Label>{t("franchiseLogin.otpSubHeading")}</Label>
            <InputBar
              isErrorPresent={this.displayError}
              placeholder={t("franchiseLogin.otpPlaceholder")}
              type="number"
              onChange={this.onChangeText}
            />
            <ErrorMessage displayError={this.displayError}>
              {this.displayError ? this.errorText : "error"}
            </ErrorMessage>
            <SubmitButton type="submit">
              {shouldDisplayLoader ? (
                <Loader color="white" height={25} width={25} />
              ) : (
                t("franchiseLogin.otpButtonText")
              )}
            </SubmitButton>
          </LoginOTPForm>
        </LoginOTPPageDiv>
      </SignInOTPPage>
    );
  }
}

export default withRouter(withTranslation("franchiseLogin")(LoginOtpScreen));

{
  /* <div>
        <form onSubmit={this.callLoginOtpVerifyAPI}>
          <input
            type="number"
            style={{ border: "1px solid black" }}
            onChange={this.onChangeText}
            placeholder="enter otp"
          />
          <button type="submit">Submit</button>
        </form>
        {this.props.authStore.userLoginOtpResendStatus ===
        APIConstants.API_SUCCESS ? (
          <div>success</div>
        ) : null}
      </div> */
}

{
  // import type { NotificationStore } from "../../../Notifications/stores/NotificationStore";

  // import { initializeIntercomChat } from "../../../Chat/utils/IntercomChatUtils";
  // import { OTP_MAX_LENGTH } from "../../constants/AppConstants";

  // import I18n from 'react-native-i18n'
  // import { showToast } from "../../../Common/utils/ToastUtils";
  // import LoadingView from "../../../Common/components/LoadingView";
  // import TextInputField from "../../../Common/components/TextInputField";
  // import LoginBlackThemeBackground from "../../styledComponents/View/LoginBlackThemeBackground";
  // import {
  //   Button,
  //   DisplayBook24W,
  // } from "../../../Common/styledComponents/Text/StyledTexts";
  // import LinkButton from "../../../Common/styledComponents/Button/LinkButton";
  // import ButtonWithLoader from "../../../Common/components/ButtonWithLoader/ButtonWithLoader";
  // import ButtonConstants from "../../../Common/styledComponents/Button/constants";
  // import OtpSuccessView from "../../styledComponents/View/OtpSuccessView";

  // import Colors from "../../../Common/themes/Colors";
  // import Images from "../../../Common/themes/Images";

  // import { navigateBack } from "../../../Common/utils/NavigationUtils";
  // import { goToDashboard } from "../../../Dashboard/utils/NavigationUtils";
  // import { gotoProfileConfirmationScene } from "../../utils/LoginNavigationUtils";
  // import type ChatStores from "../../../Chat/stores/ChatStores";

  {
    /* <div>
        <form onSubmit={this.callLoginOtpVerifyAPI}>
          <input
            type="number"
            style={{ border: "1px solid black" }}
            onChange={this.onChangeText}
            placeholder="enter otp"
          />
          <button type="submit">Submit</button>
        </form>
        {this.props.authStore.userLoginOtpResendStatus ===
        APIConstants.API_SUCCESS ? (
          <div>success</div>
        ) : null}
      </div> */
  }

  /* <LoginBlackThemeBackground>
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollView}
          extraScrollHeight={100}
          keyboardShouldPersistTaps="handled"
        >
          <View>
            <Image
              source={Images.ibcLogo}
              resizeMode="contain"
              style={styles.image}
            />

            <DisplayBook24W style={styles.displayBook}>
              {I18n.t("franchiseLogin.otpHeading")}
            </DisplayBook24W>
            <TextInputField
              label={I18n.t("franchiseLogin.otpSubHeading")}
              viewStyle={styles.formField}
              labelTextSize={TextInputField.largeLabel}
              text={this.otp.get()}
              onChangeText={this.onChangeText}
              placeholder={I18n.t("franchiseLogin.otpPlaceholder")}
              keyboardType="phone-pad"
              returnKeyType="next"
              onSubmitEditing={this.callLoginOtpVerifyAPI}
              // disabled={this.animating} @pavan Commented because we dont know disabed styleguide
              secureTextEntry
              errorEnabled={this.displayError.get()}
              helpText={this.errorText.get()}
              theme={TextInputField.theme.dark}
              maxLength={OTP_MAX_LENGTH}
            />
            <ButtonWithLoader
              onPress={this.callLoginOtpVerifyAPI}
              buttonStyle={styles.button}
              disabled={this.animating}
              loadingStatus={authStore.userLoginVerifyStatus}
              type={ButtonConstants.FULL_WIDTH_TYPE}
              progressColor={Colors.white}
            >
              <ButtonWhiteText style={styles.buttonText}>
                {I18n.t("franchiseLogin.otpButtonText")}
              </ButtonWhiteText>
            </ButtonWithLoader>
            <View style={styles.linksView}>
              <LinkButton
                onPress={this.callResendOtp}
                buttonStyle={styles.linkButton}
                textStyle={styles.linkText}
              >
                {I18n.t("franchiseLogin.resendOtpText")}
              </LinkButton>
              {this.showResendOtpLoader.get() ? (
                <View>
                  <LoadingView
                    mainViewStyle={styles.loader}
                    progressSize={16}
                    progressThickness={1.5}
                  />
                </View>
              ) : null}
              <LinkButton
                onPress={() => null}
                buttonStyle={styles.linkButton}
                textStyle={styles.dividerStyle}
              >
                {I18n.t("franchiseLogin.divider")}
              </LinkButton>
              <LinkButton
                onPress={this.goBack}
                buttonStyle={styles.linkButton}
                textStyle={styles.linkText}
              >
                {I18n.t("franchiseLogin.wrongUsernameText")}
              </LinkButton>
            </View>
          </View>

          {this.props.authStore.userLoginOtpResendStatus ===
          APIConstants.API_SUCCESS
            ? this.displaySuccessMsg()
            : this.displayNoMsg()}
        </KeyboardAwareScrollView>
      </LoginBlackThemeBackground> */
}

/*
 * @flow
 * @author: Mani,Dinesh
 */

import { action, observable, computed } from "mobx";
// import I18n from 'react-native-i18n'
import { API_INITIAL } from "../../../../../Common/constants/AppAPIConstants";
import { bindPromiseWithOnSuccess } from "../../../../../Common/utils/MobxPromise";
import type { APIStatus } from "../../../../../Common/constants/AppAPIConstants";
import FilterItem from "../FilterItem";
import BasicFilters from "../../common/BasicFilters/";
import LineGraphData from "../../../../../Common/stores/GraphsData/LineGraph/LineGraphData";
import LineGraphDataSet from "../../../../../Common/stores/GraphsData/LineGraph/LineGraphDataSet";
import Colors from "../../../../../Common/themes/Colors";
import { DAILY_OPTION_ID } from "../../../../../Common/components/PeriodFilter";
import GraphUtils from "../../../../../Common/utils/GraphUtils";
import {
  filterTypes,
  transactionLogFilterTypes,
  entityTypes,
} from "../../../../constants/BackendConstants";
import type {
  APIInflowVsOutFlowGraphData,
  APIGetRevenueGraphSummaryParameter,
} from "../../../type";
import { Services } from "../../../../services";
import i18n from "../../../../../Common/i18n";

class InflowVsOutflowGraph {
  @observable graphData: LineGraphData;
  @observable basicFilters: BasicFilters;
  @observable graphUrl: string;
  @observable graphSummaryAPIStatus: APIStatus;
  @observable period: string;
  @observable entityId: number;
  @observable entityType: string;
  @observable error: any;
  @observable parentFilters: BasicFilters;
  services: Services;
  constructor(
    entityId: number,
    entityType: string,
    parentFilters: BasicFilters,
    services: Services
  ) {
    this.services = services;
    this.graphData = new LineGraphData();
    this.graphUrl = "";
    this.basicFilters = new BasicFilters();
    Object.assign(
      this.basicFilters.currentDateFilter,
      parentFilters.currentDateFilter
    );
    this.period = DAILY_OPTION_ID;
    this.graphSummaryAPIStatus = API_INITIAL;
    this.entityId = entityId;
    this.entityType = entityType;
    this.parentFilters = parentFilters;
  }

  /**
   * Other UI utils
   */

  @computed
  get showDateFilters(): boolean {
    if (this.entityType === entityTypes.allFranchises) {
      return true;
    }
    return false;
  }

  @action.bound
  onReload() {
    this.getRevenueGraphSummaryAPI();
  }

  @action.bound
  setPeriod(period: string) {
    this.period = period;
  }

  /**
   * Revenue Graph Summary API utils
   */

  @computed
  get getRevenueSummaryDefaultReqObj(): APIGetRevenueGraphSummaryParameter {
    const appliedFilters = [];
    this.basicFilters.appliedFilters.forEach((filters) => {
      filters.forEach((eachFilter) => {
        appliedFilters.push(eachFilter.requestObject);
      });
    });

    // Adding Selected Franchise filter Option
    if (this.entityType === entityTypes.singleFranchise) {
      appliedFilters.push({
        filter_id: this.entityId,
        filter_type: this.entityType,
      });
    }

    const request = {
      filters: appliedFilters,
    };

    if (!this.basicFilters.currentDateFilter.isReset) {
      request.date_range = this.basicFilters.currentDateFilter.requestData;
    }
    if (this.entityType === entityTypes.singleFranchise) {
      if (!this.parentFilters.currentDateFilter.isReset) {
        request.date_range = this.parentFilters.currentDateFilter.requestData;
      }
    }

    return request;
  }

  @action.bound
  setGraphSummaryAPIStatus(status: APIStatus) {
    this.graphSummaryAPIStatus = status;
  }

  @action.bound
  getRevenueGraphSummaryAPI(requestObject = {}) {
    const defaultReq = this.getRevenueSummaryDefaultReqObj;
    const req = {
      ...defaultReq,
      ...requestObject,
    };
    const apiPromise = this.services.revenueStoreService.getRevenueGraphSummary(
      req
    );
    bindPromiseWithOnSuccess(apiPromise)
      .to(this.setGraphSummaryAPIStatus, this.setGraphSummary)
      .catch((e) => {
        this.error = e;
      });
  }

  @action.bound
  setGraphSummary(inflowVsOutflow: APIInflowVsOutFlowGraphData) {
    this.graphData.setData(
      observable([
        new LineGraphDataSet(
          inflowVsOutflow.graph_data.inflow.data,
          inflowVsOutflow.graph_data.inflow.legend,
          Colors.transparent,
          Colors.amon
        ),
      ])
    );
    this.graphData.setXValues(
      GraphUtils.formatDateTimeXvaluesToMMMYY(inflowVsOutflow.graph_data.labels)
    );
  }

  /**
   * Filters Related Utils
   */

  @action.bound
  onDateFilterChange(fromDate: string, toDate: string, index: number) {
    this.basicFilters.currentDateFilter.setFromDate(fromDate);
    this.basicFilters.currentDateFilter.setToDate(toDate);
    this.basicFilters.currentDateFilter.setDateFilterIndex(index);
    this.getRevenueGraphSummaryAPI();
  }

  @action.bound
  onPeriodChange(index: number, value: Object) {
    this.period = value.id;
    this.getRevenueGraphSummaryAPI();
  }

  @action.bound
  onFilterChanged(index: number, value: FilterItem) {
    this.basicFilters.changeCurrentFilter(value.filterType, [value]);
    this.getRevenueGraphSummaryAPI();
  }

  @action.bound
  onFilterReset(index: number, value: FilterItem) {
    this.basicFilters.changeCurrentFilter(value.filterType, []);
    this.getRevenueGraphSummaryAPI();
  }

  /**
   * Utils Used for displaying UI
   */

  @computed
  get displayChannelFilters() {
    const channelFilters = [];
    this.basicFilters.filters.forEach((eachFilter) => {
      if (eachFilter.filterType === transactionLogFilterTypes.channel) {
        channelFilters.push(eachFilter);
      }
    });
    return observable([
      new FilterItem({
        name: i18n.t("franchiseCommon.allChannels"),
        filter_id: "-1",
        filter_type: transactionLogFilterTypes.channel,
      }),
      ...channelFilters,
    ]);
  }

  @computed
  get channelFilterIndex(): number {
    let ind = -1;
    const channelFilters = this.basicFilters.appliedFilters.get(
      filterTypes.channel
    );
    if (channelFilters) {
      const currentChannel = channelFilters[0];
      if (currentChannel) {
        this.displayChannelFilters.slice().forEach((filter, index) => {
          if (filter.filterId === currentChannel.filterId) {
            ind = index;
          }
        });
      }
    }
    return ind;
  }
}

export default InflowVsOutflowGraph;

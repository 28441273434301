/**
 * @flow
 * @author: Prasanth
 */

import i18n from '../../Common/i18n'

export function validateOTP(OTP: string): string {
  if (!OTP || OTP.length === 0) {
    return i18n.t('franchiseCommon.emptyInputError');
  }
  if (!/^\d+$/.test(OTP)) {
    return i18n.t('franchiseCommon.otpError');
  }
  return "";
}

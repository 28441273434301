/*
 * @author: Dinesh
 * @flow
 */

import { resolveWithTimeout } from '../../../Common/utils/TestUtils'
import {
  type APIGetRevenueSummaryResponse,
  type APIExpenditureLogResponse
} from '../../stores/type'

// jsons

import getEntityRevenueSummary from '../../fixtures/getEntityRevenueSummary.json'
import getRevenueGraphSummaryJson from '../../fixtures/getRevenueGraphSummaryJson.json'
import getExpenditureChannelsListJson from '../../fixtures/getExpenditureChannelsListJson.json'
import getRevenueChannelsListJson from '../../fixtures/getRevenueChannelsListJson.json'
import getRevenueSummaryJson from '../../fixtures/getRevenueSummary.json'
import getTransactionLogs from '../../fixtures/getTransactionLogs.json'
import getPaymentSummaryJson from '../../fixtures/getPaymentSummary.json'

import getRevenueDonutGraphSummaryJson from '../../fixtures/getRevenueDonutGraphSummaryJson.json'

import getChannelsByFranchiseIdJson from '../../fixtures/getChannelsByFranchise.json'
import addExpenditureJson from '../../fixtures/addExpenditure.json'
import getExpenditureLogs from '../../fixtures/getExpenditureLogs.json'

class RevenueStoreFixtureService {
  async getRevenueSummary(): Promise<APIGetRevenueSummaryResponse> {
    return resolveWithTimeout(getRevenueSummaryJson)
  }
  // ENTITY related apis
  async getEntityRevenueDetails(): Promise<*> {
    return resolveWithTimeout(getEntityRevenueSummary)
  }
  // Graphs  related apis
  async getRevenueGraphSummary(): Promise<*> {
    return resolveWithTimeout(getRevenueGraphSummaryJson)
  }

  // Graphs  related apis
  async getRevenueDonutGraphSummary(): Promise<*> {
    return resolveWithTimeout(getRevenueDonutGraphSummaryJson)
  }

  // Graphs  related apis
  async getExpenditureGraphSummary(): Promise<*> {
    return resolveWithTimeout(getRevenueDonutGraphSummaryJson)
  }

  async getRevenueChannelsList(): Promise<*> {
    return resolveWithTimeout(getRevenueChannelsListJson)
  }

  async getExpenditureChannelsList(): Promise<*> {
    return resolveWithTimeout(getExpenditureChannelsListJson)
  }

  async getTransactionLogs(): Promise<*> {
    return resolveWithTimeout(getTransactionLogs)
  }

  async getExpenditureLogs(): Promise<APIExpenditureLogResponse> {
    return resolveWithTimeout(getExpenditureLogs)
  }

  async sendExpenditureLogsToMail(): Promise<*> {
    return resolveWithTimeout({})
  }

  async getChannelsByFranchise() {
    return resolveWithTimeout(getChannelsByFranchiseIdJson)
  }

  async addExpenditure() {
    return resolveWithTimeout(addExpenditureJson)
  }

  async getPaymentSummary() {
    return resolveWithTimeout(getPaymentSummaryJson)
  }
}

const revenueStoreFixtureService = new RevenueStoreFixtureService()
export default revenueStoreFixtureService

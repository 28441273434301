import * as React from "react";

import Colors from "../../themes/Colors";

function SvgComponent(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 30 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path fill="#2D4151" d="M3.321 23.321h23.438V1.075H3.321z" />
        <path
          d="M8.491 13.832v9.07H4.942s3.114-1.457 3.55-9.07m5.68 0v9.07h-3.549s3.115-1.457 3.55-9.07m5.68 0v9.07h-3.549s3.115-1.457 3.55-9.07m5.681 0v9.07h-3.55s3.115-1.457 3.55-9.07"
          fill="#3F576A"
        />
        <path
          fill="#7FBDEC"
          d="M7.691 19.746h.397v-3.575h-.397zm2.781 0h.397v-3.575h-.397zm8.739 0h.398v-3.575h-.398zm2.384 0h.397v-3.575h-.397zM.938 11.404h28.204V9.815H.938zm0-4.767h28.204V5.048H.938zm0 2.383h28.204V7.431H.938zm0-4.767h28.204V2.664H.938zm0-2.383h28.204V.281H.938z"
        />
        <path
          fill="#7FBDEC"
          d="M3.321 23.321h1.192V1.075H3.321zm22.643 0h1.192V1.075h-1.192zm-11.52 0h1.192V10.609h-1.192zm5.562 0h1.192V10.609h-1.192zm-11.123 0h1.192V10.609H8.883z"
        />
        <path fill="#2D4151" d="M6.102 7.755h17.876v-4.37H6.102z" />
        <path fill="#7FBDEC" d="M2.924 23.719h24.629v-.398H2.924z" />
        <path
          fill="#FFF"
          d="M8.088 6.563V4.577H9.28v.237h-.923v.622h.87v.237h-.87v.89zM10.258 5.413c.12 0 .209-.024.266-.074.057-.05.086-.124.086-.223 0-.1-.029-.173-.086-.22-.056-.05-.145-.074-.265-.074h-.408v.59h.407zm.662.944h-.248l-.204-.578a.222.222 0 00-.093-.128.346.346 0 00-.169-.035h-.355v.741h-.233V4.621h.668c.178 0 .314.042.41.127.094.084.142.205.142.363 0 .114-.028.21-.084.29a.457.457 0 01-.24.17c.094.017.163.089.205.214l.201.573zm.519-.629h.698l-.349-.827-.349.827zm.965.63l-.183-.43h-.866l-.182.43h-.253l.762-1.737h.21l.764 1.736h-.252zm1.772-1.737h.216v1.736h-.176l-.91-1.312v1.312h-.216V4.621h.174l.912 1.314zm1.007 1.63a.679.679 0 01-.264-.302 1.064 1.064 0 01-.093-.462c0-.177.031-.33.093-.46a.682.682 0 01.264-.301.756.756 0 01.403-.105c.11 0 .211.018.305.054a.617.617 0 01.237.156l-.08.18a.852.852 0 00-.223-.14.609.609 0 00-.236-.045.463.463 0 00-.386.172c-.09.114-.136.277-.136.489 0 .213.045.377.136.492.09.115.22.173.386.173a.636.636 0 00.237-.044.799.799 0 00.221-.142l.081.181a.617.617 0 01-.237.156.843.843 0 01-.305.054.748.748 0 01-.403-.106m2.82-1.63h.296v1.736h-.296v-.778h-1.147v.778h-.294V4.621h.294v.751h1.147zm.73 1.736h.285V4.621h-.285zm.718-.052a.788.788 0 01-.284-.158l.088-.181a.844.844 0 00.563.191.527.527 0 00.298-.072.23.23 0 00.105-.202.18.18 0 00-.098-.166 1.184 1.184 0 00-.313-.104 1.785 1.785 0 01-.332-.099.51.51 0 01-.205-.15.373.373 0 01-.075-.243c0-.099.026-.186.08-.261a.507.507 0 01.224-.177 1.005 1.005 0 01.672-.008.706.706 0 01.263.155l-.088.181a.855.855 0 00-.243-.144.781.781 0 00-.268-.046.478.478 0 00-.29.078.247.247 0 00-.106.21c0 .077.03.135.092.175.062.04.16.076.297.107.143.032.258.066.346.1a.528.528 0 01.211.144.34.34 0 01.08.233.428.428 0 01-.08.258.5.5 0 01-.227.17.916.916 0 01-.347.061c-.133 0-.254-.017-.364-.052m1.453.052V4.621h1.302v.205H21.19v.546h.958v.205h-.958v.575h1.015v.205z"
        />
        <path d="M0-3h30v30H0z" />
      </g>
    </svg>
  );
}

export default SvgComponent;

SvgComponent.defaultProps = {
  width: 24,
  height: 24,
  // fill: Colors.white,
};

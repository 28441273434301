/*
 * @author: Tanmay B
 * @flow
 */

import { observable, action, computed } from 'mobx'
import {
  SORT_TYPE_DESC,
  SORT_TYPE_ASC
} from '../../../../constants/BackendConstants'
import type { APISortBy } from '../../../type'

class SortByItem {
  @observable sortId: string
  @observable sortType: string
  @observable name: string

  constructor(genericSortBy: APISortBy = { key: '-1', order: '', name: '' }) {
    this.sortId = genericSortBy.key
    this.sortType = genericSortBy.order
    this.name = genericSortBy.name
  }

  @action.bound
  setSortId(sortId: string) {
    this.sortId = sortId
  }

  @action.bound
  setSortType(sortType: string) {
    this.sortType = sortType
  }

  @computed
  get isReset(): boolean {
    return this.sortId === '-1'
  }

  @computed
  get requestObject(): APISortBy {
    return {
      key: this.sortId,
      order: this.sortType
    }
  }

  @action.bound
  toggleOrder() {
    if (this.sortType === SORT_TYPE_DESC) {
      this.sortType = SORT_TYPE_ASC
    } else {
      this.sortType = SORT_TYPE_DESC
    }
  }
}
export default SortByItem

import styled from "@emotion/styled";

export const NoData = styled.div`
    width:100%;
    height:250px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family:'CerebriSans';
`

export const NoDataImage = styled.img`
    object-fit:contain;
    width:42%;
    height:32%;
`
export const NoDataText = styled.div`
    font-size:18px;
`

export const NoDataSpan = styled.span`
    font-size:12px;
`
/*
 * @author: Chinmaya
 * @flow
 */

import { observable, action } from 'mobx'
import type { APIMultimedia } from './type.js'

class AttachmentMultimedia {
  @observable url: string
  @observable mediaType: string

  constructor(multimedia: APIMultimedia) {
    this.url = multimedia.url || ''
    this.mediaType = multimedia.media_type || ''
  }

  @action.bound
  setUrl(url: string) {
    this.url = url
  }

  @action.bound
  setMediaType(mediaType: string) {
    this.mediaType = mediaType
  }

  getAPIModel() {
    return {
      url: this.url,
      media_type: this.mediaType
    }
  }
}
export default AttachmentMultimedia

import React from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from "recharts";

import {
  Container,
  CustomToolTipContainer,
  LabelTextHeading,
  LabelText,
} from "./styledComponents";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <CustomToolTipContainer>
        <LabelTextHeading>{`${label}`}</LabelTextHeading>
        <LabelText>{`₹ ${Math.ceil(payload[0].value)}`}</LabelText>
      </CustomToolTipContainer>
    );
  }

  return null;
};

export default function RevenueSummaryAreaGraph(props) {
  const { graphData } = props;
  return (
    <Container>
      <ResponsiveContainer>
        <ComposedChart width={600} height={400} data={graphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor="rgba(51, 198, 221)"
                stopOpacity={0.3}
              />
              <stop
                offset="100%"
                stopColor="rgba(51, 198, 221)"
                stopOpacity={0.1}
              />
            </linearGradient>
          </defs>
          <Line
            type="monotone"
            dataKey="y"
            stroke="#33c6dd"
            fill="url(#splitColor)"
          />
          <Area
            type="monotone"
            dataKey="y"
            stroke="#33c6dd"
            fill="url(#splitColor)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
}

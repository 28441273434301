/*
 * @flow
 * @author: Mani,Dinesh
 */

import {
  action,
  observable,
  computed,
  ObservableMap,
  IObservableArray,
} from "mobx";
// import I18n from 'react-native-i18n'
import DateFilter from "../DateFilter/";
import SortByItem from "../SortByItem";
import type { APISortBy, APIFilterBy } from "../../../type";
import FilterItem from "../FilterItem";
import {
  SORT_TYPE_ASC,
  SORT_TYPE_DESC,
  NAME_SORT_OPTION,
  AMOUNT_SORT_OPTION,
} from "../../../../constants/BackendConstants";
import i18n from "../../../../../Common/i18n";

class BasicFilters {
  @observable currentDateFilter: DateFilter;
  @observable currentSortBy: SortByItem;
  @observable filters: Array<FilterItem>;
  @observable currentFilter: FilterItem;
  @observable sortOptions: IObservableArray<SortByItem>;
  @observable
  appliedFilters: ObservableMap<IObservableArray<FilterItem>> = new Map();

  constructor() {
    this.currentSortBy = new SortByItem();
    this.currentDateFilter = new DateFilter();
    this.filters = observable([]);
    this.sortOptions = observable([]);
  }

  @computed
  get sortFilterIndex(): number {
    const currentSortItem = this.sortOptions.find(
      (eachItem) => eachItem.sortId === this.currentSortBy.sortId
    );
    return this.sortOptions.indexOf(currentSortItem);
  }

  setFilters(filters?: IObservableArray<APIFilterBy>) {
    if (filters !== undefined) {
      this.filters = filters.map((eachFilter) => new FilterItem(eachFilter));
    }
  }
  @computed
  get defaultSortOptions() {
    return [
      new SortByItem({
        order: "",
        key: "-1",
        name: i18n.t("revenueManagement:revenueModule.sortBy"),
      }),
      new SortByItem({
        order: SORT_TYPE_ASC,
        key: NAME_SORT_OPTION,
        name: i18n.t("revenueManagement:revenueModule.name"),
      }),
      new SortByItem({
        order: SORT_TYPE_DESC,
        key: AMOUNT_SORT_OPTION,
        name: i18n.t("revenueManagement:revenueModule.amount"),
      }),
    ];
  }

  @action.bound
  getFilterItemById(id: number): FilterItem {
    let filterItem = {};

    if (this.filters && this.filters.length > 0) {
      filterItem = this.filters.find((eachItem) => eachItem.filterId === id);
    }
    if (!filterItem) {
      filterItem = {};
    }
    return filterItem;
  }

  setCurrentFilter(filter: FilterItem) {
    this.currentFilter = filter;
  }

  @action.bound
  setDateFilter(dateFilter: DateFilter) {
    this.currentDateFilter = dateFilter;
  }

  @action.bound
  setSortOptions(sortOptions: Array<APISortBy>) {
    if (sortOptions) {
      this.sortOptions.replace(
        sortOptions.map((eachSortItem) => new SortByItem(eachSortItem))
      );
    }
  }

  @action.bound
  setSortBy(sortBy: SortByItem) {
    this.currentSortBy = sortBy;
  }

  @action.bound
  changeCurrentFilter(filterKey: string, filterValues: Array<FilterItem>) {
    this.appliedFilters.set(filterKey, observable(filterValues));
  }

  @action.bound
  addFilterValueForCurrentFilterKey(
    filterKey: string,
    filterValue: FilterItem
  ) {
    if (this.appliedFilters.has(filterKey)) {
      const cfValues = this.appliedFilters.get(filterKey);
      cfValues.push(filterValue);
    } else {
      this.appliedFilters.set(filterKey, observable.array([filterValue]));
    }
  }

  @action.bound
  removeFilterValueFromCurrentFilterKey(
    filterKey: string,
    filterValue: FilterItem
  ) {
    if (this.appliedFilters.has(filterKey)) {
      const cfValues = this.appliedFilters.get(filterKey);
      cfValues.remove(filterValue);
    }
  }

  @action.bound
  removeFilterObjectFromCurrentFiltersByKey(filterKey: string) {
    if (this.appliedFilters.has(filterKey)) {
      this.appliedFilters.delete(filterKey);
    }
  }

  @action.bound
  resetCurrentFilters() {
    this.appliedFilters.clear();
  }
}

export default BasicFilters;
